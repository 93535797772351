import React, { forwardRef } from 'react';
import { ArrowRightIcon, BranchesIcon, ExpandIcon, EyeIcon, FileIcon, PlayIcon, SettingsSliderIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
const DownloadCsv = forwardRef(function DownloadCsv(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(FileIcon, null), ref: ref, size: "sm", variant: "ghost" }, props), "Download CSV"));
});
const ViewAllResponses = forwardRef(function ViewAllResponses(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ ref: ref, size: "sm", trailingIcon: React.createElement(ArrowRightIcon, null), variant: "ghost" }, props), "View all responses"));
});
const ViewBreakdown = forwardRef(function ViewBreakdown(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(ExpandIcon, null), ref: ref, size: "sm", variant: "secondary", testId: "view-fullscreen" }, props), "View full breakdown"));
});
const Conditions = forwardRef(function Conditions(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(BranchesIcon, null), ref: ref, size: "sm", variant: "ghost" }, props), "Conditions"));
});
const VideoGuide = forwardRef(function VideoGuide(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(PlayIcon, null), ref: ref, size: "sm", variant: "ghost" }, props), "Add video guide"));
});
const Preview = forwardRef(function Preview(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(EyeIcon, null), ref: ref, size: "sm", variant: "ghost" }, props), "Preview"));
});
const Options = forwardRef(function Options(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ leadingIcon: React.createElement(SettingsSliderIcon, null), ref: ref, size: "sm", variant: "ghost" }, props), "Options"));
});
export const StepButtons = {
    Conditions,
    DownloadCsv,
    Options,
    Preview,
    VideoGuide,
    ViewAllResponses,
    ViewBreakdown,
};
