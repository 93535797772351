import { __rest } from "tslib";
import React from 'react';
import { SvgElement } from './styles';
function Svg(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return React.createElement(SvgElement, Object.assign({}, props), children);
}
Svg.defaultProps = {
    display: 'block',
    fill: 'currentColor',
    height: '24px',
    viewBox: '0 0 24 24',
    width: '24px',
};
export default Svg;
