import { color, layout, space } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import { cursor, fill, hoverColor, hoverFill, overflow, } from '../customProperties';
export const SvgElement = styled.svg `
  ${color};
  ${cursor};
  ${fill};
  ${layout};
  ${overflow};
  ${space};

  &:hover {
    ${hoverColor};
    ${hoverFill};
  }
`;
SvgElement.defaultProps = {
    theme,
};
