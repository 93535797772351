import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { Label } from './Label';
import { LegacySelect } from './LegacySelect';
export const SelectWithLabel = forwardRef((props, ref) => {
    const { children, disabled, id, isOptional, label, withLabel = true, labelSize = 's', onChange, selectSize = 'm', value, mb = 's' } = props, rest = __rest(props, ["children", "disabled", "id", "isOptional", "label", "withLabel", "labelSize", "onChange", "selectSize", "value", "mb"]);
    return (React.createElement(React.Fragment, null,
        withLabel && (React.createElement(Label, { display: "inline-block", htmlFor: id, isOptional: isOptional, marginBottom: mb, size: labelSize }, label)),
        React.createElement(LegacySelect, Object.assign({ disabled: disabled, id: id, onChange: onChange, ref: ref, size: selectSize, value: value, width: 1, "aria-label": label }, rest), children)));
});
