import { __rest } from "tslib";
import PropTypes from 'prop-types';
import React from 'react';
import Text from '../Text';
function Heading(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(Text, Object.assign({ color: "licorice", "data-testid": props.testId, fontSize: 5, fontWeight: "600", lineHeight: 1.15 }, props), children));
}
Heading.propTypes = {
    children: PropTypes.any,
};
export default Heading;
