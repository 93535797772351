import { __rest } from "tslib";
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';
import { ChevronBottomIcon } from '@marvelapp/ballpark-icons';
import { BtwText } from './BtwText';
import { cn } from './utils';
function Root(props) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    return (React.createElement(AccordionPrimitive.Root, Object.assign({ className: cn('w-full', className) }, rest), children));
}
function Header(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(AccordionPrimitive.Header, Object.assign({}, rest), children));
}
function Item(props) {
    const { className, children } = props, rest = __rest(props, ["className", "children"]);
    return (React.createElement(AccordionPrimitive.Item, Object.assign({ className: cn('w-full', 'border-b', 'border-gray-200', 'last:border-none', 'flex', 'flex-col', 'align-stretch', 'data-[state=open]:pb-5', className) }, rest), children));
}
function Trigger(props) {
    const { className, children } = props, rest = __rest(props, ["className", "children"]);
    return (React.createElement(AccordionPrimitive.Trigger, Object.assign({ className: cn('flex', 'py-5', 'w-full', 'ring-1', 'gap-1.5', 'items-center', 'ring-transparent', 'justify-stretch', '[&>div>svg]:-rotate-90', 
        // Hover
        'hover:data-[state=closed]:bg-gray-50', 
        // Open state
        '[&>div>svg]:data-[state=open]:rotate-0', 
        // Transition
        'duration-300', 'ease-smooth', 'transition-combined', 
        // Focus
        'focus-visible:rounded-lg', 'focus-visible:outline-none', 'focus-visible:ring-gray-600', 'focus-visible:outline-gray-300', className) }, rest),
        React.createElement("div", null,
            React.createElement(ChevronBottomIcon, { className: cn('block', 'h-3.5', 'w-3.5', 
                // Transition
                'duration-300', 'ease-smooth', 'transition-combined', 'will-change-transform') })),
        React.createElement("div", { className: "min-w-0 flex-1" },
            React.createElement(BtwText, { align: "left", size: "xs", variant: "primary", weight: "medium" }, children))));
}
function Content(props) {
    const { className, children } = props, rest = __rest(props, ["className", "children"]);
    return (React.createElement(AccordionPrimitive.Content, Object.assign({ className: cn('p-6', 'rounded-lg', 'bg-gray-100', className) }, rest), children));
}
export const Accordion = {
    Root,
    Item,
    Header,
    Trigger,
    Content,
};
