import React from 'react';
function appRoute({ loader, path, flag }) {
    return {
        path,
        flag,
        // We take advantage of webpack memoizing what already being fetched
        // to do dynamic preloading
        preload: loader,
        Application: React.lazy(() => loader().then((module) => {
            if (!module.Marvel3ReactApp) {
                const message = 'Application must export `Marvel3ReactApp`';
                throw new Error(message);
            }
            return {
                default: module.Marvel3ReactApp,
            };
        })),
    };
}
// ATTENTION: before editing this, you should know that `m3-scripts scaffold`
//    tries to modify this file to add new routes in. So make sure you understand
//    that script before changing the structure of this file. As long as you keep
//    this as an array of `appRoute` call though, it should be fine.
export const routingMap = [
    appRoute({
        path: '/(login|auth|auth-bounce)',
        loader: () => import(
        /* webpackChunkName: "askhub-auth-ui" */
        '@marvelapp/askhub-auth-ui'),
    }),
    appRoute({
        path: '/(project|askhub|record|website-record|responses|response|preview|contact-cx|settings)',
        loader: () => import(
        /* webpackChunkName: "askhub-ui" */
        '@marvelapp/askhub-ui'),
    }),
    appRoute({
        path: '/(projects|archive|templates|template)',
        loader: () => import(
        /* webpackChunkName: "askhub-dashboard" */ '@marvelapp/askhub-dashboard'),
    }),
    appRoute({
        path: '/company',
        loader: () => import(/* webpackChunkName: "people-ui" */ '@marvelapp/people-ui'),
    }),
    appRoute({
        path: '/user/account',
        loader: () => import(
        /* webpackChunkName: "account-ui" */
        '@marvelapp/account-ui'),
    }),
    appRoute({
        path: '/invite',
        loader: () => import(
        /* webpackChunkName: "invites-ui" */
        '@marvelapp/invites-ui'),
    }),
    appRoute({
        path: '/(plans|switch|upgrade|upgrade-success|update-credit-card|switch-to-yearly-plan|downgrade|plan-pricing|recruitment-bundles)',
        loader: () => import(
        /* webpackChunkName: "askhub-subscription-ui" */
        '@marvelapp/askhub-subscription-ui'),
    }),
];
if (__DEV__ || __TEST_BUILD__) {
    routingMap.push(appRoute({
        path: '/embed',
        loader: () => import(
        /* webpackChunkName: "mock-figma-ui" */
        '@marvelapp/mock-figma-ui'),
    }));
}
