import theme from '@marvelapp/ballpark-theme';
export function getTooltipStyle({ arrowRight, cursor, display, fontSize = theme.fontSizes.xs, fontWeight, maxWidth, padding = '6px 10px 4px', pointerEvents, position, whiteSpace = 'nowrap', width, }) {
    const extraArrowStyles = arrowRight && { right: arrowRight };
    return {
        wrapper: {
            cursor,
            display,
            fontWeight,
            zIndex: 'unset',
        },
        content: {
            backgroundColor: 'transparent',
            color: theme.colors.white,
            display: 'block',
            fontSize,
            lineHeight: 1.5,
            padding: 0,
            whiteSpace,
            width,
            maxWidth,
        },
        tooltip: Object.assign({ backgroundColor: theme.colors.blackA11, borderRadius: theme.radii[1], display: 'block', margin: 0, minWidth: 0, zIndex: theme.zIndices.tooltip, padding,
            pointerEvents }, position.tooltip),
        arrow: Object.assign(Object.assign({}, position.arrow), extraArrowStyles),
        gap: Object.assign({}, position.gap),
    };
}
