import { __rest } from "tslib";
import React from 'react';
import { tv } from 'tailwind-variants';
import { BtwText } from './BtwText';
const label = tv({
    base: [],
    variants: {
        disabled: {
            true: ['opacity-50', 'cursor-not-allowed'],
            false: [],
        },
    },
});
function BtwLabel(props) {
    const { children, className, htmlFor, variant = 'primary', weight = 'semibold', disabled } = props, rest = __rest(props, ["children", "className", "htmlFor", "variant", "weight", "disabled"]);
    return (React.createElement(BtwText, Object.assign({ asChild: true, className: label({ className, disabled }), variant: variant, weight: weight }, rest),
        React.createElement("label", { htmlFor: htmlFor }, children)));
}
export { BtwLabel };
