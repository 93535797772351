export var BillingCycle;
(function (BillingCycle) {
    BillingCycle["Monthly"] = "monthly";
    BillingCycle["Yearly"] = "yearly";
})(BillingCycle || (BillingCycle = {}));
export function isYearly(cycle) {
    return cycle === BillingCycle.Yearly;
}
export function isMonthly(cycle) {
    return cycle === BillingCycle.Monthly;
}
export function numberWithCommas(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const MONTHLY = BillingCycle.Monthly;
export const YEARLY = BillingCycle.Yearly;
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Inactive"] = "INACTIVE";
    SubscriptionStatus["Active"] = "ACTIVE";
    SubscriptionStatus["Cancelled"] = "CANCELLED";
    SubscriptionStatus["IsTrial"] = "IS_TRIAL";
    SubscriptionStatus["Incomplete"] = "INCOMPLETE";
})(SubscriptionStatus || (SubscriptionStatus = {}));
