import shouldForwardProp from '@styled-system/should-forward-prop';
import { pickBy } from 'lodash-es';
export function loadImage(url) {
    return new Promise((resolve, reject) => {
        const img = new window.Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
    });
}
export function getDOMProps(props) {
    return pickBy(props, (_, propName) => shouldForwardProp(propName));
}
