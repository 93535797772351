import { camelCase as _camelCase, snakeCase as _snakeCase, isArray, isPlainObject, isString, } from 'lodash-es';
function walk(obj, transformFn) {
    if (isArray(obj)) {
        return obj.map((entry) => walk(entry, transformFn));
    }
    if (!isPlainObject(obj)) {
        return obj;
    }
    return Object.keys(obj).reduce((acc, key) => {
        const newKey = transformFn(key);
        acc[newKey] = walk(obj[key], transformFn);
        return acc;
    }, {});
}
export function camelCase(obj) {
    if (isString(obj)) {
        return _camelCase(obj);
    }
    return walk(obj, _camelCase);
}
export function snakeCase(obj) {
    if (isString(obj)) {
        return _snakeCase(obj);
    }
    return walk(obj, _snakeCase);
}
