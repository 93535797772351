import { color, typography } from 'styled-system';
import { createGlobalStyle } from '@marvelapp/styled';
export const GlobalStyle = createGlobalStyle `
  button, html input[type="button"], input[type="reset"], input[type="submit"] {
    line-height: 1;
  }

  body {
    ${color};
    ${typography};
    letter-spacing: -0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    ${color};
  }

  a, a:link, a:visited {
    text-decoration: none;
  }

  .textarea {
    ::-webkit-input-placeholder {
      padding-top: 4px;
    }
  }
`;
