import { FlatStepClass } from '@marvelapp/user-test-creator';
// Needs to be unified and moved to user-test-creator. Context here:
// https://github.com/marvelapp/mkiii/pull/5182#discussion_r1504711226
export var FlatFixedStepClass;
(function (FlatFixedStepClass) {
    // TODO: Can we add Screener/Welcome/Exit to the flat step class?
    FlatFixedStepClass["Screener"] = "Screener";
    FlatFixedStepClass["Welcome"] = "Welcome";
    FlatFixedStepClass["Exit"] = "Exit";
})(FlatFixedStepClass || (FlatFixedStepClass = {}));
// TODO: new enum, needs to be reviewed
export var FlatStepCategories;
(function (FlatStepCategories) {
    FlatStepCategories["Misc"] = "Misc";
    FlatStepCategories["Survey"] = "Survey";
    FlatStepCategories["Usability"] = "Usability";
    FlatStepCategories["Marketing"] = "Marketing";
})(FlatStepCategories || (FlatStepCategories = {}));
// TODO: new const, needs to be reviewed
export const STEP_CATEGORIES = [
    {
        title: FlatStepCategories.Misc,
        steps: [FlatStepClass.Instruction, FlatFixedStepClass.Screener],
    },
    {
        title: FlatStepCategories.Survey,
        steps: [
            FlatStepClass.YesOrNo,
            FlatStepClass.QuestionText,
            FlatStepClass.MultipleChoice,
            FlatStepClass.RatingScale,
            FlatStepClass.PreferenceTest,
            FlatStepClass.QuestionEmail,
            FlatStepClass.QuestionNumerical,
            FlatStepClass.QuestionDate,
        ],
    },
    {
        title: FlatStepCategories.Usability,
        steps: [
            FlatStepClass.WebsiteTask,
            FlatStepClass.FiveSecondTest,
            FlatStepClass.FirstClickStep,
            FlatStepClass.FreeRoamStep,
            FlatStepClass.GoalBasedStep,
        ],
    },
    {
        title: FlatStepCategories.Marketing,
        steps: [FlatStepClass.TaglineCopyTest, FlatStepClass.LegalStep],
    },
];
// TODO: new const, needs to be reviewed
export const STEP_DESCRIPTION = {
    [FlatStepClass.Instruction]: 'Add additional context',
    [FlatFixedStepClass.Screener]: 'Find the right participants',
    [FlatStepClass.YesOrNo]: 'A simple two-choice question',
    [FlatStepClass.QuestionText]: 'Collect free text answers',
    [FlatStepClass.MultipleChoice]: 'Provide two or more choices',
    [FlatStepClass.RatingScale]: 'Get feedback on a numbered scale',
    [FlatStepClass.PreferenceTest]: 'Multiple choice with images',
    [FlatStepClass.QuestionEmail]: 'Collect email answers',
    [FlatStepClass.QuestionNumerical]: 'Collect numerical answers',
    [FlatStepClass.QuestionDate]: 'Collect date answers',
    [FlatStepClass.WebsiteTask]: 'Ask the participant to test a website',
    [FlatStepClass.FiveSecondTest]: 'Test memory recall',
    [FlatStepClass.FirstClickStep]: 'Test first impressions',
    [FlatStepClass.FreeRoamStep]: 'Test a prototype without a goal',
    [FlatStepClass.GoalBasedStep]: 'Set a goal to complete',
    [FlatStepClass.TaglineCopyTest]: 'Test and validate marketing copy',
    [FlatStepClass.LegalStep]: 'Ask participants to accept terms',
};
