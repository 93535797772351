const kinds = {
    ghost: {
        bg: 'white',
        boxShadow: 'button.ghost',
        color: 'slate',
        hoverBoxShadow: 'button.ghostHover',
        hoverColor: 'licorice',
        hoverFill: 'licorice',
        hoverBg: 'snow',
    },
    ghostDanger: {
        bg: 'transparent',
        boxShadow: 'button.ghost',
        color: 'slate',
        hoverBoxShadow: 'button.dangerHover',
        hoverColor: 'white',
        hoverFill: 'white',
        hoverBg: 'redDark',
    },
    danger: {
        bg: 'red',
        color: 'white',
        hoverBg: 'redDark',
        hoverColor: 'white',
        boxShadow: 'button.danger',
        textShadow: 'button.text',
        hoverBoxShadow: 'button.dangerHover',
    },
    ghostReversed: {
        bg: 'transparent',
        boxShadow: 'button.ghostReversed',
        color: 'white',
        fill: 'white',
        hoverBg: 'white',
        hoverBoxShadow: 'button.ghostReversedHover',
        hoverColor: 'slate',
        hoverFill: 'slate',
    },
    disabled: {
        bg: 'snowDark',
        boxShadow: 'button.disabled',
        color: 'silver',
        fill: 'silver',
        pointerEvents: 'none',
    },
    green: {
        bg: 'green',
        color: 'white',
        fill: 'white',
        hoverBg: 'greenDark',
        boxShadow: 'button.green',
        hoverBoxShadow: 'button.greenHover',
        textShadow: 'button.text',
    },
    slate: {
        bg: 'slate',
        color: 'white',
        fill: 'white',
        hoverBg: 'slate',
    },
    marvel: {
        bg: 'marvel',
        color: 'white',
        fill: 'white',
        hoverBg: 'marvelDark',
        boxShadow: 'button.marvel',
        hoverBoxShadow: 'button.marvelHover',
        textShadow: 'button.text',
    },
    licorice: {
        bg: 'licorice',
        color: 'white',
        fill: 'white',
        hoverBg: 'licorice',
    },
    withShadow: {
        bg: 'white',
        boxShadow: 'button.withShadow',
        color: 'black',
    },
    toolbar: {
        bg: 'rgba(249,250,252,.94)',
        hoverBg: 'white',
        boxShadow: 'button.toolbar',
    },
    toolbarActive: {
        bg: 'slate',
        color: 'white',
        hoverBg: 'black',
    },
    negative: {
        bg: 'tomato9',
        border: '1px solid',
        borderBottomColor: 'tomato10',
        borderLeftColor: 'tomato9',
        borderRightColor: 'tomato9',
        borderTopColor: 'tomato9',
        boxShadow: 'none',
        color: 'background',
        hoverBg: 'tomato10',
    },
};
export default kinds;
