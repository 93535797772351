// TODO: remove @marvelapp/help dependency when the articles are fully migrated to
// Ballpark
export const HELP = 'https://help.ballparkhq.com';
export const HELP_ZENDESK = `${HELP}/hc/en-us`;
export const CONTACT_SUPPORT = `${HELP}/en/articles/8409798-contact-us`;
const ARTICLES = `${HELP_ZENDESK}/articles`;
export const TROUBLESHOOTING_FIGMA_ISSUES = `${ARTICLES}/4452646945553-Troubleshooting-Figma-issues-including-loading-times-timeouts-and-more`;
export const HOW_TO_ADD_FIGMA_PROTOTYPE = `${ARTICLES}/8329830-how-to-add-a-figma-prototype-share-link-to-your-test-or-survey`;
export const ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS = `${ARTICLES}/6010519393297-How-to-enable-permissions-for-camera-and-screen-sharing`;
export const ENABLE_SCREEN_SHARING = `${ENABLE_SCREEN_SHARING_AND_CAMERA_ACCESS}#h_01GQ7EJ0RZBGBDXRTDENDDG7WF`;
export const HOW_TO_STUDY_DESCRIPTION = `${ARTICLES}/8505424265873-How-to-write-an-awesome-research-description-that-leads-to-high-quality-responses`;
export const OPTIMISING_PROTOTYPES_FOR_TESTING = `${ARTICLES}/8430019855889-Optimising-your-Figma-and-Marvel-prototypes-for-testing`;
export const SUMMARY_METRICS = `${ARTICLES}/8329853-what-are-performance-metrics`;
export const SCREENER_METRICS = `${ARTICLES}/8517350-understanding-screener-reporting-data`;
export const FIGMA_PERMISSION_HELP = `${ARTICLES}/7445046530065-Figma-file-permissions-preventing-Ballpark-accessing-the-prototype`;
export const DEVICE_CONNECTION_ISSUES = `${ARTICLES}/16546367382801-Hardware-and-client-video-issues`;
export const RECRUITMENT_MINUTES_FAQS = `${HELP}/en/articles/8329842-what-are-recruitment-minutes`;
export const ROADMAP = 'https://portal.productboard.com/ballpark/1-ballpark';
export const LEARN_VIDEO = `${HELP}/en/articles/8694795-understanding-video-in-ballpark`;
