import { isString } from 'lodash-es';
import Pusher from 'pusher-js';
import uuidv4 from 'uuid/v4';
import config from '../config';
const userId = uuidv4();
export function connectPusher({ accessToken, apiKey }) {
    if (!isString(accessToken)) {
        throw new Error(`Invalid accessToken, ${accessToken}`);
    }
    if (!isString(apiKey)) {
        throw new Error(`Invalid apiKey, ${apiKey}`);
    }
    const pusher = new Pusher(apiKey, {
        encrypted: true,
        authEndpoint: `${config.pie.host}/api/pusher/auth/`,
        cluster: 'eu',
        auth: {
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            params: {
                user_id: userId,
                cluster: 'eu',
            },
        },
    });
    pusher.connection.bind('error', (err) => {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.error('pusher: ', err);
        }
    });
    const subscribe = (channelName) => {
        const channel = pusher.subscribe(channelName);
        const unsubscribe = () => pusher.unsubscribe(channelName);
        return { channel, unsubscribe };
    };
    const disconnect = () => pusher.disconnect();
    return { subscribe, disconnect };
}
