import React from 'react';
import { useCurrentUser, userSelectors } from '@marvelapp/ballpark-application';
import { Button } from '@marvelapp/ballpark-components';
import { pluralize } from '@marvelapp/core';
import { Link } from '@marvelapp/ui-internal';
export const TrialCountdown = function TrialCountdown() {
    const user = useCurrentUser();
    const isCurrentlyOnTrial = userSelectors.hasRemainingTrialDays(user) && userSelectors.isFree(user);
    if (!isCurrentlyOnTrial)
        return null;
    const trialDaysRemaining = userSelectors.trialDaysRemaining(user);
    return (React.createElement(Link, { display: "block", "data-testid": "trial-countdown", to: { pathname: '/plans', state: { modal: true } } },
        React.createElement(Button, { display: "block", fontSize: "xs", fontWeight: 600, height: "inherit", kind: "blue8", px: "xs", width: "100%" },
            trialDaysRemaining,
            " ",
            pluralize(trialDaysRemaining, 'day'),
            " left - buy now")));
};
