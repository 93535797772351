import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { Box } from '@marvelapp/ui';
import { Label, LabelContainer } from './Label';
import { Text } from './Text';
import { Textarea } from './Textarea';
export const TextareaWithLabel = forwardRef((_a, ref) => {
    var { id, isOptional, kind, label, labelSize = 'm', subheading, textareaSize = 'm' } = _a, props = __rest(_a, ["id", "isOptional", "kind", "label", "labelSize", "subheading", "textareaSize"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LabelContainer, { marginBottom: 12 },
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Label, { display: "inline-block", htmlFor: id, isOptional: isOptional, size: labelSize }, label),
                subheading && (React.createElement(Text, { color: "slate12", mt: "xs", size: "s" }, subheading)))),
        React.createElement(Textarea, Object.assign({ id: id, kind: kind, ref: ref, size: textareaSize, width: 1 }, props))));
});
