import { __rest } from "tslib";
import React from 'react';
import { Text as MarvelText } from '@marvelapp/ui';
export const sizes = {
    xs: {
        fontSize: 'xs',
        fontWeight: 'regular',
        lineHeight: 'body',
    },
    s: {
        fontSize: 's',
        fontWeight: 'regular',
        lineHeight: 'body',
    },
    m: {
        fontSize: 'm',
        fontWeight: 'regular',
        lineHeight: 'body',
    },
    l: {
        fontSize: 'l',
        fontWeight: 'regular',
        lineHeight: 'body',
    },
    xl: {
        fontSize: 'xl',
        fontWeight: 'regular',
        lineHeight: 'body',
    },
    xxxxxxl: {
        fontSize: 'xxxxxxl',
        fontWeight: 'regular',
        lineHeight: 'body',
    },
};
const kinds = {
    primary: {
        color: 'slate11',
    },
    secondary: {
        color: 'slate12',
    },
    additional: {
        color: 'slate10',
    },
    reversed: {
        color: 'background',
    },
};
export function Text(_a) {
    var { as, children, kind = 'primary', size, truncated } = _a, rest = __rest(_a, ["as", "children", "kind", "size", "truncated"]);
    return (React.createElement(MarvelText, Object.assign({ as: as, truncated: truncated }, kinds[kind], (size && sizes[size]), rest), children));
}
