import { useEffect, useState } from 'react';
// Derived state is often an anti-pattern but in Ballpark the central state is updated asynchronously
// so for things like input components we need to copy the value to local state so that input's value
// updates are synchronous (otherwise the cursor jumps to the end of the input).
export function useStateFromProp(value, shouldUpdate) {
    const [state, setState] = useState(value);
    useEffect(() => {
        if (shouldUpdate && !shouldUpdate()) {
            return;
        }
        setState(value);
    }, [shouldUpdate, value]);
    return [state, setState];
}
