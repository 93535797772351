import React from 'react';
import ReactDOM from 'react-dom';
const MODAL_ID = 'root-modal';
export class ModalPortal extends React.Component {
    constructor() {
        super();
        // eslint-disable-next-line no-undef
        this.portalDestinationElem = document.getElementById(MODAL_ID);
    }
    render() {
        return this.portalDestinationElem
            ? ReactDOM.createPortal(this.props.children, this.portalDestinationElem)
            : null;
    }
}
export function ModalPortalDestination() {
    return React.createElement("div", { id: MODAL_ID });
}
