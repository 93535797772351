import React, { Component, createContext } from 'react';
import { Switch } from 'react-router-dom';
export const ModalStackContext = createContext([]);
export const ModalIndexContext = createContext([]);
export default class ModalSwitch extends Component {
    constructor() {
        super(...arguments);
        // We can pass a location to <Switch/> that will tell it to ignore the
        // router's current location and use the location prop instead.
        //
        // We can also use "location state" to tell the app the user wants to go to a
        // location in a modal, rather than as the main page, keeping the base page
        // visible behind it.
        //
        // Normally, the base page wouldn't match the route for the modal. So, to get
        // both screens to render, we can save the old location and pass it to Switch,
        // so it will think the location is still the base page even though it's the
        // location of the modal
        this.previousLocation = this.props.location;
        this.stack = [];
    }
    componentDidUpdate(nextProps) {
        const { location } = this.props;
        // set previousLocation if props.location is not modal
        if (nextProps.history.action !== 'POP') {
            if (!location.state || !location.state.modal) {
                this.previousLocation = this.props.location;
                this.stack = [];
            }
            if (nextProps.location.state && nextProps.location.state.modal) {
                // Turns out you can rage click Links which results in duplicate entries
                // being pushed to the history stack. So we check here that it's
                // (probably) not the same link clicked to prevent adding duplicate
                // modals to the stack (seems like the kind of thing react-router should
                // maybe guard against 🤔)
                if (this.props.location.pathname !== nextProps.location.pathname) {
                    if (nextProps.history.action === 'REPLACE') {
                        this.stack[this.stack.length - 1] = nextProps.location;
                    }
                    else {
                        this.stack.push(nextProps.location);
                    }
                }
            }
            return;
        }
        if (this.props.location.pathname !== nextProps.location.pathname) {
            const locationIndex = this.stack.findIndex(({ pathname }) => nextProps.location.pathname === pathname);
            if (locationIndex === -1) {
                this.stack = [];
                return;
            }
            this.stack = this.stack.slice(0, locationIndex + 1);
        }
    }
    render() {
        const { children, location } = this.props;
        return (React.createElement(ModalStackContext.Provider, { value: this.stack },
            React.createElement(ModalIndexContext.Provider, { value: this.stack.length - 1 },
                React.createElement(Switch, { location: location }, children))));
    }
}
