export function decodeParams(raw) {
    return raw
        .replace(/^(#|\?)/, '')
        .split('&')
        .reduce((parsed, param) => {
        const [key, value = ''] = param.split('=');
        return Object.assign(Object.assign({}, parsed), { [decodeURIComponent(key)]: decodeURIComponent(value) });
    }, {});
}
export function encodeParams(params) {
    return Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
}
export function getUrlEncodedRequestBody(requestInfo) {
    return Object.keys(requestInfo)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(requestInfo[key])}`)
        .join('&');
}
/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export function postForm(path, params, method = 'post') {
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
    Object.keys(params).forEach((key) => {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
    });
    document.body.appendChild(form);
    form.submit();
}
export function isSafeURL(url) {
    try {
        const { protocol } = new URL(url);
        // eslint-disable-next-line no-script-url
        return protocol !== 'javascript:';
    }
    catch (_a) {
        return true;
    }
}
export function isSafeRedirectURL(url, allowedOrigin) {
    const testUrl = url.toString();
    const { protocol, origin } = new URL(testUrl);
    return ['http', 'https'].indexOf(protocol) !== -1 && origin === allowedOrigin;
}
export function isDeployPreviewUrl(url) {
    // Confirm with a regex that its a valid url and that it contains the deploy preview subdomain
    let testUrl;
    if (typeof url !== 'string') {
        testUrl = url === null || url === void 0 ? void 0 : url.toString();
    }
    else {
        testUrl = url;
    }
    if (!testUrl) {
        return false;
    }
    const isDeployPreview = /^https:\/\/deploy--preview-\d+\.askhub\.dev\/$/.test(testUrl);
    return isDeployPreview;
}
