// https://stackoverflow.com/a/15724300/63011
// Warning about this function: if there is more than one cookie by
// the same name, then no cookie value will be returned.
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}
