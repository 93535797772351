import { __rest } from "tslib";
import React from 'react';
import { tv } from 'tailwind-variants';
import { BtwText } from './BtwText';
const message = tv({
    base: ['rounded-lg', 'text-left'],
    variants: {
        variant: {
            gray: ['bg-gray-100', 'text-gray-800'],
            green: ['bg-green-100', 'text-green-800'],
            pink: ['bg-pink-100', 'text-pink-800'],
            red: ['bg-red-100', 'text-red-800'],
            yellow: ['bg-yellow-100', 'text-yellow-800'],
            black: ['bg-black/90', 'text-white', 'backdrop-blur-md'],
        },
        size: {
            base: ['py-4', 'px-5', '[&>span]:gap-3', '[&>span>svg]:size-6'],
            sm: [
                'py-3',
                'px-4',
                '[&>span]:gap-2.5',
                '[&>span>svg]:size-4',
                '[&>span>svg]:mt-0.5',
            ],
        },
    },
    defaultVariants: {
        variant: 'gray',
        size: 'base',
    },
});
function BtwMessage(props) {
    const { children, variant, className, leadingIcon, size } = props, rest = __rest(props, ["children", "variant", "className", "leadingIcon", "size"]);
    const fontSize = getFontSize(size);
    return (React.createElement("div", Object.assign({ className: message({ className, variant, size }) }, rest),
        React.createElement(BtwText, { asChild: true, className: "flex [&>svg]:flex-none", leading: "relaxed", size: fontSize, variant: "inherit" },
            React.createElement("span", null,
                leadingIcon,
                React.createElement("span", { className: "[&>strong]:inline [&>strong]:font-semibold" }, children)))));
}
function getFontSize(size) {
    // Applying a different font size based on Message size
    switch (size) {
        case 'base':
            return 'sm';
        case 'sm':
            return 'xs';
        default:
            return 'sm';
    }
}
export { BtwMessage };
