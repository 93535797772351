import { DASHBOARD_CARD_GUTTER, DASHBOARD_CARD_WIDTH, SIDEBAR_WIDTH, } from './constants';
export function getContainerWidth(numberOfCards = 1) {
    return `${DASHBOARD_CARD_WIDTH * numberOfCards}px`;
}
export function getBreakpointWidth(numberOfCards = 1, sidebarWidth = SIDEBAR_WIDTH) {
    return `${DASHBOARD_CARD_WIDTH * numberOfCards + sidebarWidth + DASHBOARD_CARD_GUTTER}px`;
}
export const breakpoints = [
    `${DASHBOARD_CARD_WIDTH + DASHBOARD_CARD_GUTTER * 2}px`,
    `${DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3}px`,
    `${DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3 + SIDEBAR_WIDTH}px`,
    `${DASHBOARD_CARD_WIDTH * 3 + DASHBOARD_CARD_GUTTER * 4 + SIDEBAR_WIDTH}px`,
    `${DASHBOARD_CARD_WIDTH * 4 + DASHBOARD_CARD_GUTTER * 5 + SIDEBAR_WIDTH}px`,
    `${DASHBOARD_CARD_WIDTH * 5 + DASHBOARD_CARD_GUTTER * 6 + SIDEBAR_WIDTH}px`,
    `${DASHBOARD_CARD_WIDTH * 6 + DASHBOARD_CARD_GUTTER * 7 + SIDEBAR_WIDTH}px`,
    `${DASHBOARD_CARD_WIDTH * 7 + DASHBOARD_CARD_GUTTER * 8 + SIDEBAR_WIDTH}px`,
    `${DASHBOARD_CARD_WIDTH * 8 + DASHBOARD_CARD_GUTTER * 9 + SIDEBAR_WIDTH}px`,
    `${DASHBOARD_CARD_WIDTH * 9 + DASHBOARD_CARD_GUTTER * 10 + SIDEBAR_WIDTH}px`,
];
export const containerWidths = [
    `${DASHBOARD_CARD_WIDTH + DASHBOARD_CARD_GUTTER * 2}px`,
    `${DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3}px`,
    `${DASHBOARD_CARD_WIDTH * 2 + DASHBOARD_CARD_GUTTER * 3}px`,
    `${DASHBOARD_CARD_WIDTH * 3 + DASHBOARD_CARD_GUTTER * 4}px`,
    `${DASHBOARD_CARD_WIDTH * 4 + DASHBOARD_CARD_GUTTER * 5}px`,
    `${DASHBOARD_CARD_WIDTH * 5 + DASHBOARD_CARD_GUTTER * 6}px`,
    `${DASHBOARD_CARD_WIDTH * 6 + DASHBOARD_CARD_GUTTER * 7}px`,
    `${DASHBOARD_CARD_WIDTH * 7 + DASHBOARD_CARD_GUTTER * 8}px`,
    `${DASHBOARD_CARD_WIDTH * 8 + DASHBOARD_CARD_GUTTER * 9}px`,
    `${DASHBOARD_CARD_WIDTH * 9 + DASHBOARD_CARD_GUTTER * 10}px`,
];
