// TODO: Unify input, button and select sizes
export const inputSizes = [
    {
        fontSize: 1,
        borderRadius: 0,
        lineHeight: 1.4,
        pt: 2,
        pr: 2,
        pb: 2,
        pl: 2,
    },
    {
        fontSize: 2,
        borderRadius: 1,
        lineHeight: 1.4,
        pt: 3,
        pr: 3,
        pb: 3,
        pl: 3,
    },
    {
        fontSize: 2,
        borderRadius: 1,
        lineHeight: 1.4,
        pt: 3,
        pr: 3,
        pb: 3,
        pl: 3,
    },
    {
        fontSize: 2,
        borderRadius: 1,
        lineHeight: 1.4,
        pt: 3,
        pr: 3,
        pb: 3,
        pl: 3,
    },
];
