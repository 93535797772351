// TODO: remove the !important when we ditch the people-ui uglyass old CSS
export const fonts = [
    '"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important',
    '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace',
];
export const fontSizes = [
    10, 12, 13, 15, 17, 20, 24, 32, 36, 48, 64, 80,
];
export const fontWeights = {
    bold: 700,
    medium: 500,
    regular: 400,
    semibold: 600,
};
export const lineHeights = {
    base: 1,
    body: 1.6,
    heading: 1.3,
    sub: 1.5,
};
