import React from 'react';
import { BtwLink } from '../BtwLink';
import { BaseError } from './BaseError';
export function UnhandledError() {
    const subheading = (React.createElement(React.Fragment, null,
        "It looks like there was an unexpected error. It pains us to ask, but please can you try refreshing the page? If the problem persists,",
        ' ',
        React.createElement(BtwLink, { href: "mailto:help@ballparkhq.com", target: "_blank", variant: "current" }, "get in touch"),
        "."));
    return React.createElement(BaseError, { heading: "Something went wrong", subheading: subheading });
}
