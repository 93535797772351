/**
 * Early on in Ballpark we added a 'type' field to distinguish steps that were a
 * different type on the frontend but the same on the backend. The reasoning at
 * the time was that it was too much effort to change the backend to support the
 * new step types. In hindisght, this was a mistake and has caused a lot of
 * issues with frontend code. This file defines a 'flat' step class that maps
 * a (className, type) pair to a single class name which makes typing much easier.
 */
export var FlatStepClass;
(function (FlatStepClass) {
    FlatStepClass["Instruction"] = "Instruction";
    FlatStepClass["FiveSecondTest"] = "FiveSecondTest";
    FlatStepClass["MultipleChoice"] = "MultipleChoice";
    FlatStepClass["PreferenceTest"] = "PreferenceTest";
    FlatStepClass["FirstClickStep"] = "FirstClickStep";
    FlatStepClass["FreeRoamStep"] = "FreeRoamStep";
    FlatStepClass["GoalBasedStep"] = "GoalBasedStep";
    FlatStepClass["QuestionDate"] = "QuestionDate";
    FlatStepClass["QuestionEmail"] = "QuestionEmail";
    FlatStepClass["QuestionNumerical"] = "QuestionNumerical";
    FlatStepClass["QuestionText"] = "QuestionText";
    FlatStepClass["RatingScale"] = "RatingScale";
    FlatStepClass["TaglineCopyTest"] = "TaglineCopyTest";
    FlatStepClass["WebsiteTask"] = "WebsiteTask";
    FlatStepClass["YesOrNo"] = "YesOrNo";
    FlatStepClass["LegalStep"] = "LegalStep";
    FlatStepClass["MediaSettings"] = "MediaSettings";
    FlatStepClass["CardSortingHybrid"] = "CardSortingHybrid";
    FlatStepClass["CardSortingOpen"] = "CardSortingOpen";
    FlatStepClass["CardSortingClosed"] = "CardSortingClosed";
})(FlatStepClass || (FlatStepClass = {}));
