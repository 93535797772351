export var StepClass;
(function (StepClass) {
    StepClass["Instruction"] = "Instruction";
    StepClass["BannerAdTest"] = "BannerAdTest";
    StepClass["FiveSecondTest"] = "FiveSecondTest";
    StepClass["MultipleChoice"] = "MultipleChoice";
    StepClass["PreferenceTest"] = "PreferenceTest";
    StepClass["PrototypeTask"] = "PrototypeTask";
    StepClass["Question"] = "Question";
    StepClass["RatingScale"] = "RatingScale";
    StepClass["TaglineCopyTest"] = "TaglineCopyTest";
    StepClass["WebsiteTask"] = "WebsiteTask";
    StepClass["YesOrNo"] = "YesOrNo";
    StepClass["MediaSettings"] = "MediaSettings";
    StepClass["CardSortingHybrid"] = "CardSortingHybrid";
    StepClass["CardSortingOpen"] = "CardSortingOpen";
    StepClass["CardSortingClosed"] = "CardSortingClosed";
})(StepClass || (StepClass = {}));
export var RecordingPipeline;
(function (RecordingPipeline) {
    RecordingPipeline["StreamingFromClient"] = "0010-StreamingFromClient";
    RecordingPipeline["ClientDisconnect"] = "0011-ClientDisconnect";
    RecordingPipeline["ClientReconnectAttempted"] = "0012-ClientReconnectAttempted";
    RecordingPipeline["ClientReconnectAttemptSuccess"] = "0013-ClientReconnectAttemptSuccess";
    RecordingPipeline["ClientReconnectAttemptFailed"] = "0014-ClientReconnectAttemptFailed";
    RecordingPipeline["UserInitiatedDisconnect"] = "0019-UserInitiatedDisconnect";
    RecordingPipeline["StreamingFromClientComplete"] = "0020-StreamingFromClientComplete";
    RecordingPipeline["ProcessingInstructionSent"] = "0030-ProcessingInstructionSent";
    RecordingPipeline["JanusProcessing"] = "0040-JanusProcessing";
    RecordingPipeline["JanusProcessingComplete"] = "0050-JanusProcessingComplete";
    RecordingPipeline["UploadingToGCP"] = "0060-UploadingToGCP";
    RecordingPipeline["UploadingToGCPComplete"] = "0070-UploadingToGCPComplete";
    RecordingPipeline["FFMPEGProcessing"] = "0080-FFMPEGProcessing";
    RecordingPipeline["FFMPEGProcessingMissingData"] = "0081-FFMPEGProcessingMissingData";
    RecordingPipeline["FFMPEGProcessingComplete"] = "0090-FFMPEGProcessingComplete";
    RecordingPipeline["WritingToGCP"] = "0100-WritingToGCP";
    RecordingPipeline["WritingToGCPComplete"] = "0110-WritingToGCPComplete";
    RecordingPipeline["NotifyingRUS"] = "0120-NotifyingRUS";
    // Note: no NotifyingRUSComplete because the recording will be marked as Ready if it succeeds
    RecordingPipeline["Ready"] = "0130-Ready";
    RecordingPipeline["Error"] = "0140-Error";
    RecordingPipeline["FFMPEGProcessingError"] = "0141-FFMPEGProcessingError";
})(RecordingPipeline || (RecordingPipeline = {}));
export var RecordingStatus;
(function (RecordingStatus) {
    RecordingStatus["AcquiringMedia"] = "acquiring_media";
    RecordingStatus["Streaming"] = "streaming";
    RecordingStatus["Processing"] = "processing";
    RecordingStatus["Ready"] = "ready";
    RecordingStatus["Failed"] = "failed";
})(RecordingStatus || (RecordingStatus = {}));
export var StepRecordingPermission;
(function (StepRecordingPermission) {
    StepRecordingPermission["Granted"] = "GRANTED";
    StepRecordingPermission["Denied"] = "DENIED";
    StepRecordingPermission["NotRequested"] = "NOT_REQUESTED";
    StepRecordingPermission["NotSupported"] = "NOT_SUPPORTED";
})(StepRecordingPermission || (StepRecordingPermission = {}));
export var RatingType;
(function (RatingType) {
    RatingType["Numerical"] = "Numerical";
    RatingType["Stars"] = "Stars";
    RatingType["Hearts"] = "Hearts";
    RatingType["Thumbs"] = "Thumbs";
})(RatingType || (RatingType = {}));
