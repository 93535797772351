import { __rest } from "tslib";
import React from 'react';
import { pluralize } from '@marvelapp/core';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Image } from '../Image';
import { ScrollArea } from '../ScrollArea';
import { Stack } from '../Stack';
import { cn } from '../utils';
function Root(props) {
    const { children, type = 'always' } = props, rest = __rest(props, ["children", "type"]);
    return (React.createElement(ScrollArea.Root, { type: type },
        React.createElement(ScrollArea.Viewport, null,
            React.createElement(Stack, Object.assign({ align: "stretch", direction: "row" }, rest), children),
            type === 'always' && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "h-[38px]" }),
                React.createElement("div", { className: "absolute bottom-0 left-0 right-0 h-[38px] border-t border-gray-200" })))),
        React.createElement(ScrollArea.Scrollbar
        // Target the thumb
        , { 
            // Target the thumb
            className: cn({
                'p-4 [&>div>div]:bg-gray-900 [&>div>div]:hover:bg-gray-900/70': type === 'always',
            }), orientation: "horizontal" })));
}
function Category(props) {
    const { title, className, categoriesCount, cardsCount, children } = props, rest = __rest(props, ["title", "className", "categoriesCount", "cardsCount", "children"]);
    return (React.createElement("div", Object.assign({ "data-testid": "category", className: cn('border-r', 'last:border-none', 'border-gray-200', '[&>div>div]:px-4', '[&>div>div]:py-3', '[&>div>div]:first:pl-8', '[&>div>span]:p-4', '[&>div>span]:first:pl-8', className) }, rest),
        React.createElement(Stack, { className: cn({
                'w-60': categoriesCount > 2,
                'w-[348px]': categoriesCount === 2,
                'w-[698px]': categoriesCount === 1,
            }) },
            React.createElement(Stack, { align: "baseline", className: "border-b border-gray-200", direction: "row", gap: "1", justify: "between", width: "full" },
                React.createElement(BtwHeading, { truncate: true, asChild: true, size: "sm" },
                    React.createElement("h3", { "data-testid": "category-title" }, title)),
                React.createElement(BtwText, { className: "whitespace-nowrap", size: "xs", "data-testid": "card-count" }, cardsCount
                    ? `${cardsCount} ${pluralize(cardsCount, 'card')}`
                    : null)),
            React.createElement(Stack, { asChild: true, gap: "2.5", width: "full" },
                React.createElement("span", null, children)))));
}
function Card(props) {
    const { title, index, imageSrc } = props, rest = __rest(props, ["title", "index", "imageSrc"]);
    return (React.createElement(Stack, Object.assign({ align: "center", direction: "row", justify: "between", width: "full", "data-testid": "card" }, rest),
        React.createElement("div", { className: "min-w-0 flex-1 text-left" },
            index !== undefined && (React.createElement(BtwText, { size: "sm", className: "pr-2", asChild: true, "data-testid": "card-rank" },
                React.createElement("span", null,
                    index + 1,
                    "."))),
            React.createElement(BtwText, { size: "sm", "data-testid": "card-title", asChild: true, className: "break-words" },
                React.createElement("span", null, title))),
        imageSrc && (React.createElement("div", { className: "flex-shrink-0" },
            React.createElement(Image, { className: "size-6 rounded", src: imageSrc })))));
}
export const CardSortingResponse = {
    Root,
    Category,
    Card,
};
