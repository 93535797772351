import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@marvelapp/ballpark-application';
import { CONTACT_SUPPORT } from '@marvelapp/ballpark-help';
import { CheckIcon, HourglassIcon, LoaderIcon, WarningIcon, } from '@marvelapp/ballpark-icons';
import { decodeParams, fetchJSON } from '@marvelapp/core';
import { BtwButton } from './BtwButton';
import { BtwLink } from './BtwLink';
import { BtwModal } from './BtwModal';
import { BtwText } from './BtwText';
import { Stack } from './Stack';
export const BundlePurchaseConfirmation = React.memo(function BundlePurchaseConfirmation() {
    const accessToken = useAuth();
    const { search } = useLocation();
    const params = decodeParams(search);
    const [errorCode, setErrorCode] = useState(undefined);
    const [purchaseDetails, setPurchaseDetails] = useState(undefined);
    const [pollingStatus, setPollingStatus] = useState(null);
    const shouldShowBundleConfirmation = !!params.checkout_uuid && params.success;
    useEffect(() => {
        if (!shouldShowBundleConfirmation)
            return;
        const controller = new AbortController();
        const { signal } = controller;
        const longTimeTimeout = setTimeout(() => {
            setPollingStatus('LONG_TIME');
        }, 5000);
        const tooLongTimeout = setTimeout(() => {
            controller.abort();
            setPollingStatus('TOO_LONG');
        }, 20000);
        const confirmPurchase = () => {
            fetchJSON(`/api/confirm-credit-purchase/${params.checkout_uuid}/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                signal,
            })
                .then((res) => {
                setPurchaseDetails(res);
                clearTimeout(longTimeTimeout);
                clearTimeout(tooLongTimeout);
            })
                .catch((err) => {
                // 402 is the error code for "Purchase not confirmed" and is
                // used to determine that we should keep polling
                if (err.status !== 402) {
                    setErrorCode(err.status);
                }
                else {
                    setTimeout(confirmPurchase, 2500);
                }
            });
        };
        setPollingStatus('POLLING');
        confirmPurchase();
    }, [shouldShowBundleConfirmation, params.checkout_uuid]);
    const { title, body, icon } = getModalContent({
        purchaseDetails,
        pollingStatus,
        errorCode,
    });
    const canCloseModal = purchaseDetails || errorCode || pollingStatus === 'TOO_LONG';
    const disableClose = useCallback((e) => {
        if (!canCloseModal) {
            e.preventDefault();
        }
    }, [canCloseModal]);
    return (React.createElement(BtwModal.Root, { defaultOpen: shouldShowBundleConfirmation },
        React.createElement(BtwModal.Content, { "data-testid": "purchase-confirmation", onEscapeKeyDown: disableClose, onPointerDownOutside: disableClose, onInteractOutside: disableClose },
            React.createElement(BtwModal.Body, null,
                React.createElement(Stack, { align: "center", className: "pt-8 text-center", gap: "2" },
                    icon,
                    React.createElement(BtwText, { weight: "semibold", variant: "primary" }, title),
                    React.createElement(BtwText, { size: "sm", variant: "primary" }, body))),
            React.createElement(BtwModal.Footer, null,
                React.createElement(BtwModal.Close, { asChild: true },
                    React.createElement(BtwButton, { "data-testid": "continue-cta", disabled: !canCloseModal, width: "full", variant: "primary" }, "Continue"))))));
});
function getModalContent({ errorCode, purchaseDetails, pollingStatus, }) {
    if (errorCode) {
        return {
            title: 'There was an issue with your bundle purchase',
            body: `Please get in touch with us and quote error code ${errorCode}`,
            icon: React.createElement(WarningIcon, null),
        };
    }
    if (purchaseDetails) {
        return {
            title: 'Payment successful!',
            body: `We've added ${purchaseDetails.quantity_purchased} more credits to your workspace. May the insights be with you!`,
            icon: React.createElement(CheckIcon, null),
        };
    }
    if (pollingStatus === 'TOO_LONG') {
        return {
            title: 'This is taking longer than expected',
            body: (React.createElement(React.Fragment, null,
                "We\u2019ve received your payment, and your credits balance should update shortly. If it doesn\u2019t,",
                ' ',
                React.createElement(BtwLink, { "data-testid": "contact-link", href: CONTACT_SUPPORT, target: "_blank", variant: "secondary" }, "get in touch"),
                ' ',
                "with us.")),
            icon: React.createElement(HourglassIcon, null),
        };
    }
    if (pollingStatus === 'LONG_TIME') {
        return {
            title: 'This is taking longer than expected',
            body: 'We’ve received your payment, but it’s taking bit longer to update your credits balance. Hang on for a bit more.',
            icon: React.createElement(LoaderIcon, { className: "animate-spin" }),
        };
    }
    return {
        title: 'Updating your credits balance',
        body: 'We’ve received your payment; this should only take a moment.',
        icon: React.createElement(LoaderIcon, { className: "animate-spin" }),
    };
}
