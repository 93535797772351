import { __rest } from "tslib";
import React from 'react';
import { color } from 'styled-system';
import styled, { keyframes } from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Button } from './Button';
export function PulsatingButton(_a) {
    var { children, shouldPulse, pulseColor = 'smoke' } = _a, props = __rest(_a, ["children", "shouldPulse", "pulseColor"]);
    if (!shouldPulse) {
        return (React.createElement(Button, Object.assign({ style: {
                height: '36px',
                padding: '0 16px',
                lineHeight: '20px',
                fontSize: '14px',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
            } }, props), children));
    }
    return (React.createElement(PulseAnimation, { color: pulseColor },
        React.createElement(Relative, null,
            React.createElement(Button, Object.assign({ style: {
                    height: '36px',
                    padding: '0 16px',
                    lineHeight: '20px',
                    fontSize: '14px',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                } }, props), children))));
}
const pulseBig = keyframes `
  0% {
    box-shadow: 0 0 0 0 currentColor;
    opacity: 0.8;
  }
  70% {
      box-shadow: 0 0 0 20px currentColor;
      opacity: 0;
  }
  100% {
      box-shadow: 0 0 0 0 currentColor;
      opacity: 0;
  }
`;
const pulseSmall = keyframes `
  0% {
    box-shadow: 0 0 0 0 currentColor;
    opacity: 0.8;
  }
  70% {
      box-shadow: 0 0 0 10px currentColor;
      opacity: 0;
  }
  100% {
      box-shadow: 0 0 0 0 currentColor;
      opacity: 0;
  }
`;
export const PulseAnimation = styled(Box) `
  position: relative;
  height: 36px; // Don't hate the player, hate the game.

  &:before {
    ${color};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    animation: ${(props) => (props.isSmall ? pulseSmall : pulseBig)} 2s infinite;
    border-radius: ${(props) => props.theme.radii.m}px;
  }
`;
const Relative = styled.div `
  position: relative;
`;
