import styled from '@marvelapp/styled';
import Flex from '../Flex';
export const ImageHolder = styled.div `
  @media (max-height: 800px) {
    display: none;
  }
`;
export const TinyUpsellerWrapper = styled(Flex) `
  @media (max-height: 500px) {
    display: none;
  }
`;
