export const kinds = {
    error: {
        bg: 'red',
    },
    information: {
        bg: 'yellowLight',
    },
    success: {
        bg: 'green',
    },
};
