import { __rest } from "tslib";
import React from 'react';
import { tv } from 'tailwind-variants';
import { BtwHeading } from './BtwHeading';
import { BtwText } from './BtwText';
import { Stack } from './Stack';
const root = tv({
    base: [],
    variants: {
        size: {
            sm: [
                // Icon
                '[&_.cta-message--icon>svg]:w-6',
                '[&_.cta-message--icon>svg]:h-6',
                // Heading
                '[&_.cta-message--heading]:text-base',
                '[&_.cta-message--heading]:tracking-normal',
            ],
            base: [
                // Icon
                '[&_.cta-message--icon>svg]:w-12',
                '[&_.cta-message--icon>svg]:h-12',
                '[&_.cta-message--icon>svg>path]:stroke-[3px]',
                // Heading
                '[&_.cta-message--heading]:text-xl',
                '[&_.cta-message--heading]:-tracking-[0.01em]',
            ],
        },
    },
    defaultVariants: {
        size: 'base',
    },
});
function Root(props) {
    const { children, size, className } = props;
    return (React.createElement(Stack, { className: root({ className, size }), align: "center", width: "full", gap: "6" }, children));
}
function Header(props) {
    const { children, icon } = props;
    return (React.createElement(Stack, { align: "center", width: "full", gap: "4" },
        icon && (React.createElement(Stack, { align: "center", className: "cta-message--icon text-gray-900", width: "full" }, icon)),
        React.createElement(Stack, { align: "center", gap: "1.5" }, children)));
}
function Heading(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(BtwHeading, Object.assign({ asChild: true, className: "cta-message--heading" }, rest),
        React.createElement("h2", null, children)));
}
function Description(props) {
    return React.createElement(BtwText, Object.assign({ size: "sm", leading: "relaxed" }, props));
}
function Actions(props) {
    return (React.createElement(Stack, { gap: "3", direction: "row", align: "center", justify: "center", width: "full" }, props.children));
}
export const CtaMessage = {
    Root,
    Header,
    Heading,
    Description,
    Actions,
};
