import { __rest } from "tslib";
import React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Text } from './Text';
const sizes = {
    xs: {
        fontSize: 'xs',
        fontWeight: 'medium',
        lineHeight: 'base',
    },
    s: {
        fontSize: 's',
        fontWeight: 'medium',
        lineHeight: 'base',
    },
    m: {
        fontSize: 'm',
        fontWeight: 'medium',
        lineHeight: 'base',
    },
    l: {
        fontSize: 'l',
        fontWeight: 'medium',
        lineHeight: 'base',
    },
    xl: {
        fontSize: 'xl',
        fontWeight: 'medium',
        lineHeight: 'base',
    },
};
const kinds = {
    primary: {
        color: 'slate12',
    },
    secondary: {
        color: 'slate11',
    },
    blue12: {
        color: 'blue12',
    },
};
export function Label(_a) {
    var { children, htmlFor, isOptional = true, kind = 'primary', size = 'm' } = _a, rest = __rest(_a, ["children", "htmlFor", "isOptional", "kind", "size"]);
    return (React.createElement(Text, Object.assign({ as: "label", htmlFor: htmlFor }, sizes[size], kinds[kind], rest),
        children,
        !isOptional && ' *'));
}
export const LabelContainer = styled(Box) `
  display: flex;
  align-items: start;
  justify-content: space-between;
`;
