import { __rest } from "tslib";
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { tv } from 'tailwind-variants';
import { BtwButton } from './BtwButton';
import { basePopoverVariants } from './BtwPopover';
import { Stack as StackPrimitive } from './Stack';
import { cn } from './utils';
function Trigger(props) {
    const { disabled } = props, rest = __rest(props, ["disabled"]);
    return (React.createElement(DropdownPrimitive.Trigger, { asChild: true, disabled: disabled },
        React.createElement(BtwButton, Object.assign({ disabled: disabled }, rest))));
}
/**
 * Dropdown menu content
 */
const content = tv({
    extend: basePopoverVariants,
    base: [
        // Max available screen height depending on the dropdown position
        'max-h-[var(--radix-dropdown-menu-content-available-height)]',
    ],
    variants: {
        size: {
            base: [
                'rounded-xl',
                '[&_.dropdown-stack]:p-2',
                '[&_.dropdown-stack]:gap-1',
            ],
        },
        width: {
            auto: ['min-w-[var(--radix-dropdown-menu-trigger-width)]'],
            trigger: ['w-[var(--radix-dropdown-menu-trigger-width)]'],
        },
    },
    defaultVariants: {
        size: 'base',
        width: 'auto',
    },
});
function Content(props) {
    const { className, size, width } = props, rest = __rest(props, ["className", "size", "width"]);
    return (React.createElement(DropdownPrimitive.Portal, null,
        React.createElement(DropdownPrimitive.Content, Object.assign({ className: content({ size, className, width }), collisionPadding: 16, sideOffset: 8 }, rest))));
}
function Item(props) {
    const { children, className, disabled, onSelect } = props, rest = __rest(props, ["children", "className", "disabled", "onSelect"]);
    return (React.createElement(DropdownPrimitive.Item, { disabled: disabled, asChild: true, onSelect: onSelect },
        React.createElement(BtwButton, Object.assign({ className: cn(
            // Includes some overrides to the buttons, since the default styles
            // are a little too much for dropdown items.
            'justify-start', 
            // Overriding the font weight here, because semibold is too much
            // for dropdown items
            'font-medium', 
            // Overrides for the disabled state
            'disabled:bg-white', 
            // Overrides for the focus state
            // TODO: Maybe we need to find a way to improve the default focus state
            // e.g. focus-visible vs focus maybe? Or a variant?
            'focus-visible:ring-0', 'focus-visible:outline-0', 'focus-visible:ring-transparent', 'focus-visible:outline-transparent', 
            // Overrides for the hover state
            'hover:bg-transparent', 'hover:ring-transparent', 
            // Using the library's state to highlight hover/focus items.
            // This enables seamless switch between hover & keyboard interactions
            'data-[highlighted]:bg-gray-400/25', className), width: "full", variant: "ghost" }, rest),
            React.createElement("span", { className: "min-w-0 truncate" }, children))));
}
/**
 * Dropdown menu separator
 */
function Separator() {
    return (React.createElement(DropdownPrimitive.Separator, { className: cn('h-px', 'w-full', 'bg-gray-600/10') }));
}
/**
 * Dropdown menu stack
 */
function Stack(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement(StackPrimitive, Object.assign({ className: "dropdown-stack", width: "full" }, rest));
}
export const BtwDropdown = {
    Content,
    Item,
    Root: DropdownPrimitive.Root,
    Separator,
    Stack,
    Trigger,
};
