import { cloneDeep, merge } from 'lodash-es';
import { devOverrides, isDebugMode } from '@marvelapp/core';
export default function applyUserOverrides(user, adminBillingData) {
    var _a;
    const billing = (_a = adminBillingData === null || adminBillingData === void 0 ? void 0 : adminBillingData.user) === null || _a === void 0 ? void 0 : _a.company.billing;
    const billingOverride = billing ? { company: { billing } } : {};
    const storedOverrides = getStoredOverrides();
    return merge(cloneDeep(user), billingOverride, ...storedOverrides);
}
function getStoredOverrides() {
    if (isDebugMode()) {
        const storedRole = devOverrides.role.get();
        const storedPlanLabel = devOverrides.plan.get();
        const roleOverride = storedRole ? { role: storedRole } : {};
        const planLabelOverride = storedPlanLabel
            ? { company: { billing: { plan: { label: storedPlanLabel } } } }
            : {};
        return [roleOverride, planLabelOverride];
    }
    return [];
}
