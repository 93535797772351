import { addMonths, format, getYear, setMonth, setYear, startOfMonth, } from 'date-fns';
import React, { useCallback, useMemo, useState, } from 'react';
import { DayPicker, useNavigation, } from 'react-day-picker';
import { CalendarIcon, ChevronBottomSmallIcon, ChevronLeftIcon, ChevronRightIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton } from './BtwButton';
import { BtwInput } from './BtwInput';
import { BtwPopover } from './BtwPopover';
import { BtwText } from './BtwText';
import { Stack } from './Stack';
import { cn } from './utils';
const classNames = {
    day_outside: 'text-gray-400',
    day_selected: 'bg-gray-900 text-white hover:bg-gray-900 font-medium',
    day_today: 'font-bold',
    day: 'size-8 hover:bg-gray-100 rounded-md',
    head_cell: 'size-8 font-medium text-center',
    root: 'p-5 font-body text-13',
    table: 'w-full',
    tbody: 'text-gray-900 font-medium',
};
function DatePickerSelect(props) {
    const { currentValue, children, onChange, testId } = props;
    return (React.createElement("div", { className: cn('relative', 'overflow-hidden', 'ring-1', 'ring-transparent', 'rounded-md', 'has-[:focus-visible]:outline-none', 'has-[:focus-visible]:outline-gray-300', 'has-[:focus-visible]:ring-gray-600') },
        React.createElement("select", { className: "absolute left-0 top-0 z-10 opacity-0", onChange: onChange, "data-testid": testId }, children),
        React.createElement(Stack, { direction: "row", gap: "0.5", align: "center", className: "h-7 px-1.5 py-0.5" },
            React.createElement(BtwText, { size: "13", weight: "semibold", variant: "primary", className: "z-0", "data-testid": "date-display-text" }, currentValue),
            React.createElement(ChevronBottomSmallIcon, { className: "size-4" }))));
}
function CustomCaption(props) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    const { displayMonth, displayIndex } = props;
    const months = useMemo(() => {
        const now = new Date();
        return Array.from({ length: 12 }, (_, month) => setMonth(now, month));
    }, []);
    const years = useMemo(() => {
        // According to Kas, the oldest person alive was born in 1904
        const startYear = 1904;
        const currentYear = getYear(new Date());
        // Creating an array from 1904 to a hundred years from now
        return Array.from({ length: currentYear + 100 - startYear }, (_, year) => setYear(startYear, startYear + year));
    }, []);
    const handleMonthChange = useCallback((event) => {
        const selectedMonth = Number(event.target.value);
        const newMonth = setMonth(startOfMonth(displayMonth), selectedMonth);
        goToMonth(addMonths(newMonth, displayIndex ? -displayIndex : 0));
    }, [goToMonth, displayMonth, displayIndex]);
    const handleYearChange = useCallback((event) => {
        const selectedYear = Number(event.target.value);
        const newMonth = setYear(startOfMonth(displayMonth), selectedYear);
        goToMonth(addMonths(newMonth, displayIndex ? -displayIndex : 0));
    }, [goToMonth, displayMonth, displayIndex]);
    return (React.createElement(Stack, { direction: "row", align: "center", justify: "between", className: "mb-4" },
        React.createElement(BtwButton, { standaloneIcon: React.createElement(ChevronLeftIcon, null), size: "sm", disabled: !previousMonth, onClick: () => previousMonth && goToMonth(previousMonth) }),
        React.createElement(Stack, { direction: "row", gap: "0.5" },
            React.createElement(DatePickerSelect, { currentValue: format(displayMonth, 'MMMM'), onChange: handleMonthChange, testId: "date-picker-month" }, months.map((month) => (React.createElement("option", { key: month.getMonth(), value: month.getMonth(), selected: month.getMonth() === displayMonth.getMonth() }, format(month, 'MMMM'))))),
            React.createElement(DatePickerSelect, { currentValue: format(displayMonth, 'yyy'), onChange: handleYearChange, testId: "date-picker-year" }, years.map((year) => (React.createElement("option", { key: year.getFullYear(), value: year.getFullYear(), selected: year.getFullYear() === displayMonth.getFullYear() }, format(year, 'yyy')))))),
        React.createElement(BtwButton, { standaloneIcon: React.createElement(ChevronRightIcon, null), size: "sm", disabled: !nextMonth, onClick: () => nextMonth && goToMonth(nextMonth) })));
}
function DatePicker(props) {
    const { onSelect, trigger, value } = props;
    const date = value ? new Date(value) : undefined;
    const [monthInView, setMonthInView] = useState(date);
    return (React.createElement(BtwPopover.Root, { onOpenChange: (isOpen) => {
            // on open, set the selected month
            if (isOpen)
                setMonthInView(date);
        } },
        React.createElement(BtwPopover.Trigger, { className: "w-full" }, trigger || (React.createElement(BtwInput.Root, { leadingIcon: React.createElement(CalendarIcon, null), width: "full" },
            React.createElement(BtwInput.Field, { id: "date", placeholder: "Pick a date", readOnly: true, value: date && format(date, 'PPP') })))),
        React.createElement(BtwPopover.Content, { align: "center", "data-testid": "date-picker-popover" },
            React.createElement(DayPicker, { classNames: classNames, components: { Caption: CustomCaption }, mode: "single", month: monthInView, onMonthChange: setMonthInView, onSelect: onSelect, selected: date, showOutsideDays: true, weekStartsOn: 1 }))));
}
export { DatePicker };
