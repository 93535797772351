import { __rest } from "tslib";
import * as Popover from '@radix-ui/react-popover';
import { Command, useCommandState } from 'cmdk';
import React, { forwardRef, useState } from 'react';
import { ChevronRightSmallIcon, SearchIcon } from '@marvelapp/ballpark-icons';
import { BtwInput } from './BtwInput';
import { BtwPopover } from './BtwPopover';
import { BtwText } from './BtwText';
import { Combobox, ComboboxContext as SearchContext, useComboboxState as useSearchState, } from './Combobox';
import { Stack } from './Stack';
import { cn } from './utils';
/**
 * Root
 */
function Root(props) {
    const { children, filter, shouldFilter } = props, rest = __rest(props, ["children", "filter", "shouldFilter"]);
    const [open, onOpenChange] = useState(false);
    return (React.createElement(BtwPopover.Root, Object.assign({ open: open, onOpenChange: onOpenChange }, rest),
        React.createElement(SearchContext.Provider, { value: {
                open,
                onOpenChange,
                variant: 'multi-select',
                selected: 0,
                onClear: () => {
                    // Implement the clear functionality here
                },
                total: 0,
            } },
            React.createElement(Command, { className: "w-full", shouldFilter: shouldFilter }, children))));
}
/**
 * Search input
 */
const Input = React.forwardRef(function Input(props, ref) {
    const searchState = useSearchState();
    const { value, onValueChange, onFocus, onBlur } = props;
    return (React.createElement(Stack, { width: "full" },
        React.createElement(Popover.Anchor, { className: "w-full" },
            React.createElement(Command.Input, { placeholder: "Search for things here\u2026", asChild: true, value: value, onValueChange: onValueChange, ref: ref },
                React.createElement(BtwInput.Root, { width: "full", leadingIcon: React.createElement(SearchIcon, null) },
                    React.createElement(BtwInput.Field, { id: "search-input", placeholder: "Search for participant demographics and interests\u2026", onFocus: onFocus !== null && onFocus !== void 0 ? onFocus : (() => searchState.onOpenChange(true)), onBlur: onBlur !== null && onBlur !== void 0 ? onBlur : (() => searchState.onOpenChange(false)), "data-testid": props['data-testid'] }))))));
});
/**
 * Search content
 */
const Content = forwardRef(function Content(props, ref) {
    const { children, onPointerDownOutside, collisionBoundary, collisionPadding, } = props;
    const searchState = useSearchState();
    // You must always render the Command.List, even if the popover is closed
    if (!searchState.open)
        return React.createElement(Command.List, { "data-testid": props['data-testid'] });
    return (React.createElement(BtwPopover.Content
    // This is required so it doesn't automatically focus on the popover content element
    , { 
        // This is required so it doesn't automatically focus on the popover content element
        onOpenAutoFocus: (event) => {
            event.preventDefault();
        }, onPointerDownOutside: onPointerDownOutside, 
        // And this is counter-intuitive, but because it's not automatically focused,
        // it triggers the onFocusOutside event instead. This immediately changes the
        // value of open to false
        onFocusOutside: (event) => {
            event.preventDefault();
        }, className: cn('max-w-[--radix-popper-anchor-width]', props.className), ref: ref, "data-testid": props['data-testid'], collisionBoundary: collisionBoundary, collisionPadding: collisionPadding },
        React.createElement(Stack, { width: "full", className: cn('p-1.5', '[&>div]:w-full'), asChild: true },
            React.createElement(Command.List, null, children))));
});
function FilterGroupItem(props) {
    const { leadingIcon, groupTitle, filterTitle, highlight, value } = props, rest = __rest(props, ["leadingIcon", "groupTitle", "filterTitle", "highlight", "value"]);
    return (React.createElement(Combobox.Item, Object.assign({}, rest, { size: "intermediate", leadingIcon: leadingIcon, className: cn('items-center', '!h-9', '!pl-2.5'), value: value }),
        React.createElement(Stack, { direction: "row", gap: "0.5", width: "full", className: "min-w-0 items-end" },
            groupTitle && (React.createElement(React.Fragment, null,
                React.createElement(BtwText, { variant: "primary", truncate: true, className: "" }, groupTitle),
                ' ',
                React.createElement(ChevronRightSmallIcon, { className: "size-5 shrink-0" }),
                ' ')),
            React.createElement(BtwText, { truncate: true, variant: "primary" }, filterTitle))));
}
function FilterOptionItem(props) {
    const { optionValue } = props, rest = __rest(props, ["optionValue"]);
    return (React.createElement(Combobox.Item, Object.assign({ check: "checkbox", size: "intermediate", className: cn('[&>span]:pt-0.5', '!h-9') }, rest), optionValue));
}
function Group(props) {
    const { children, className } = props, rest = __rest(props, ["children", "className"]);
    return (React.createElement(Combobox.Group, Object.assign({ className: cn('pt-0', '[&:not(:first-child)]:pt-1.5', '[&:not(:last-child)]:pb-1.5', className) }, rest), children));
}
const Search = {
    Root,
    Input,
    Content,
    FilterGroupItem,
    FilterOptionItem,
    Empty: Combobox.Empty,
    LoadingState: Combobox.LoadingState,
    Group,
};
export { Search, useCommandState };
