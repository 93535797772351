import { __rest } from "tslib";
import React from 'react';
import { Icon } from '../Icon';
export function ConditionsIcon(_a) {
    var { fill = 'licorice', height = 15, width = 15 } = _a, props = __rest(_a, ["fill", "height", "width"]);
    return (React.createElement(Icon, Object.assign({ "data-testid": "conditions-icon", height: height, viewBox: "0 0 37 29", width: width, fill: fill }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.12131 8.87141C1.29288 8.87141 0.62131 8.19984 0.621307 7.37141C0.621306 6.54299 1.29288 5.87141 2.12131 5.87141M2.12131 5.87141L2.12131 8.87141ZM31 5.87131L28.6345 3.50579C28.0487 2.92 28.0487 1.97026 28.6345 1.38447C29.2203 0.798686 30.17 0.79868 30.7558 1.38447L35.682 6.31064C35.9633 6.59195 36.1213 6.97348 36.1213 7.3713C36.1213 7.76912 35.9633 8.15066 35.682 8.43197L30.7558 13.3581C30.17 13.9439 29.2203 13.9439 28.6345 13.3581C28.0487 12.7724 28.0487 11.8226 28.6345 11.2368L31 8.87131L2.12131 8.87141L2.12131 5.87141M2.12131 5.87141L31 5.87131Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.5 23.6801C19 23.5 19 23.0085 19 22.1801C19 21.3517 19.6716 20.6801 20.5 20.6801M20.5 20.6801L20.5 23.6801ZM31.617 20.6799L29.2515 18.3144C28.6657 17.7286 28.6657 16.7788 29.2515 16.1931C29.8373 15.6073 30.787 15.6073 31.3728 16.1931L36.299 21.1192C36.5803 21.4005 36.7383 21.7821 36.7383 22.1799C36.7383 22.5777 36.5803 22.9593 36.299 23.2406L31.3728 28.1667C30.787 28.7525 29.8372 28.7525 29.2515 28.1667C28.6657 27.581 28.6657 26.6312 29.2515 26.0454L31.617 23.6799L20.5 23.6801L20.5 20.6801M20.5 20.6801L31.617 20.6799Z" }),
        React.createElement("path", { d: "M9.06065 11.0607C9.64643 10.4749 10.5962 10.4749 11.182 11.0607L21.0815 20.9602C21.6672 21.5459 21.6672 22.4957 21.0815 23.0815C20.4957 23.6673 19.5459 23.6673 18.9601 23.0815L9.06065 13.182C8.47486 12.5962 8.47486 11.6464 9.06065 11.0607Z" })));
}
