import { __rest } from "tslib";
import React from 'react';
import { CheckIcon, CopyIcon } from '@marvelapp/ballpark-icons';
import { useCopyToClipboard } from '@marvelapp/hooks';
import { BtwButton } from './BtwButton';
import { BtwTooltip } from './BtwTooltip';
export function CopyToClipboardButton(props) {
    const { text } = props, rest = __rest(props, ["text"]);
    const [value, copy] = useCopyToClipboard();
    return (React.createElement(BtwTooltip, { content: React.createElement(React.Fragment, null, value ? 'Copied!' : 'Copy') },
        React.createElement(BtwButton, Object.assign({ onClick: () => copy(text), standaloneIcon: value ? React.createElement(CheckIcon, null) : React.createElement(CopyIcon, null) }, rest))));
}
