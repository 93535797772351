import { useEffect, useLayoutEffect } from 'react';
const canUseDOM = !!(typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement);
export function useLockBodyScroll() {
    useLayoutEffect(() => {
        if (!canUseDOM)
            return;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []); // Empty array ensures effect is only run on mount and unmount
}
export function useKey(handler, { key, keyEvent = 'keydown' }) {
    useEffect(() => {
        if (!canUseDOM)
            return;
        if (handler) {
            const handleEvent = (event) => {
                if (event.key === key) {
                    handler(event);
                }
            };
            document.addEventListener(keyEvent, handleEvent);
            return () => {
                document.removeEventListener(keyEvent, handleEvent);
            };
        }
    }, [handler, key, keyEvent]);
}
export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        if (!canUseDOM)
            return;
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            // or when the target element is not attached to the DOM
            if (!handler ||
                !ref.current ||
                ref.current.contains(event.target) ||
                !event.target.isConnected) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
