import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { PlanLabel } from '@marvelapp/core';
export default function useLogRocket({ user, ignoreEnterpriseCheck, alwaysShow, ignoreMarvel = true, }) {
    var _a;
    const { company, email, firstName, lastName, pk, role } = user;
    const planLabel = (_a = company === null || company === void 0 ? void 0 : company.billing.plan) === null || _a === void 0 ? void 0 : _a.label;
    const isEnterprise = planLabel === PlanLabel.Enterprise;
    const companyId = company === null || company === void 0 ? void 0 : company.pk;
    const companyName = company === null || company === void 0 ? void 0 : company.name;
    const accountId = company === null || company === void 0 ? void 0 : company.billing.accountId;
    const isProduction = process.env.DEPLOY_ENV.includes('production');
    useEffect(() => {
        if (isProduction &&
            !LogRocket._isInitialized &&
            (isEnterprise || ignoreEnterpriseCheck || alwaysShow) &&
            !(ignoreMarvel && email.includes('@marvelapp.com'))) {
            LogRocket.init('mrwo1p/marvel', {
                // Aggregate console errors to ensure we don't miss potential errors this way
                console: {
                    shouldAggregateConsoleErrors: true,
                },
                release: process.env.RELEASE,
            });
            LogRocket.identify(pk, {
                name: `${firstName} ${lastName}`,
                email,
                role,
                companyName,
                companyId,
                accountId,
                planLabel,
            });
        }
        if (LogRocket._isInitialized) {
            // Call identify again if info changes when LogRocket is already initialised.
            // This could happen when a user lands on ballpark when they're not logged in
            // and then they log in. We need to call `identify` again in those cases
            // to have user information associated with the user in LogRocket
            LogRocket.identify(pk, {
                name: `${firstName} ${lastName}`,
                email,
                role,
                companyName,
                companyId,
                accountId,
                planLabel,
            });
        }
    }, [
        accountId,
        alwaysShow,
        companyId,
        companyName,
        email,
        firstName,
        isEnterprise,
        lastName,
        pk,
        planLabel,
        role,
        ignoreEnterpriseCheck,
        isProduction,
        ignoreMarvel,
    ]);
}
