import React, { createContext, useContext, useMemo } from 'react';
import { createPermissions } from './createPermissions';
const PermissionsContext = createContext(null);
export const PermissionsProvider = ({ user, subscription, children, flags }) => {
    const permissions = useMemo(() => createPermissions(user, subscription, flags), [user, subscription, flags]);
    return (React.createElement(PermissionsContext.Provider, { value: permissions }, children));
};
export function usePermissions() {
    return useContext(PermissionsContext);
}
