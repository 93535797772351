import { __rest } from "tslib";
import React from 'react';
import { BtwHeading } from './BtwHeading';
import { BtwText } from './BtwText';
import { BtwToggle } from './BtwToggle';
import { BtwTooltip } from './BtwTooltip';
import { Stack } from './Stack';
function Root(props) {
    const { children, description, disabled, disabledTooltipText, heading, size = 'md', } = props;
    if (!heading && !description) {
        return null;
    }
    return (React.createElement(Stack, { gap: "3", direction: "row", justify: "between", align: "center", width: "full" },
        React.createElement(Stack, { gap: size === 'md' ? '1' : '0.5', align: "start" },
            React.createElement(BtwHeading, { weight: "medium", size: size === 'md' ? 'sm' : '13' }, heading),
            description && (React.createElement(BtwText, { "data-testid": "setting-description", leading: "normal", size: size === 'md' ? '13' : 'xs' }, description))),
        React.createElement(BtwTooltip, { content: disabled ? disabledTooltipText : undefined, disableHoverableContent: true },
            React.createElement("div", null, children))));
}
function Base(props) {
    const { heading, description, disabled, disabledTooltipText, size } = props, rest = __rest(props, ["heading", "description", "disabled", "disabledTooltipText", "size"]);
    return (React.createElement(Root, { heading: heading, description: description, disabled: disabled, disabledTooltipText: disabledTooltipText, size: size },
        React.createElement(BtwToggle, Object.assign({ size: size, variant: "legacyBlue", disabled: disabled }, rest))));
}
const BtwSettingsItem = Object.assign(Base, {
    Root,
});
export { BtwSettingsItem };
