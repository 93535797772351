import { __rest } from "tslib";
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { tv } from 'tailwind-variants';
import { HeartFilledIcon, HeartIcon, StarFilledIcon, StarIcon, ThumbsUpFilledIcon, ThumbsUpIcon, ZoomIcon, } from '@marvelapp/ballpark-icons';
import { BtwButton, baseButtonStyles, secondaryButtonStyles, } from '../BtwButton';
import { BtwCheckbox } from '../BtwCheckbox';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { FullscreenModal } from '../FullscreenModal';
import { Image } from '../Image';
import { Pin } from '../Pin';
import { Stack } from '../Stack';
import { cn } from '../utils';
const root = tv({
    base: [
        ...baseButtonStyles,
        ...secondaryButtonStyles,
        'py-0',
        'px-0',
        'w-full',
        'text-left',
        'rounded-md',
        'gap-4',
        'group',
        'items-start',
    ],
    variants: {
        variant: {
            primary: [],
            red: [
                'hover:bg-red-50',
                'hover:text-red-600',
                'hover:ring-red-300',
                'active:bg-red-100/80',
                'active:text-red-700',
                'active:ring-red-400',
            ],
            blue: [
                'hover:bg-blue-50',
                'hover:text-blue-900',
                'hover:ring-blue-200',
                'active:bg-blue-100/80',
                'active:text-blue-950',
                'active:ring-blue-300',
            ],
            yellow: [
                'hover:bg-yellow-50',
                'hover:text-yellow-600',
                'hover:ring-yellow-300',
                'active:bg-yellow-100/80',
                'active:text-yellow-700',
                'active:ring-yellow-400',
            ],
        },
        selected: {
            true: [],
            false: [],
        },
        hovered: {
            true: [],
            false: [],
        },
    },
    compoundVariants: [
        {
            variant: 'primary',
            selected: true,
            className: [
                'bg-blue-50',
                'text-blue-900',
                'ring-blue-200',
                'hover:bg-blue-100/80',
                'hover:text-blue-950',
                'hover:ring-blue-300',
                'active:bg-blue-100',
                'active:text-blue-950',
                'active:ring-blue-300',
            ],
        },
        {
            variant: 'primary',
            hovered: true,
            className: [
                'bg-blue-50',
                'text-blue-900',
                'ring-blue-200',
                'hover:bg-blue-100/80',
                'hover:text-blue-950',
                'hover:ring-blue-300',
                'active:bg-blue-100',
                'active:text-blue-950',
                'active:ring-blue-300',
            ],
        },
        // Blue
        {
            variant: 'blue',
            selected: true,
            className: [
                'bg-blue-50',
                'text-blue-900',
                'ring-blue-200',
                'hover:bg-blue-100/80',
                'hover:text-blue-950',
                'hover:ring-blue-300',
                'active:bg-blue-100',
                'active:text-blue-950',
                'active:ring-blue-300',
            ],
        },
        {
            variant: 'blue',
            hovered: true,
            className: [
                'bg-blue-50',
                'text-blue-900',
                'ring-blue-200',
                'hover:bg-blue-100/80',
                'hover:text-blue-950',
                'hover:ring-blue-300',
                'active:bg-blue-100',
                'active:text-blue-950',
                'active:ring-blue-300',
            ],
        },
        // Yellow
        {
            variant: 'yellow',
            selected: true,
            className: [
                'bg-yellow-50',
                'text-yellow-600',
                'ring-yellow-300',
                'hover:bg-yellow-100/80',
                'hover:text-yellow-700',
                'hover:ring-yellow-400',
                'active:bg-yellow-100',
                'active:text-yellow-700',
                'active:ring-yellow-400',
            ],
        },
        {
            variant: 'yellow',
            hovered: true,
            className: [
                'bg-yellow-50',
                'text-yellow-600',
                'ring-yellow-300',
                'hover:bg-yellow-100/80',
                'hover:text-yellow-700',
                'hover:ring-yellow-400',
                'active:bg-yellow-100',
                'active:text-yellow-700',
                'active:ring-yellow-400',
            ],
        },
        // Red
        {
            variant: 'red',
            selected: true,
            className: [
                'bg-red-50',
                'text-red-600',
                'ring-red-300',
                'hover:bg-red-100/80',
                'hover:text-red-700',
                'hover:ring-red-400',
                'active:bg-red-100',
                'active:text-red-700',
                'active:ring-red-400',
            ],
        },
        {
            variant: 'red',
            hovered: true,
            className: [
                'bg-red-50',
                'text-red-600',
                'ring-red-300',
                'hover:bg-red-100/80',
                'hover:text-red-700',
                'hover:ring-red-400',
                'active:bg-red-100',
                'active:text-red-700',
                'active:ring-red-400',
            ],
        },
    ],
    defaultVariants: {
        hovered: false,
        selected: false,
        variant: 'primary',
    },
});
function Root(props) {
    const { selected, className, variant, hovered } = props, rest = __rest(props, ["selected", "className", "variant", "hovered"]);
    return (React.createElement("button", Object.assign({ type: "button", className: root({
            className,
            hovered,
            selected,
            variant,
        }) }, rest)));
}
const aspectContainer = tv({
    base: [
        'w-full',
        'rounded',
        'bg-gray-100',
        'aspect-video',
        'border',
        'border-gray-200',
        'group-hover:bg-gray-200/50',
        'group-hover:border-gray-300',
        'ease-smooth',
        'duration-300',
        'transition-combined',
        'flex',
        'items-center',
        'justify-center',
    ],
    variants: {
        variant: {
            primary: [],
            red: [],
            yellow: [],
        },
        selected: {
            true: [],
            false: [],
        },
    },
    compoundVariants: [
        {
            variant: 'primary',
            selected: true,
            className: [
                'bg-blue-100/50',
                'border-blue-200',
                'group-hover:bg-blue-200/50',
                'group-hover:border-blue-300',
            ],
        },
    ],
    defaultVariants: {
        variant: 'primary',
    },
});
function AspectContainer(props) {
    const { selected, className, variant } = props, rest = __rest(props, ["selected", "className", "variant"]);
    return (React.createElement("div", Object.assign({ className: aspectContainer({
            className,
            selected,
            variant,
        }) }, rest)));
}
function ZoomButtonContainer(props) {
    const { children, selected } = props;
    return (React.createElement("div", { className: cn('absolute', 'left-4', 'right-4', 'top-4', 'aspect-video', 'ease-smooth', 'duration-300', 'transition-combined', 'pointer-events-none', {
            'opacity-0': !selected,
            'group-hover:opacity-100': !selected,
        }) },
        React.createElement(Pin.Center, { className: "pointer-events-auto" }, children)));
}
function SelectableCardBase(props) {
    const { title, description, selected, onClick, imageSrc } = props;
    return (React.createElement(Root, { selected: selected },
        React.createElement(Stack, { className: "flex-1 p-4", gap: "4", onClick: onClick },
            imageSrc && (React.createElement("div", { className: "w-full" },
                React.createElement(AspectContainer, { selected: selected },
                    React.createElement("div", null),
                    React.createElement(Image, { className: "block max-h-[90%] max-w-[90%] rounded", src: imageSrc, alt: title })))),
            React.createElement(Stack, { gap: "1", className: "w-full" },
                React.createElement(BtwHeading, { variant: selected ? 'current' : 'primary', size: "sm" }, title),
                description && (React.createElement(BtwText, { leading: "relaxed", size: "13", variant: selected ? 'current' : 'secondary', weight: "normal" }, description)))),
        React.createElement(Pin.Tr, { onClick: onClick, className: cn('ease-smooth', 'duration-300', 'transition-combined', {
                'p-7': imageSrc,
                'p-4': !imageSrc,
                'opacity-0': !selected,
                'group-hover:opacity-100': !selected,
            }) },
            React.createElement(BtwCheckbox, { checked: selected, variant: "blue" })),
        imageSrc && (React.createElement(ZoomButtonContainer, { selected: selected },
            React.createElement(FullscreenModal, { trigger: React.createElement(BtwButton, { leadingIcon: React.createElement(ZoomIcon, null), size: "intermediate" },
                    React.createElement(FormattedMessage, { id: '/q6ZHV', defaultMessage: 'View image' })) },
                React.createElement(Image, { alt: title, className: "block h-auto max-w-full", src: imageSrc }))))));
}
function Rating(props) {
    const { icon, selected, variant, hovered, number } = props, rest = __rest(props, ["icon", "selected", "variant", "hovered", "number"]);
    return (React.createElement(Root, Object.assign({ className: "py-8", selected: selected, variant: variant, hovered: hovered }, rest),
        icon !== 'number' && (React.createElement(Pin.Tl, { className: "p-1.5" },
            React.createElement(Root, { className: "text-xxs pointer-events-none rounded-sm px-1.5 py-1", selected: selected, variant: variant, hovered: hovered }, number))),
        React.createElement("div", { className: "[&>svg>path]:stroke-2 [&>svg]:h-7 [&>svg]:w-7" },
            React.createElement(RatingIcon, { number: number, selected: selected, icon: icon }))));
}
function RatingIcon(props) {
    const { icon, selected, number } = props;
    switch (icon) {
        case 'star':
        default:
            if (selected)
                return React.createElement(StarFilledIcon, null);
            return React.createElement(StarIcon, null);
        case 'heart':
            if (selected)
                return React.createElement(HeartFilledIcon, null);
            return React.createElement(HeartIcon, null);
        case 'thumbs-up':
            if (selected)
                return React.createElement(ThumbsUpFilledIcon, null);
            return React.createElement(ThumbsUpIcon, null);
        // TODO: Handle numebr case
        case 'number':
            return (React.createElement(BtwText, { size: "3xl", weight: "black", variant: "current" }, number));
    }
}
export const SelectableCard = Object.assign(SelectableCardBase, {
    Rating,
});
