export var Identifier;
(function (Identifier) {
    Identifier["Webinar"] = "webinar";
    Identifier["LockedAccount"] = "locked-account-banner";
})(Identifier || (Identifier = {}));
export var Position;
(function (Position) {
    Position["DashboardTinyUpseller"] = "dashboard-tiny-upseller";
    Position["DashboardNotificationBar"] = "dashboard-notification-bar";
    Position["DashboardHintBar"] = "dashboard-hint-bar";
    Position["DashboardSideBar"] = "dashboard-side-bar";
    Position["Floating"] = "floating";
})(Position || (Position = {}));
