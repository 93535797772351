import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { inputKinds } from '../Input/kinds';
import { inputSizes } from './sizes';
import { TextareaElement } from './styles';
/** @type {any} */
const Textarea = forwardRef((_a, ref) => {
    var { disabled = false, display = 'block', fontFamily = 0, fontWeight = 400, isLoading = false, kind = 'default', size = 1 } = _a, props = __rest(_a, ["disabled", "display", "fontFamily", "fontWeight", "isLoading", "kind", "size"]);
    const allProps = Object.assign(Object.assign(Object.assign({}, inputSizes[size]), inputKinds[kind]), props);
    return (React.createElement(TextareaElement, Object.assign({ disabled: disabled, display: display, fontFamily: fontFamily, fontWeight: fontWeight, isLoading: isLoading }, allProps, { ref: ref })));
});
export default Textarea;
