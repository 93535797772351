import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { Button as MarvelButton } from '@marvelapp/ui';
export const kinds = {
    blue8: {
        bg: 'blue8',
        border: 'none',
        color: 'background',
        hoverBg: 'blue8',
        boxShadow: 'none',
    },
    skyBlue: {
        bg: 'sky4',
        color: 'sky11',
        hoverBg: 'sky5',
        boxShadow: 'none',
        hoverBoxShadow: 'none',
        activeBoxShadow: 'none',
    },
    ghost: {
        bg: 'background',
        border: '1px solid',
        borderColor: 'slate6',
        color: 'slate12',
        hoverBg: 'slate2',
        boxShadow: 'button.secondary',
        hoverBoxShadow: 'button.secondaryHover',
        activeBoxShadow: 'none',
    },
    ghostReversed: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'whiteA6',
        borderHoverColor: 'whiteA8',
        color: 'background',
        hoverBg: 'whiteA2',
        boxShadow: 'none',
        hoverBoxShadow: 'none',
        activeBoxShadow: 'none',
    },
    ghostDanger: {
        bg: 'transparent',
        boxShadow: 'button.ghost',
        color: 'slate',
        hoverBoxShadow: 'button.dangerHover',
        hoverColor: 'white',
        hoverFill: 'white',
        hoverBg: 'redDark',
    },
    primary: {
        bg: 'primary',
        border: '1px solid',
        borderBottomColor: 'primaryHighlight',
        borderLeftColor: 'primary',
        borderRightColor: 'primary',
        borderTopColor: 'primary',
        boxShadow: 'none',
        color: 'whiteA12',
        hoverBg: 'primaryHighlight',
    },
    amber: {
        bg: 'amber5',
        color: 'amber12',
        boxShadow: 'none',
        hoverBg: 'amber6',
    },
    attention: {
        bg: 'amber4',
        border: '1px solid',
        borderColor: 'amber6',
        color: 'amber12',
        hoverBg: 'amber5',
        boxShadow: 'button.secondary',
        hoverBoxShadow: 'button.secondaryHover',
        activeBoxShadow: 'none',
    },
    disabled: {
        bg: 'slate2',
        border: '1px solid',
        borderColor: 'slate6',
        color: 'slate10',
        hoverBg: 'slate2',
        boxShadow: 'button.secondary',
        hoverBoxShadow: 'none',
        activeBoxShadow: 'none',
    },
    transparent: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'transparent',
        boxShadow: 'none',
        color: 'slate12',
        hoverBg: 'slate3',
    },
    light: {
        bg: 'slate4',
        border: '1px solid',
        borderColor: 'transparent',
        boxShadow: 'none',
        color: 'slate12',
        hoverBg: 'slate7',
    },
    black: {
        bg: 'blackA10',
        border: '1px solid',
        borderBottomColor: 'blackA11',
        borderLeftColor: 'blackA10',
        borderRightColor: 'blackA10',
        borderTopColor: 'blackA10',
        boxShadow: 'none',
        color: 'whiteA12',
        hoverBg: 'blackA11',
    },
    white: {
        bg: 'whiteA10',
        hoverBg: 'whiteA11',
        color: 'blackA12',
        boxShadow: 'borders.black',
    },
    lightRed: {
        bg: 'red6',
        boxShadow: 'none',
        color: 'red11',
        hoverBg: 'red7',
    },
    negative: {
        bg: 'tomato9',
        border: '1px solid',
        borderBottomColor: 'tomato10',
        borderLeftColor: 'tomato9',
        borderRightColor: 'tomato9',
        borderTopColor: 'tomato9',
        boxShadow: 'none',
        color: 'background',
        hoverBg: 'tomato10',
    },
    positive: {
        bg: 'grass9',
        border: '1px solid',
        borderColor: 'grass10',
        boxShadow: 'none',
        color: 'background',
        hoverBg: 'grass10',
    },
};
export const activeKinds = {
    blue8: {
        bg: 'blue8',
        border: 'none',
        color: 'background',
        hoverBg: 'blue10',
    },
    skyBlue: {
        bg: 'sky4',
        color: 'sky11',
        hoverBg: 'sky5',
        boxShadow: 'none',
        hoverBoxShadow: 'none',
        activeBoxShadow: 'none',
    },
    ghost: {
        bg: 'blue3',
        border: '1px solid',
        borderColor: 'blue6',
        color: 'blue12',
        hoverBg: 'blue4',
        boxShadow: 'button.secondary',
        hoverBoxShadow: 'button.secondaryHover',
        activeBoxShadow: 'none',
    },
    ghostReversed: {
        bg: 'blue3',
        border: '1px solid',
        borderColor: 'blue6',
        color: 'blue12',
        hoverBg: 'blue4',
        boxShadow: 'button.secondary',
        hoverBoxShadow: 'button.secondaryHover',
        activeBoxShadow: 'none',
    },
    ghostDanger: {
        bg: 'transparent',
        boxShadow: 'button.ghost',
        color: 'slate',
        hoverBoxShadow: 'button.dangerHover',
        hoverColor: 'white',
        hoverFill: 'white',
        hoverBg: 'redDark',
    },
    amber: {
        bg: 'amber6',
        color: 'amber12',
        boxShadow: 'none',
        hoverBg: 'amber7',
    },
    attention: {
        bg: 'amber5',
        border: '1px solid',
        borderColor: 'amber7',
        color: 'amber12',
        hoverBg: 'amber7',
        boxShadow: 'button.secondary',
        hoverBoxShadow: 'button.secondaryHover',
        activeBoxShadow: 'none',
    },
    primary: {
        bg: 'primary',
        color: 'whiteA12',
        border: '1px solid',
        borderTopColor: 'primary',
        borderRightColor: 'primary',
        borderBottomColor: 'primaryHighlight',
        borderLeftColor: 'primary',
    },
    disabled: {
        bg: 'slate2',
        border: '1px solid',
        borderColor: 'slate6',
        color: 'slate10',
        hoverBg: 'slate2',
        boxShadow: 'button.secondary',
        hoverBoxShadow: 'none',
        activeBoxShadow: 'none',
    },
    transparent: {
        bg: 'primary',
        border: '1px solid',
        borderColor: 'primary',
        color: 'whiteA12',
        hoverBg: 'primaryHighlight',
    },
    light: {
        bg: 'primary',
        border: '1px solid',
        borderColor: 'primary',
        color: 'whiteA12',
        hoverBg: 'primaryHighlight',
    },
    black: {
        bg: 'blue9',
        hoverBg: 'blue10',
        color: 'whiteA12',
        border: '1px solid',
        borderColor: 'blue10',
    },
    white: {
        bg: 'blue4',
        color: 'blue12',
        hoverBg: 'blue5',
    },
    lightRed: {
        bg: 'red6',
        boxShadow: 'none',
        color: 'red11',
        hoverBg: 'red7',
    },
    negative: {
        bg: 'tomato10',
        hoverBg: 'tomato11',
        color: 'background',
        border: '1px solid',
        borderTopColor: 'tomato10',
        borderRightColor: 'tomato10',
        borderBottomColor: 'tomato11',
        borderLeftColor: 'tomato10',
    },
    positive: {
        bg: 'grass10',
        hoverBg: 'grass11',
        color: 'background',
        border: '1px solid',
        borderTopColor: 'grass10',
        borderRightColor: 'grass10',
        borderBottomColor: 'grass11',
        borderLeftColor: 'grass10',
    },
};
export const sizes = {
    xxs: {
        borderRadius: 'xs',
        fontSize: 'xxs',
        fontWeight: 'medium',
        height: '18px',
        lineHeight: '16px',
        minWidth: '18px',
        paddingX: 'xxs',
    },
    xs: {
        borderRadius: 's',
        fontSize: 'xs',
        fontWeight: 'medium',
        height: '24px',
        lineHeight: '22px',
        minWidth: '24px',
        paddingX: 'xs',
    },
    s: {
        borderRadius: 'm',
        fontSize: 'xs',
        fontWeight: 'medium',
        height: '30px',
        lineHeight: '28px',
        minWidth: '30px',
    },
    m: {
        borderRadius: 'm',
        fontSize: 's',
        fontWeight: 'medium',
        height: '36px',
        lineHeight: '34px',
        minWidth: '36px',
    },
    l: {
        borderRadius: 'm',
        fontSize: 's',
        fontWeight: 'medium',
        height: '40px',
        lineHeight: '38px',
        minWidth: '40px',
    },
};
const getButtonKind = ({ isActive, isDisabled, isLoading, kind, }) => {
    if (isDisabled || isLoading) {
        return kinds.disabled;
    }
    if (isActive) {
        return activeKinds[kind];
    }
    return kinds[kind];
};
export const Button = forwardRef(function Button(_a, ref) {
    var { children, isActive, isDisabled, isLoading, isSquare, kind = 'primary', size = 'm', testId } = _a, rest = __rest(_a, ["children", "isActive", "isDisabled", "isLoading", "isSquare", "kind", "size", "testId"]);
    const width = isSquare && sizes[size].minWidth;
    const kindProps = getButtonKind({
        isDisabled,
        isActive,
        kind,
        isLoading,
    });
    return (React.createElement(MarvelButton, Object.assign({ isDisabled: isDisabled, isLoading: isLoading, width: width, testId: testId }, kindProps, sizes[size], rest, { ref: ref }), children));
});
