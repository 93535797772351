import React, { Fragment, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Webinar } from '@marvelapp/askhub-upsells';
import { usePermissions } from '@marvelapp/ballpark-application';
import { Link, Stack } from '@marvelapp/ballpark-components';
import { HELP, ROADMAP } from '@marvelapp/ballpark-help';
import { useOnClickOutside } from '@marvelapp/hooks';
import { TrialCountdown } from './TrialCountdown';
import { UpgradeButton } from './UpgradeButton';
import { getMainLinks } from './links';
export const NavLinks = function NavLinks() {
    const { pathname } = useLocation();
    const permissions = usePermissions();
    const mainLinks = getMainLinks({ permissions });
    const mainLinksCanView = mainLinks.filter((link) => link.canView);
    const ref = useRef();
    const [openSubmenu, setOpenSubmenu] = useState(null);
    useOnClickOutside(ref, () => {
        setOpenSubmenu(null);
    });
    return (React.createElement(Stack, { width: "full", gap: "6", align: "stretch" },
        React.createElement(TrialCountdown, null),
        React.createElement(UpgradeButton, null),
        React.createElement(Stack, { gap: "2", align: "stretch" }, mainLinksCanView.map((link) => (React.createElement(Fragment, { key: link.name },
            React.createElement(NavLink, { active: openSubmenu === null && pathname.includes(link.to), to: link.to }, link.name))))),
        React.createElement(Stack, { gap: "2", align: "stretch", className: "mt-2" },
            React.createElement(NavLink, { key: "help", to: HELP }, "Help"),
            React.createElement(IntercomLauncher, null),
            React.createElement(NavLink, { key: "roadmap", to: ROADMAP }, "Roadmap")),
        React.createElement(Webinar, null)));
};
function NavLink({ active, children, to }) {
    return (React.createElement(Link, { kind: "licorice", bg: active && 'slate4', borderRadius: 2, fontSize: 14, fontWeight: active ? 500 : 400, height: "32px", hoverBg: active ? 'slate5' : 'slate4', lineHeight: "32px", to: to, px: "s" }, children));
}
function IntercomLauncher() {
    return (React.createElement(Link, { kind: "licorice", borderRadius: 2, fontSize: 14, fontWeight: 400, height: "32px", hoverBg: 'slate4', lineHeight: "32px", px: "s", id: "intercom-launcher", textAlign: "left" }, "Contact us"));
}
