import { __rest } from "tslib";
import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { tv } from 'tailwind-variants';
import { ArrowTopRightIcon } from '@marvelapp/ballpark-icons';
import { textBaseVariants } from './BtwText';
const link = tv({
    extend: textBaseVariants,
    base: [
        'duration-300',
        'ease-smooth',
        'cursor-pointer',
        'transition-all',
        'will-change-transform',
        'focus-visible:rounded-sm',
        'focus-visible:outline',
        'focus-visible:outline-2',
        'focus-visible:outline-offset-4',
    ],
    variants: {
        variant: {
            primary: [
                'text-blue-700',
                'hover:text-blue-900',
                'focus-visible:outline-gray-900/10',
            ],
            secondary: [
                'text-gray-900',
                'hover:text-gray-500',
                'focus-visible:outline-gray-900/10',
            ],
            muted: [
                'text-gray-500',
                'hover:text-gray-900',
                'focus-visible:outline-gray-900/10',
            ],
            current: [
                'text-current',
                'hover:opacity-70',
                'focus-visible:outline-gray-900/10',
            ],
        },
        underline: {
            // Needs to be important because of legacy global styles targetting the anchor tag directly
            true: ['!underline', '!underline-offset-[3px]'],
            false: [],
        },
    },
    defaultVariants: {
        variant: 'primary',
        weight: 'medium',
        underline: true,
    },
}, {
    responsiveVariants: true,
    twMerge: false, // https://github.com/nextui-org/tailwind-variants/issues/18
});
function BtwLinkBase(props) {
    const { asChild, children, className, size, underline, variant, weight } = props, rest = __rest(props, ["asChild", "children", "className", "size", "underline", "variant", "weight"]);
    const Comp = asChild ? Slot : 'a';
    return (React.createElement(Comp, Object.assign({ className: link({ className, size, variant, weight, underline }) }, rest), children));
}
function External(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(BtwLinkBase, Object.assign({ className: "[&>svg]:ml-0.5 [&>svg]:inline-block [&>svg]:h-3.5 [&>svg]:w-3.5", target: "_blank", rel: "noopener noreferrer" }, rest),
        children,
        React.createElement(ArrowTopRightIcon, null)));
}
const BtwLink = Object.assign(BtwLinkBase, {
    External,
});
export { BtwLink };
