import { useResponsiveValue } from '@theme-ui/match-media';
import { range } from 'lodash-es';
import React from 'react';
import { Box, ContentLoader, Flex, Grid, Text, Tooltip } from '@marvelapp/ui';
import { ModalLink } from '../Modal';
import { Player } from './Player';
export function CollaboratorsView({ activeCollaborators, isLoading, }) {
    const MAX_LENGTH = useResponsiveValue([5, 5, 5, 5, 3, 3, 5]);
    const moreThanMax = activeCollaborators.length > MAX_LENGTH;
    const remainingCollaborators = moreThanMax
        ? activeCollaborators.length - MAX_LENGTH
        : 0;
    if (isLoading) {
        return (React.createElement(Flex, { "data-testid": "collaborators-loading", marginRight: "10px" }, range(MAX_LENGTH).map((loader, index) => (React.createElement(Box, { key: loader, mr: "-6px", position: "relative", zIndex: range(4).length - index },
            React.createElement(ContentLoader, { border: "3px solid", borderColor: "white", borderRadius: 18, height: 34, key: loader, kind: "medium", width: 34 }))))));
    }
    return (React.createElement(ModalLink, { display: ['none', 'block'], mr: 2, to: { search: '?share=1' }, "data-testid": "open-share-modal" },
        React.createElement(Grid, { alignItems: "center", gridAutoFlow: "column", gridColumnGap: 1, justifyContent: "center" },
            activeCollaborators
                .slice(0, MAX_LENGTH)
                .map(({ avatarUrl, name, id, color }, index) => (React.createElement(Box, { key: id, mr: "-10px", position: "relative", zIndex: MAX_LENGTH - index + 1 },
                React.createElement(Player, { activeColor: color, avatarUrl: avatarUrl, avatarSize: 34, key: id, name: name })))),
            moreThanMax && (React.createElement(Box, { mr: "-6px", position: "relative", zIndex: 0 },
                React.createElement(Tooltip, { content: `And ${remainingCollaborators} more`, cursor: "pointer", position: "bottom" },
                    React.createElement(MoreCollaborators, { num: remainingCollaborators })))))));
}
export function MoreCollaborators({ num, }) {
    return (React.createElement(Box, { alignItems: "center", bg: "smoke", borderRadius: "50%", "data-testid": "extra-collaborators", display: "flex", height: 34, justifyContent: "center", position: "relative", pr: "2px", width: 34 },
        React.createElement(Text, { color: "slate", fontSize: 34 / 3, fontWeight: 600, opacity: 0.7 },
            "+",
            num)));
}
