import React from 'react';
import { useFeatureFlag } from '@marvelapp/ballpark-application';
import { BaseError } from './ErrorPages';
import { Link } from './Link';
export function Maintenance({ testId = 'maintenance', flag, children, }) {
    const isMaintenance = useFeatureFlag(flag);
    if (!isMaintenance)
        return React.createElement(React.Fragment, null, children);
    return (React.createElement(BaseError, { heading: "Scheduled Maintenance", subheading: React.createElement(React.Fragment, null,
            "We're currently performing scheduled maintenance on this part of Ballpark. Visit our",
            ' ',
            React.createElement(Link, { to: "https://status.ballparkhq.com/" }, "status page"),
            " for updates."), testId: testId }));
}
