import { __rest } from "tslib";
import * as ProgressPrimitive from '@radix-ui/react-progress';
import React, { forwardRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { tv } from 'tailwind-variants';
import { cn } from './utils';
const progress = tv({
    base: [
        'w-full',
        'relative',
        'bg-gray-100',
        'overflow-hidden',
        // Fix overflow clipping in Safari
        // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
        'z-0',
    ],
    variants: {
        variant: {
            gray: ['[&>div]:bg-gray-300'],
            green: ['[&>div]:bg-green-500'],
            legacyBlue: ['[&>div]:bg-legacyBlue'],
            primary: ['[&>div]:bg-gray-900'],
            red: ['[&>div]:bg-red-500'],
            yellow: ['[&>div]:bg-yellow-500'],
            notSupported: [
                '[&>div]:bg-gray-50 bg-gray-50 border border-dashed border-gray-600/20',
            ],
        },
        size: {
            sm: ['h-1.5'],
            base: ['h-2'],
            md: ['h-3'],
            lg: ['h-5'],
        },
        rounded: {
            full: ['rounded-full', '[&>div]:rounded-full'],
            md: ['rounded-md', '[&>div]:rounded-md'],
        },
    },
    defaultVariants: {
        rounded: 'full',
        size: 'base',
        variant: 'primary',
    },
});
const ProgressBase = forwardRef(function ProgressBase(props, ref) {
    const { className, size, value, variant, rounded } = props, rest = __rest(props, ["className", "size", "value", "variant", "rounded"]);
    return (React.createElement(ProgressPrimitive.Root, Object.assign({ className: progress({ className, size, variant, rounded }), value: value, ref: ref }, rest),
        React.createElement(ProgressPrimitive.Indicator, { className: cn('w-full', 'h-full', 
            // Transition
            'duration-1000', 'ease-smooth', 'transition-combined', 'will-change-transform'), style: { transform: `translateX(-${100 - value}%)` } })));
});
function AnimatedProgress(props) {
    const { value, delay = 500 } = props, rest = __rest(props, ["value", "delay"]);
    const [animated, setAnimated] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setAnimated(true);
        }, delay);
    }, [delay]);
    return React.createElement(ProgressBase, Object.assign({}, rest, { value: animated ? value : 0 }));
}
function AnimatedInViewProgress(props) {
    const { value } = props, rest = __rest(props, ["value"]);
    const { ref, inView } = useInView({
        triggerOnce: true,
    });
    return React.createElement(ProgressBase, Object.assign({}, rest, { ref: ref, value: inView ? value : 0 }));
}
const Progress = Object.assign(ProgressBase, {
    Animated: AnimatedProgress,
    AnimatedInView: AnimatedInViewProgress,
});
export { Progress };
