import { __rest } from "tslib";
import React from 'react';
import { Link } from './Link';
export function NavLink(_a) {
    var { active, kind = 'licorice', children, isSublink } = _a, rest = __rest(_a, ["active", "kind", "children", "isSublink"]);
    return (React.createElement(Link, Object.assign({ bg: active && 'slate4', borderRadius: 2, color: active ? 'slate12' : 'slate11', display: "block", fontSize: isSublink ? 13 : 14, fontWeight: active ? 500 : 400, height: "32px", hoverBg: active ? 'slate5' : 'slate4', hoverColor: "slate12", kind: kind, lineHeight: "32px", marginBottom: 2, pl: isSublink ? 26 : 3, pr: isSublink ? 26 : 3, pt: "1px" }, rest), children));
}
export function PopoverLink(_a) {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (React.createElement(NavLink, Object.assign({ borderRadius: "0px", fontSize: "s", marginBottom: "0px", whiteSpace: "nowrap" }, rest), children));
}
