// TODO: Unify input, button and select sizes
export const inputSizes = [
    {
        fontSize: 1,
        borderRadius: 0,
        height: 26,
        lineHeight: '26px',
        pl: 2,
        pr: 2,
    },
    {
        fontSize: 2,
        borderRadius: 1,
        height: 30,
        lineHeight: '30px',
        pl: 3,
        pr: 3,
    },
    {
        fontSize: 2,
        borderRadius: 1,
        height: 36,
        lineHeight: '36px',
        pl: 12,
        pr: 12,
    },
    {
        fontSize: 2,
        borderRadius: 1,
        height: 44,
        lineHeight: '44px',
        pl: 3,
        pr: 3,
    },
];
export const loaderSizes = [
    {
        size: 12,
        borderWidth: 2,
    },
    {
        size: 16,
        borderWidth: 2,
    },
    {
        size: 20,
        borderWidth: 4,
    },
    {
        size: 24,
        borderWidth: 4,
    },
];
