import { __rest } from "tslib";
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import React from 'react';
import { tv } from 'tailwind-variants';
import { basePopoverVariants } from './BtwPopover';
const hoverCard = tv({
    extend: basePopoverVariants,
    base: [
        // Max available screen height depending on the position of the hoverCard
        'max-h-[var(--radix-hover-card-content-available-height)]',
    ],
    variants: {
        size: {
            base: [],
            md: ['p-6', 'gap-5'],
        },
        width: {
            auto: ['min-w-[var(--radix-hoverCard-trigger-width)]'],
            trigger: ['w-[var(--radix-popover-trigger-width)]'],
        },
    },
    defaultVariants: {
        size: 'base',
        width: 'auto',
    },
});
function Content(props) {
    const { className, size, width, hasPortal = true } = props, rest = __rest(props, ["className", "size", "width", "hasPortal"]);
    return (React.createElement(WithPortal, { hasPortal: hasPortal },
        React.createElement(HoverCardPrimitive.Content, Object.assign({ sideOffset: 8, collisionPadding: { top: 70, bottom: 20 }, className: hoverCard({ className, size, width }) }, rest))));
}
function WithPortal(props) {
    const { children, hasPortal } = props;
    if (hasPortal) {
        return React.createElement(HoverCardPrimitive.Portal, null, children);
    }
    return React.createElement(React.Fragment, null, children);
}
const HoverCard = {
    Content,
    Root: HoverCardPrimitive.Root,
    Trigger: HoverCardPrimitive.Trigger,
};
export { HoverCard };
