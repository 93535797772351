import { __awaiter } from "tslib";
/* GLOBALS __DEV__ */
import { gql, useQuery } from '@apollo/client';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { flowRight, get } from 'lodash-es';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { PermissionsProvider, getIsUnsafeProductionBackend, } from '@marvelapp/ballpark-application';
import { UserRole, camelCase, fetchApi, heapTrackEvent, isDebugMode, } from '@marvelapp/core';
import { useLogRocket } from '@marvelapp/hooks';
import { Box } from '@marvelapp/ui';
import { FullScreenLoader } from '@marvelapp/ui-internal';
import config from '../config';
import applyUserOverrides from '../utils/applyUserOverrides';
import { LaunchDarklyProvider, getFlags, getLaunchDarklyClient, } from '../utils/launchDarkly';
import logout from '../utils/logout';
import { connectPusher } from '../utils/pusher';
import App from './App';
let DevToolbar;
if (isDebugMode()) {
    // This makes sure it's not included in the bundle in a production deploy
    DevToolbar = React.lazy(() => import(/* webpackChunkName: "DevToolbar" */ './DevToolbar'));
}
const USER_QUERY = gql `
  query getUser {
    user {
      pk
      uuid
      intercomUserHash
      avatarUrl
      username
      createdAt
      email
      firstName
      lastName
      occupation
      occupationDisplayName
      emailAddressType
      lastActiveAt
      image: avatarUrl
      isDetached
      isSketch
      testGroup
      tool
      signedToken {
        token
        marvelUserId
      }
      properties {
        prototypeProjectsOwnedCount
        userTestProjectsOwnedCount
        screensOwnedCount
        hotspotsOwnedCount
        teamsOwnedCount
      }
      company {
        pk
        createdAt
        name
        iconUrl
        prototypeProjectCount
        userTestProjectCount
        testResultsCount
        memberCount
        guestCount
        accountLockOverride
        owner {
          pk
          email
          username
        }
        billing {
          subscriptionPk
          projectQuantityPurchased
          billingCycle
          customerId
          nextPaymentDate
          subscriptionState
          trialDaysRemaining
          plan {
            label
          }
          accountId
          currency
        }
        settings {
          visibilityLock
          editorsCanInvite
        }
        flags {
          unrestrictedProjectTest
        }
      }
      role
    }
  }
`;
const GET_ADMIN_BILLING_DATA = gql `
  query getBilling {
    user {
      pk
      company {
        pk
        billing {
          subscriptionPk
          payment
        }
      }
    }
  }
`;
function AppContainer({ accessToken, client, data: { loading: isLoading = false, user = {
    uuid: '',
    intercomUserHash: '',
    pk: null,
    username: null,
    email: null,
    role: 'ANONYMOUS',
}, } = {}, }) {
    const [ldClient, setLdClient] = useState(null);
    const pusher = useRef(null);
    const [transactionalKey, setTransactionalKey] = useState('');
    const [isFetchingSubscription, setIsFetchingSubscription] = useState(false);
    const [subscription, _setSubscription] = useState(null);
    const setSubscription = (sub_1, ...args_1) => __awaiter(this, [sub_1, ...args_1], void 0, function* (sub, { resetStore = true } = {}) {
        _setSubscription(camelCase(sub));
        if (resetStore) {
            yield client.resetStore();
        }
    });
    useEffect(() => {
        if (accessToken) {
            setIsFetchingSubscription(true);
            fetchApi('/api/user/', { accessToken })
                .then((response) => response.json())
                .then(({ transaction_key: transKey, subscription: newSubscription }) => {
                setSubscription(newSubscription, { resetStore: false });
                setTransactionalKey(transKey);
            })
                .finally(() => setIsFetchingSubscription(false));
        }
        else {
            setIsFetchingSubscription(false);
        }
    }, [accessToken]);
    useEffect(() => {
        if (accessToken) {
            // initialize and authenticate pusher
            pusher.current = connectPusher({
                apiKey: config.pusher.apiKeyEU,
                accessToken,
            });
        }
    }, [accessToken]);
    const { data: adminBillingData, loading: isAdminBillingDataLoading } = useQuery(GET_ADMIN_BILLING_DATA, {
        skip: ![UserRole.Owner, UserRole.Admin].includes(user.role),
    });
    const userObj = applyUserOverrides(user, adminBillingData);
    const { company, email, pk, role, occupationDisplayName, testGroup, isSketch, tool, uuid, intercomUserHash, } = userObj;
    const enableForAll = config.usertest.enableBallparkFeatureFlagForAll;
    const history = useHistory();
    useEffect(() => {
        // wait for first load otherwise user might not be able to access the feature/page
        if (!isLoading) {
            getLaunchDarklyClient({
                company,
                email,
                pk,
                role,
                history,
            }, enableForAll).then(setLdClient);
        }
    }, [isLoading, company, email, pk, role, testGroup, enableForAll]);
    useEffect(() => {
        if (pk && window.heap) {
            window.heap.identify(pk);
            window.heap.addUserProperties({
                role,
                plan_label: get(company, 'billing.plan.label'),
                company_id: get(company, 'pk'),
                company_name: get(company, 'name'),
                occupation: occupationDisplayName,
                is_sketch: isSketch,
                design_tool: tool,
            });
        }
        if (get(company, 'flags.unrestrictedProjectTest')) {
            heapTrackEvent('UserOnFreeTrial');
        }
    }, [pk, company, role]);
    useEffect(() => {
        if (uuid && window.Intercom) {
            window.Intercom('boot', {
                app_id: window.INTERCOM_APP_ID,
                user_id: uuid,
                user_hash: intercomUserHash,
                hide_default_launcher: true,
                custom_launcher_selector: '#intercom-launcher',
            });
        }
    }, [intercomUserHash, uuid]);
    useLogRocket({ user: userObj, alwaysShow: true, ignoreMarvel: false });
    // wait for initial load and flags to be ready otherwise user will get routed to sign in if this is a authenticated route
    // We are also waiting for isAdminBillingDataLoading to ensure the user object is completely ready before rendering the app
    if (isLoading ||
        isFetchingSubscription ||
        isAdminBillingDataLoading ||
        !ldClient)
        return React.createElement(FullScreenLoader, { bg: "white" });
    const flags = getFlags(ldClient);
    return (React.createElement(PermissionsProvider, { user: userObj, flags: flags },
        React.createElement(LaunchDarklyProvider, { client: ldClient },
            React.createElement(React.Fragment, null,
                React.createElement(Helmet, { defaultTitle: "Ballpark", titleTemplate: "%s | Ballpark" }),
                React.createElement(ProductionBackendWarning, null),
                React.createElement(App, { accessToken: accessToken, config: config, flags: flags, logout: logout, pusher: pusher.current, setSubscription: setSubscription, subscription: subscription, transactionalKey: transactionalKey, user: userObj }),
                DevToolbar && (React.createElement(Suspense, { fallback: null },
                    React.createElement(DevToolbar, { user: user })))))));
}
export default flowRight(graphql(USER_QUERY, {
    skip: (props) => !props.accessToken,
}), withApollo)(AppContainer);
function ProductionBackendWarning() {
    const isUnsafeProductionBackend = getIsUnsafeProductionBackend(config);
    return (isUnsafeProductionBackend && (React.createElement(Box, { position: "sticky", top: 0, display: "flex", justifyContent: "center", alignItems: "center", bg: "red", color: "white", px: 2, height: 50, fontWeight: "800", zIndex: 99999 },
        React.createElement("span", { role: "img", "aria-label": "" }, "\u26A0\uFE0F"),
        ' ',
        "DANGER: You are using the production backend!")));
}
