import * as TogglePrimitive from '@radix-ui/react-toggle';
import React from 'react';
import { tv } from 'tailwind-variants';
const circle = tv({
    base: [
        // Transition
        'duration-300',
        'ease-smooth',
        'transition-combined',
        'will-change-transform',
        'bg-white',
        'shadow-sm',
        'rounded-full',
    ],
});
const root = tv({
    base: [
        'py-0',
        'cursor-pointer',
        'appearance-none',
        'rounded-full',
        'box-border',
        'duration-300',
        'ease-smooth',
        'transition-combined',
        'disabled:cursor-not-allowed',
        'border-none',
        'outline-none',
        // Focus state
        'focus-visible:outline-[3px]',
        'focus-visible:outline-gray-200',
        // Off
        'data-[state=off]:bg-gray-300/85',
        'data-[state=off]:hover:bg-gray-300',
        '[&>div]:active:scale-x-110',
        '[&>div]:data-[state=off]:origin-left',
        // On
        '[&>div]:data-[state=on]:active:scale-x-110',
        '[&>div]:data-[state=on]:active:translate-x-full',
        '[&>div]:data-[state=on]:origin-right',
        '[&>div]:data-[state=on]:translate-x-full',
        // Disabled
        'data-[state=off]:disabled:bg-gray-300/25',
        'data-[state=off]:disabled:hover:bg-gray-300/25',
    ],
    variants: {
        variant: {
            primary: [
                'data-[state=on]:bg-gray-900',
                'data-[state=on]:hover:bg-gray-950',
                'data-[state=on]:disabled:bg-gray-300/25',
                'data-[state=on]:disabled:hover:bg-gray-300/25',
            ],
            legacyBlue: [
                'data-[state=on]:bg-legacyBlue',
                'data-[state=on]:hover:bg-legacyBlueHover',
                'data-[state=on]:disabled:bg-legacyBlue/30',
                'data-[state=on]:disabled:hover:bg-legacyBlue/30',
            ],
        },
        size: {
            base: [],
            sm: ['px-[3px]', 'h-5', 'w-[34px]', '[&>div]:w-3.5', '[&>div]:h-3.5'],
            md: ['px-1', 'h-6', 'w-10', '[&>div]:w-4', '[&>div]:h-4'],
        },
        width: {},
    },
    defaultVariants: {
        size: 'md',
        variant: 'primary',
    },
});
function BtwToggle(props) {
    const { className, variant, size } = props;
    return (React.createElement(TogglePrimitive.Root, Object.assign({ className: root({ className, variant, size }) }, props),
        React.createElement("div", { className: circle() })));
}
export { BtwToggle };
