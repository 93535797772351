import React from 'react';
import { canBeShownAgain } from '../storage';
function getCurrrentUpsellForPosition({ position, upsells, closed, }) {
    const upsell = upsells.find((item) => {
        const { condition, identifier, isClosable } = item;
        // None closable upsells should always be shown as they are important
        if (!isClosable) {
            return condition;
        }
        // Ensures that in the position it only shows 1 banner every 24h
        const canShowAgain = canBeShownAgain({
            identifier,
            closed,
            position,
        });
        return condition && canShowAgain;
    });
    return upsell;
}
export default function UpsellRenderer({ upsells, closed, position, onClickClose, userInfo, }) {
    if (!upsells)
        return null;
    const upsell = getCurrrentUpsellForPosition({
        position,
        upsells,
        closed,
    });
    if (!upsell)
        return null;
    // Found a upsell matching conditions to show
    const { identifier, daysUntilReshownAfterClose } = upsell;
    const clickedClose = () => {
        onClickClose({ identifier, position, daysUntilReshownAfterClose });
    };
    const UpsellComponent = upsell.component;
    return (React.createElement(UpsellComponent, { "data-testid": identifier, identifier: identifier, onClickClose: upsell.isClosable ? clickedClose : null, userInfo: userInfo }));
}
