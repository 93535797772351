import themeGet from '@styled-system/theme-get';
import { typography } from 'styled-system';
import styled from '@marvelapp/styled';
import { inputStyles } from '@marvelapp/ui';
export const StyledTelInput = styled.div `
  ${typography};

  .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    color: ${themeGet('colors.slate10')};
    padding-left: 5px;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 6px 0 0 6px;
    background-color: ${themeGet('colors.background')};
  }

  &:hover .selected-flag {
    border-right: 1px solid ${themeGet('colors.slate6')};
  }

  .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
    border-radius: 6px 0 0 6px;
    background-color: ${themeGet('colors.snow')};
  }

  .intl-tel-input,
  .form-control {
    width: 100%;
  }

  .form-control {
    border: none;
    ${inputStyles}
  }

  .selected-flag {
    outline: none;
    border-right: 1px solid ${themeGet('colors.smoke')};
    transition: all 400ms ${themeGet('easings.smooth.string')};
  }

  .intl-tel-input.allow-dropdown .selected-flag {
    width: 62px;
    padding-left: 12px;
  }

  .intl-tel-input .arrow {
    width: 8px;
    height: 4px;
    border: none;
    transition: all 400ms ${themeGet('easings.smooth.string')};

    &::after {
      display: none;
    }
  }

  .intl-tel-input .flag-container .arrow {
    background-image: url('https://m3-static.marvelapp.com/assets/51810207a66389cc2e6ea10545caf5b7.svg');
    background-size: 8px 4px;
    background-repeat: no-repeat;
    margin: 0 5px;
  }

  .intl-tel-input .selected-flag .arrow.up {
    transform: rotate(180deg);
    border: none;
  }

  .iti-flag {
    box-shadow: none;
  }

  .country-list {
    width: 100%;
    border: none;
    border-radius: 4px;
    margin: 10px 0 0;
    padding-top: 10px;
    top: auto !important;
    box-shadow: ${themeGet('shadows.popover.raised')};
  }

  .intl-tel-input .country-list .country {
    padding: 0 16px;
  }

  .intl-tel-input .country-list .divider {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${themeGet('colors.smoke')};
  }

  .intl-tel-input .country-list .country-name {
    color: ${themeGet('colors.black')};
  }

  .intl-tel-input .country-list .country .dial-code {
    color: ${themeGet('colors.silver')};
  }

  .intl-tel-input .country-list .country.highlight {
    background: ${themeGet('colors.snowDark')};
  }

  .intl-tel-input .country-list .flag-box {
    margin-right: 10px;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input,
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 input[type='tel'],
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2
    input[type='text'] {
    padding-left: 94px;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input,
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type='tel'],
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3
    input[type='text'] {
    padding-left: 102px;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input,
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input[type='tel'],
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4
    input[type='text'] {
    padding-left: 110px;
  }

  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input,
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 input[type='tel'],
  .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5
    input[type='text'] {
    padding-left: 118px;
  }
`;
