import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { layout } from 'styled-system';
import theme from '@marvelapp/ballpark-theme';
import styled from '@marvelapp/styled';
import Absolute from './Absolute';
import Box from './Box';
import CloseButton from './CloseButton';
import EscKeyHandler from './EscKeyHandler';
import ModalOverlay from './ModalOverlay';
import ModalPortal from './ModalPortal';
import Text from './Text';
/** @param {any} */
export default function ModalDialog({ children, isOpen, maxWidth = 440, size = 1, title = null, trigger, width, }) {
    const headerSizes = [{ padding: 3 }, { padding: 4 }];
    const headerTitleSizes = [{ fontSize: 3 }, { fontSize: 3 }];
    const closeButtonSizes = [
        { top: 15, right: 15 },
        { top: 21, right: 21 },
    ];
    const animation = {
        animate: {
            opacity: 1,
            x: '-50%',
            y: '-50%',
        },
        exit: {
            opacity: 0,
            x: '-50%',
            y: '-35%',
        },
        initial: {
            opacity: 0,
            x: '-50%',
            y: '-35%',
        },
        transition: {
            ease: theme.easings.smooth.array,
            duration: 0.6,
        },
    };
    return (React.createElement(ModalPortal, null,
        isOpen && React.createElement(EscKeyHandler, { onEscapeKey: trigger }),
        React.createElement(AnimatePresence, null,
            isOpen && React.createElement(ModalOverlay, { onClick: trigger }),
            isOpen && (React.createElement(Content, { animate: animation.animate, "data-testid": "modal-dialog", exit: animation.exit, initial: animation.initial, key: "modal", maxWidth: maxWidth, transition: animation.transition, width: width },
                title && (React.createElement(Box, Object.assign({ borderBottom: "1px solid", borderColor: "smoke" }, headerSizes[size]),
                    React.createElement(Text, Object.assign({ color: "licorice", fontWeight: 500, marginTop: "1px", textAlign: "center" }, headerTitleSizes[size]), title))),
                children,
                React.createElement(Absolute, Object.assign({}, closeButtonSizes[size]),
                    React.createElement(CloseButton, { fill: "currentColor", kind: "ghost", onClick: trigger, padding: 0, size: size })))))));
}
export const Content = styled(motion.div) `
  ${layout};

  pointer-events: all;
  border-radius: ${(props) => props.theme.radii[3]}px;
  box-shadow: ${(props) => props.theme.shadows.modal};
  background-color: ${(props) => props.theme.colors.white};

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: ${(props) => props.theme.zIndices.modal};
`;
