import { __rest } from "tslib";
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { kinds, sizes } from '../Input';
import { Label, LabelContainer } from '../Label';
import { StyledTelInput } from './TelInput.styles';
export function TelInputWithLabel(_a) {
    var { setIsNumberValid, setNumber, size, kind, error, theme } = _a, rest = __rest(_a, ["setIsNumberValid", "setNumber", "size", "kind", "error", "theme"]);
    const allProps = Object.assign(Object.assign(Object.assign({}, sizes[size]), kinds[kind]), rest);
    return (React.createElement(Box, { mt: 2, width: 1 },
        React.createElement(LabelContainer, { marginBottom: 12 },
            React.createElement(Label, { display: "inline-block", size: "s" }, "Phone Number"),
            error && (React.createElement(Error, { "data-testid": "tel-input-error", fontSize: "s" }, error))),
        React.createElement(StyledTelInput, Object.assign({}, allProps),
            React.createElement(IntlTelInput, { containerClassName: "intl-tel-input", inputClassName: "form-control", onPhoneNumberChange: (validation, value, countryData) => {
                    setNumber(`${countryData.dialCode}${value}`);
                    setIsNumberValid(validation);
                }, separateDialCode: true }))));
}
const Error = styled(Box).attrs({
    lineHeight: 'base',
    color: 'red11',
}) ``;
