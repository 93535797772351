import { __rest } from "tslib";
import React from 'react';
import { FixedStep, PrototypeStepType, QuestionStepType, ScreenerType, StepClass, StepUtils, YesOrNoStepType, } from '@marvelapp/user-test-creator';
import { BannerAdIcon, CardSortingClosedIcon, CardSortingHybridIcon, CardSortingOpenIcon, DateIcon, EmailIcon, ExitIcon, FirstClickIcon, FiveSecondIcon, FreeRoamIcon, InstructionIcon, LegalIcon, MediaSettingsIcon, MultipleChoiceIcon, NumberIcon, PreferenceTestIcon, PrototypeIcon, QuestionIcon, RatingIcon, ScreenerIcon, TaglineIcon, WebsiteIcon, WelcomeIcon, YesOrNoIcon, } from './icons';
const sizes = {
    xs: {
        width: 18,
        height: 18,
        borderRadius: 'xs',
    },
    s: {
        width: 24,
        height: 24,
        borderRadius: 'xs',
    },
    m: {
        width: 32,
        height: 32,
        borderRadius: 's',
    },
    l: {
        width: 42,
        height: 42,
        borderRadius: 'm',
    },
};
export function StepIcon(_a) {
    var { type, size = 'l' } = _a, rest = __rest(_a, ["type", "size"]);
    const props = Object.assign(Object.assign({}, sizes[size]), rest);
    switch (type) {
        case ScreenerType.Screener:
            return React.createElement(ScreenerIcon, { type: type, size: size });
        case StepClass.Instruction:
            return React.createElement(InstructionIcon, Object.assign({}, props));
        case FixedStep.Welcome:
            return React.createElement(WelcomeIcon, Object.assign({}, props));
        case YesOrNoStepType.YesNo:
            return React.createElement(YesOrNoIcon, Object.assign({}, props));
        case StepClass.Question:
        case QuestionStepType.Text:
            return React.createElement(QuestionIcon, Object.assign({}, props));
        case StepClass.MultipleChoice:
            return React.createElement(MultipleChoiceIcon, Object.assign({}, props));
        case StepClass.RatingScale:
            return React.createElement(RatingIcon, Object.assign({}, props));
        case StepClass.PreferenceTest:
            return React.createElement(PreferenceTestIcon, Object.assign({}, props));
        case StepClass.FiveSecondTest:
            return React.createElement(FiveSecondIcon, Object.assign({}, props));
        case PrototypeStepType.FirstClick:
            return React.createElement(FirstClickIcon, Object.assign({}, props));
        case StepClass.WebsiteTask:
            return React.createElement(WebsiteIcon, Object.assign({}, props));
        case StepClass.PrototypeTask:
        case PrototypeStepType.Prototype:
            return React.createElement(PrototypeIcon, Object.assign({}, props));
        case PrototypeStepType.FreeRoam:
            return React.createElement(FreeRoamIcon, Object.assign({}, props));
        case StepClass.BannerAdTest:
            return React.createElement(BannerAdIcon, Object.assign({}, props));
        case StepClass.TaglineCopyTest:
            return React.createElement(TaglineIcon, Object.assign({}, props));
        case YesOrNoStepType.Legal:
            return React.createElement(LegalIcon, Object.assign({}, props));
        case QuestionStepType.Email:
            return React.createElement(EmailIcon, Object.assign({}, props));
        case QuestionStepType.QuestionDate:
            return React.createElement(DateIcon, Object.assign({}, props));
        case QuestionStepType.Numerical:
            return React.createElement(NumberIcon, Object.assign({}, props));
        case FixedStep.Exit:
            return React.createElement(ExitIcon, Object.assign({}, props));
        case StepClass.MediaSettings:
            return React.createElement(MediaSettingsIcon, Object.assign({}, props));
        case StepClass.CardSortingOpen:
            return React.createElement(CardSortingOpenIcon, Object.assign({}, props));
        case StepClass.CardSortingClosed:
            return React.createElement(CardSortingClosedIcon, Object.assign({}, props));
        case StepClass.CardSortingHybrid:
            return React.createElement(CardSortingHybridIcon, Object.assign({}, props));
        default:
            return null;
    }
}
export function getIconType(step, fixedStepType, isScreenerStep = false) {
    if (isScreenerStep) {
        return ScreenerType.Screener;
    }
    const isQuestionStep = StepUtils.isOfType(step, StepClass.Question);
    if (isQuestionStep)
        return ('type' in step && step.type) || QuestionStepType.Text;
    const isPrototypeStep = StepUtils.isOfType(step, StepClass.PrototypeTask);
    if (isPrototypeStep)
        return 'type' in step ? step.type : PrototypeStepType.Prototype;
    const isYesOrNoStep = StepUtils.isOfType(step, StepClass.YesOrNo);
    // handle legacy projects where the type key doesn't exist
    if (isYesOrNoStep)
        return 'type' in step ? step.type : YesOrNoStepType.YesNo;
    if (fixedStepType)
        return fixedStepType;
    return step.className;
}
export const getIconColor = (type) => {
    switch (type) {
        case FixedStep.Welcome:
            return 'purple';
        case PrototypeStepType.FreeRoam:
            return 'violet';
        case YesOrNoStepType.YesNo:
            return 'fuchsia';
        case StepClass.BannerAdTest:
        case QuestionStepType.QuestionDate:
            return 'indigo';
        case QuestionStepType.Text:
            return 'blue';
        case StepClass.MultipleChoice:
            return 'green';
        case YesOrNoStepType.Legal:
        case QuestionStepType.Numerical:
            return 'lime';
        case StepClass.RatingScale:
        case StepClass.MediaSettings:
            return 'amber';
        case StepClass.TaglineCopyTest:
            return 'rose';
        case StepClass.PreferenceTest:
        case PrototypeStepType.Prototype:
        case StepClass.FiveSecondTest:
            return 'pink';
        case PrototypeStepType.FirstClick:
        case QuestionStepType.Email:
            return 'sky';
        case StepClass.WebsiteTask:
            return 'orange';
        case FixedStep.Exit:
        case StepClass.Instruction:
            return 'yellow';
        case StepClass.CardSortingOpen:
            return 'violet';
        case StepClass.CardSortingClosed:
            return 'blue';
        case StepClass.CardSortingHybrid:
            return 'amber';
        default:
            return 'sky';
    }
};
