import { slateA } from '@radix-ui/colors';
import { blackA, colors, whiteA } from './colors';
export const shadows = {
    actionBar: `0 0 1px 0 ${slateA.slateA9}, 0 -2px 6px 0 ${slateA.slateA3}`,
    projectBar: `0 1px 0 0 ${slateA.slateA6}, 0 1px 3px 0 ${slateA.slateA6}`,
    sidebarTabs: `0 1px 0 0 ${slateA.slateA11}`,
    sidebarFooter: `0 -1px 0 0 ${slateA.slateA11}`,
    borders: {
        top: `0 -1px 0 0 ${colors.blackA2}`,
        right: `1px 0 0 0 ${colors.blackA2}`,
        bottom: `0 1px 0 0 ${colors.blackA2}`,
        left: `-1px 0 0 0 ${colors.blackA2}`,
        black: `0 0 0 1px ${colors.blackA1}`,
        blackInset: `inset 0 0 0 1px ${colors.blackA1}`,
        inset: `inset 0 0 0 1px ${colors.slate6}`,
        insetLight: `inset 0 0 0 1px ${colors.slate6}`,
    },
    button: {
        ghost: `inset 1px 0 0 ${colors.smoke}, inset -1px 0 0 ${colors.smoke}, inset 0 1px 0 ${colors.smoke}, inset 0 -1px 0 ${colors.smokeDark}, 0 1px 2px 0 rgba(12,16,20,0.10)`,
        ghostHover: `inset 1px 0 0 ${colors.smoke}, inset -1px 0 0 ${colors.smoke}, inset 0 1px 0 ${colors.smoke}, inset 0 -1px 0 ${colors.smokeDark}, inset 0 0 0 1px ${colors.smoke}, 0 2px 4px 0 rgba(12,16,20,0.10)`,
        ghostReversed: `inset 0 0 0 1px ${whiteA.whiteA5}`,
        ghostReversedHover: `inset 0 0 0 1px rgba(255,255,255,0)`,
        withShadow: `0 0 0 1px ${blackA.blackA1}, 0 1px 3px 0 ${blackA.blackA1}`,
        toolbar: `0 0 0 1px ${slateA.slateA4}`,
        green: `inset 1px 0 0 ${slateA.slateA1}, inset -1px 0 0 ${slateA.slateA1}, inset 0 1px 0 ${slateA.slateA1}, inset 0 -1px 0 ${slateA.slateA2}, inset 0 0 0 1px ${slateA.slateA1}, 0 1px 2px 0 rgba(25,64,22,0.10)`,
        greenHover: `inset 1px 0 0 ${slateA.slateA1}, inset -1px 0 0 ${slateA.slateA1}, inset 0 1px 0 ${slateA.slateA1}, inset 0 -1px 0 ${slateA.slateA2}, inset 0 0 0 1px ${slateA.slateA1}, 0 2px 4px 0 rgba(25,64,22,0.10)`,
        marvel: `inset 1px 0 0 ${slateA.slateA1}, inset -1px 0 0 ${slateA.slateA1}, inset 0 1px 0 ${slateA.slateA1}, inset 0 -1px 0 ${slateA.slateA2}, inset 0 0 0 1px ${slateA.slateA1}, 0 1px 2px 0 rgba(2,47,69,0.10)`,
        marvelHover: `inset 1px 0 0 ${slateA.slateA1}, inset -1px 0 0 ${slateA.slateA1}, inset 0 1px 0 ${slateA.slateA1}, inset 0 -1px 0 ${slateA.slateA2}, inset 0 0 0 1px ${slateA.slateA1}, 0 2px 4px 0 rgba(2,47,69,0.10)`,
        danger: `inset 1px 0 0 ${slateA.slateA1}, inset -1px 0 0 ${slateA.slateA1}, inset 0 1px 0 ${slateA.slateA1}, inset 0 -1px 0 ${slateA.slateA2}, inset 0 0 0 1px ${slateA.slateA1}, 0 1px 2px 0 rgba(53,17,13,0.10)`,
        dangerHover: `inset 1px 0 0 ${slateA.slateA1}, inset -1px 0 0 ${slateA.slateA1}, inset 0 1px 0 ${slateA.slateA1}, inset 0 -1px 0 ${slateA.slateA2}, inset 0 0 0 1px ${slateA.slateA1}, 0 2px 4px 0 rgba(81,25,19,0.10)`,
        text: '0 1px 0 rgba(12,16,20,0.05)',
        // A separate loading state shadow is needed for askhub
        loading: `inset 1px 0 0 ${colors.smoke}, inset -1px 0 0 ${colors.smoke}, inset 0 1px 0 ${colors.smoke}, inset 0 -1px 0 ${colors.smokeDark}, 0 1px 2px 0 rgba(12,16,20,0.10)`,
        disabled: `0 1px 2px 0 ${colors.blackA1}`,
        secondary: `0 1px 2px 0 ${colors.blackA1}`,
        secondaryHover: `0 2px 3px 0 ${colors.blackA1}`,
        secondaryFocus: `0 0 0 2px ${colors.blue6}`,
        marketing: `0 1px 2px 0 ${colors.blackA1}, 0 0 0 1px ${colors.blackA1}`,
    },
    select: {
        disabled: `inset 0 0 0 1px ${colors.smoke}`,
        ghost: `inset 0 0 0 1px ${colors.smoke}`,
        ghostFocus: `inset 0 0 0 1px ${colors.marvel}`,
        ghostHover: `inset 0 0 0 1px ${colors.smokeExtraDark}`,
        error: `inset 0 0 0 1px ${colors.red}`,
    },
    input: {
        whiteTransparentHover: `inset 0 0 0 1px ${whiteA.whiteA6}`,
        whiteTransparentFocus: `inset 0 0 0 1px ${colors.white}`,
        underline: `inset 0 -1px 0 ${colors.smoke}`,
        underlineHover: `inset 0 -1px 0 ${colors.smokeExtraDark}`,
        underlineFocus: `inset 0 -1px 0 ${colors.marvel}`,
        default: `inset 0 0 0 1px ${colors.slate6}`,
        disabled: `inset 0 0 0 1px ${colors.slate6}`,
        defaultTransparent: `0 0 0 1px ${colors.blackA2}`,
        defaultHover: `inset 0 0 0 1px ${colors.slate7}`,
        defaultFocus: `0 0 0 3px ${colors.blue5}, inset 0 0 0 1px ${colors.blue8}`,
        focus: `0 0 0 3px ${colors.blue5}`,
        error: `0 0 0 3px ${colors.red5}`,
    },
    projectFrame: {
        default: `0 0 0 1px ${slateA.slateA5}, 0 1px 2px 0 ${slateA.slateA4};`,
        hover: `0 0 0 1px ${slateA.slateA1}, 0 2px 4px 0 ${slateA.slateA2}, 0 8px 16px 0 ${slateA.slateA5};`,
        active: `0 0 1px 0 ${slateA.slateA8}, 0 1px 2px 0 ${slateA.slateA4}`,
    },
    popover: {
        raised: `0 0 0 1px ${slateA.slateA4}, 0 8px 10px 0 ${slateA.slateA4}, 0 8px 24px 0 ${slateA.slateA3}`,
        default: `0 0 0 1px ${colors.blackA1}, 0 8px 10px 0 ${colors.blackA1}, 0 8px 24px 0 ${colors.blackA1}`,
        balloon: `0 0 0 1px ${colors.blackA1}, 0 2px 4px 0 ${colors.blackA1}`,
    },
    checkbox: {
        default: `inset 0 0 0 1px ${colors.smoke}`,
        defaultChecked: `inset 0 0 0 50% ${colors.green}`,
    },
    card: {
        defaultDarker: `0 0 0 1px ${slateA.slateA7}, 0 1px 2px 0 ${slateA.slateA7}`,
        selected: `0 0 0 2px ${colors.white}, 0 0 0 4px ${colors.green}`,
        current: `0 0 0 2px ${colors.white}, 0 0 0 4px ${colors.marvel}`,
        raised: '0 14px 30px 0 rgba(34, 45, 57, 0.18), 0 4px 3px 0 rgba(34, 45, 57, 0.06), 0 0 0 1px rgba(34, 45, 57, 0.06)',
        default: `0 0 0 1px ${colors.blackA1}, 0 1px 2px 0 ${colors.blackA1}`,
        inactive: `0 0 0 1px ${colors.blackA1}, 0 1px 2px 0 ${colors.blackA1}, 0 0 0 5px ${colors.slate3}`,
    },
    image: {
        default: `0 0 0 1px ${slateA.slateA6}`,
        selected: `0 0 0 2px ${colors.white}, 0 0 0 4px ${colors.green}`,
    },
    modal: `0 6px 12px 0 ${blackA.blackA1}`,
    deleteProjects: `inset 0 0 0 1px ${slateA.slateA7}`,
    raiseOnHover: `0 0 0 1px ${slateA.slateA1}, 0 2px 4px 0 ${slateA.slateA2}, 0 8px 16px 0 ${slateA.slateA5}`,
    toast: `0 2px 6px 0 ${blackA.blackA2}, 0 3px 15px 0 ${blackA.blackA1}`,
    toggleButton: {
        default: `inset 0 0 0 1px ${colors.smoke}`,
        selected: `inset 0 0 0 2px ${colors.marvel}`,
    },
    userTestType: {
        default: `inset 0 0 0 1px ${colors.smoke}`,
        selected: `inset 0 0 0 2px ${colors.marvel}`,
    },
    userTestScreenSelector: {
        default: `inset 0 0 0 1px ${colors.smoke}`,
        error: `inset 0 0 0 2px ${colors.marvel}`,
    },
    sidebarOverlay: {
        left: '8px 0 24px 0 rgba(34,45,57,0.05), 8px 0 10px 0 rgba(34,45,57,0.08)',
        right: '-8px 0 24px 0 rgba(34,45,57,0.05), -8px 0 10px 0 rgba(34,45,57,0.08)',
    },
    userTestTab: {
        selected: `inset 0 -3px 0 ${colors.marvel}`,
    },
    userTestStatsCard: `0px 2px 4px ${colors.black}`,
    cardIcon: {
        default: '0 0 1px 0 rgba(30, 45, 62, 0.2), 0 1px 3px 0 rgba(30, 45, 62, 0.12)',
    },
    constraintPreview: `0 1px 1px 0 ${colors.smokeDark}`,
    dottedCard: {
        hover: `0 1px 4px 0 ${colors.blackA1}`,
    },
    sidebar: {
        left: `1px 0 4px 0 ${colors.blackA1}`,
    },
    typeCard: {
        active: `0 0 0 4px ${colors.blue6}`,
        skipped: `0 0 0 4px ${colors.slate6}`,
    },
    brandLogo: {
        active: `0 0 0 2px ${colors.background}, 0 0 0 5px ${colors.blue6}`,
    },
    editable: {
        hover: `0 0 0 5px ${colors.background}, 0 0 0 8px ${colors.slate5}`,
        focus: `0 0 0 5px ${colors.background}, 0 0 0 8px ${colors.blue6}`,
    },
    video: {
        player: `0 6px 24px 0 ${colors.blackA4}`,
    },
    currentPlan: `0 0 0 2px ${colors.slate8}`,
};
