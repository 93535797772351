export const kinds = {
    medium: {
        bg: 'snowDark',
    },
    light: {
        bg: 'snow',
    },
    dark: {
        bg: 'slate',
    },
};
