import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Upsell from './components/Upsell';
import { Position } from './types';
export function DashboardTinyUpseller() {
    return React.createElement(Upsell, { position: Position.DashboardTinyUpseller });
}
export function DashboardNotificationBar() {
    return React.createElement(Upsell, { position: Position.DashboardNotificationBar });
}
export function FloatingUpsell() {
    const location = useLocation();
    const allowedPaths = [
        '/projects/my',
        '/projects/team',
        '/projects/personal',
        '/archive/my',
        '/archive/team',
        '/company/people/members',
        '/company/people/guests',
        '/company/settings',
        '/user/account/personal',
        '/user/account/apps',
    ];
    const isAllowed = allowedPaths
        .map((path) => {
        return !!matchPath(location.pathname, {
            path,
            exact: true,
        });
    })
        .includes(true);
    return isAllowed ? React.createElement(Upsell, { position: Position.Floating }) : null;
}
export function DashboardHintBar() {
    return React.createElement(Upsell, { position: Position.DashboardHintBar });
}
export function Webinar() {
    return React.createElement(Upsell, { position: Position.DashboardSideBar });
}
export { setViewedPricingPage, setDidDowngrade } from './storage-assist';
