import { __rest } from "tslib";
import React, { forwardRef, } from 'react';
import { cn } from './utils';
const Root = forwardRef(function Root(props, ref) {
    const { className, minWidth } = props, rest = __rest(props, ["className", "minWidth"]);
    return (React.createElement("div", { style: { minWidth } },
        React.createElement("table", Object.assign({ className: cn('w-full', 'table-fixed', 'overflow-hidden', className), ref: ref }, rest))));
});
const Thead = forwardRef(function Thead(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement("thead", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Tbody = forwardRef(function Tbody(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement("tbody", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Tfoot = forwardRef(function Tfoot(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement("tfoot", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Tr = forwardRef(function Tr(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement("tr", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Th = forwardRef(function Th(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement("th", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Td = forwardRef(function Td(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement("td", Object.assign({ className: cn(className), ref: ref }, rest));
});
const Caption = forwardRef(function Caption(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return React.createElement("caption", Object.assign({ className: cn(className), ref: ref }, rest));
});
export const Table = Object.assign(Root, {
    Caption,
    Tbody,
    Td,
    Tfoot,
    Th,
    Thead,
    Tr,
});
