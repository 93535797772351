import { __rest } from "tslib";
import React from 'react';
import { Avatar as MarvelAvatar } from '@marvelapp/ui';
var Sizes;
(function (Sizes) {
    Sizes[Sizes["xss"] = 16] = "xss";
    Sizes[Sizes["xs"] = 24] = "xs";
    Sizes[Sizes["s"] = 32] = "s";
    Sizes[Sizes["m"] = 48] = "m";
    Sizes[Sizes["l"] = 64] = "l";
})(Sizes || (Sizes = {}));
export function Avatar(_a) {
    var { size = 's' } = _a, rest = __rest(_a, ["size"]);
    return React.createElement(MarvelAvatar, Object.assign({ size: Sizes[size] }, rest));
}
