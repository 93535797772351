import { __rest } from "tslib";
import React from 'react';
import { Heading as MarvelHeading } from '@marvelapp/ui';
export const sizes = {
    xxs: {
        fontSize: 'xxs',
        fontWeight: 'semibold',
        lineHeight: 'heading',
        textTransform: 'uppercase',
        letterSpacing: '0.2px',
    },
    s: {
        fontSize: 's',
        fontWeight: 'medium',
        lineHeight: 'heading',
    },
    m: {
        fontSize: 'm',
        fontWeight: 'semibold',
        lineHeight: 'heading',
    },
    l: {
        fontSize: 'l',
        fontWeight: 'semibold',
        lineHeight: 'heading',
    },
    xl: {
        fontSize: 'xl',
        fontWeight: 'semibold',
        lineHeight: 'heading',
    },
    xxl: {
        fontSize: ['xxl', 'xxl', 'xxl', 'xxl', 'xxl', 'xxxl'],
        fontWeight: 'semibold',
        lineHeight: 'heading',
    },
};
export const kinds = {
    primary: {
        color: 'slate12',
    },
    secondary: {
        color: 'slate11',
    },
    reversed: {
        color: 'background',
    },
};
export function Heading(_a) {
    var { as, children, kind = 'primary', size, truncated } = _a, rest = __rest(_a, ["as", "children", "kind", "size", "truncated"]);
    return (React.createElement(MarvelHeading, Object.assign({ as: as, truncated: truncated }, kinds[kind], (size && sizes[size]), rest), children));
}
