import React from 'react';
import { Loader as LoaderPrimitive } from './Loader';
const ICON_CLASSNAME = 'inner-icon';
/**
 * To used when you have to conditionally render a child with an icon next to it
 * and also show a loading state.
 */
function ChildrenWithIconBase(props) {
    const { isLoading, standaloneIcon, leadingIcon, trailingIcon, children } = props;
    if (isLoading && standaloneIcon) {
        return React.createElement(Loader, null);
    }
    if (!isLoading && standaloneIcon) {
        return React.createElement(Icon, null, standaloneIcon);
    }
    if (isLoading) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, null),
            children));
    }
    if (leadingIcon) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Icon, null, leadingIcon),
            children));
    }
    if (trailingIcon) {
        return (React.createElement(React.Fragment, null,
            children,
            React.createElement(Icon, null, trailingIcon)));
    }
    return React.createElement(React.Fragment, null, children);
}
function Icon(props) {
    return (React.createElement("span", { "data-testid": props.testId, className: ICON_CLASSNAME }, props.children));
}
function Loader() {
    return (React.createElement(Icon, null,
        React.createElement(LoaderPrimitive, null)));
}
const ChildrenWithIcon = Object.assign(ChildrenWithIconBase, {
    Icon,
    Loader,
});
export { ChildrenWithIcon, ICON_CLASSNAME };
