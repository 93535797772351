import { __rest } from "tslib";
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import React, { forwardRef } from 'react';
import { checkToggleVariants } from './CheckToggle';
const Item = forwardRef(function Item(props, ref) {
    const { className, variant } = props, rest = __rest(props, ["className", "variant"]);
    return (React.createElement(RadioGroupPrimitive.Item, Object.assign({ className: checkToggleVariants({ className, variant }), ref: ref }, rest)));
});
const RadioToggle = {
    Root: RadioGroupPrimitive.Root,
    Item,
    Indicator: RadioGroupPrimitive.Indicator,
};
export { RadioToggle };
