import theme from '@marvelapp/ballpark-theme';
import styled, { css } from '@marvelapp/styled';
import { boxStyles } from '../Box';
import { textDecoration } from '../customProperties';
import { underlineColor } from './colors';
export const LinkElement = styled.a `
  ${boxStyles};
  ${textDecoration}

  cursor: pointer;
  transition-duration: 150ms;

  &.${'active'} {
    color: ${theme.colors.licorice};
    font-weight: 500;

    &:hover {
      color: ${theme.colors.licorice};
    }
  }

  ${(props) => props.hasUnderline &&
    css `
      background-position: 0 95%;
      background-repeat: repeat-x;
      background-size: 2px 2px;

      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 50%,
        ${underlineColor[props.color]} 50%
      );

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 75%,
          ${underlineColor[props.color]} 75%
        );
      }
    `};
`;
