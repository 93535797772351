export const sizes = [
    {
        borderRadius: 12,
        height: 24,
        width: 24,
    },
    {
        borderRadius: 15,
        height: 30,
        width: 30,
    },
    {
        borderRadius: 20,
        height: 40,
        width: 40,
    },
];
export const iconSizes = [
    {
        width: 18,
        height: 18,
    },
    {
        width: 22,
        height: 22,
    },
    {
        width: 24,
        height: 24,
    },
];
export default sizes;
