// TODO: Unify input, button and select sizes
export const buttonSizes = (isRounded) => [
    {
        fontSize: 1,
        borderRadius: isRounded ? 13 : 1,
        height: 26,
        lineHeight: '26px',
        pl: 3,
        pr: 3,
    },
    {
        fontSize: 12,
        borderRadius: isRounded ? 15 : 1,
        height: 30,
        lineHeight: '30px',
        pl: 3,
        pr: 3,
    },
    {
        fontSize: [1, 12, 12, 12],
        borderRadius: isRounded ? [17, 20, 20, 20] : [1, 2],
        height: [34, 36, 36, 36],
        lineHeight: ['34px', '36px', '36px', '36px'],
        pl: [18, 20, 20, 20],
        pr: [18, 20, 20, 20],
    },
    {
        fontSize: [2, 3, 3, 3],
        borderRadius: isRounded ? [20, 25, 25, 25] : 2,
        height: [40, 50, 50, 50],
        lineHeight: ['40px', '50px', '50px', '50px'],
        pl: [25, 30, 30, 30],
        pr: [25, 30, 30, 30],
    },
];
export const loaderSizes = [
    {
        size: 12,
        borderWidth: 3,
    },
    {
        size: 16,
        borderWidth: 3,
    },
    {
        size: 20,
        borderWidth: 4,
    },
    {
        size: 24,
        borderWidth: 4,
    },
];
export const iconSizes = [
    {
        width: 18,
        height: 18,
    },
    {
        width: 24,
        height: 24,
    },
    {
        width: 32,
        height: 32,
    },
    {
        width: 40,
        height: 40,
    },
];
