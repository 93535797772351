import isNotStyledSystemProp from '@styled-system/should-forward-prop';
// eslint-disable-next-line no-restricted-imports
import defaultStyled from 'styled-components';
// See https://github.com/styled-components/styled-components/blob/8165cbe994f6f749236244f6f7017c2f0b9afcfe/packages/styled-components/src/models/StyledComponent.ts#L129-L142
// to understand how the function is used
function shouldForwardProp(prop, isValidAttr, elementToBeCreated) {
    if (typeof elementToBeCreated !== 'string') {
        // Forward every prop when the element is a custom component,
        // typically declared with as={MyComponent}
        return true;
    }
    return (
    // Some styled-system props, such as width and height,
    // can be valid attributes for certain tags (e.g. img, video, ...)
    isValidAttr(prop) || isNotStyledSystemProp(prop));
}
// @ts-ignore
const styledWithForwardProp = (tag) => {
    return defaultStyled(tag).withConfig({
        // @ts-ignore
        shouldForwardProp,
    });
};
// @ts-ignore
const styled = styledWithForwardProp;
// Proxying the `styled-components` tag shortcuts, e.g. `styled.div`, `styled.a`, etc.
Object.keys(defaultStyled).forEach((tag) => {
    // @ts-ignore
    styled[tag] = styledWithForwardProp(tag);
});
// eslint-disable-next-line no-restricted-imports
export * from 'styled-components';
export default styled;
