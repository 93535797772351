// Custom easing functions
export const easings = {
    bouncy: {
        string: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
        array: [0.68, -0.55, 0.265, 1.55],
    },
    smooth: {
        string: 'cubic-bezier(0.23, 1, 0.32, 1)',
        array: [0.23, 1, 0.32, 1],
    },
};
// Custom transitions
export const transitions = {
    smooth: {
        fast: `all 300ms ${easings.smooth.string}`,
        medium: `all 600ms ${easings.smooth.string}`,
        slow: `all 900ms ${easings.smooth.string}`,
    },
};
