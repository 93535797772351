export var ProjectVisibility;
(function (ProjectVisibility) {
    ProjectVisibility["Private"] = "PRIVATE";
    ProjectVisibility["Workspace"] = "WORKSPACE";
})(ProjectVisibility || (ProjectVisibility = {}));
export var CompanyVisibility;
(function (CompanyVisibility) {
    CompanyVisibility["InviteOnly"] = "INVITE_ONLY";
    CompanyVisibility["Unlocked"] = "UNLOCKED";
})(CompanyVisibility || (CompanyVisibility = {}));
