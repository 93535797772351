import { billing } from '@marvelapp/core';
export const getCompany = (user) => user === null || user === void 0 ? void 0 : user.company;
export const getCompanyName = (user) => getCompany(user).name;
export const getCompanyAvatar = (user) => getCompany(user).iconUrl;
export const getCompanyOwner = (user) => getCompany(user).owner;
const getBilling = (user) => getCompany(user).billing;
// Workspace Properties
export const hasUserTestProjects = (user) => getCompany(user).userTestProjectCount > 0;
export const projectQuantityPurchased = (user) => getBilling(user).projectQuantityPurchased;
export const prototypeProjectCount = (user) => getCompany(user).prototypeProjectCount;
export const userTestProjectCount = (user) => getCompany(user).userTestProjectCount;
export const whiteboardProjectCount = (user) => getCompany(user).whiteboardProjectCount;
// User Properties
export const getUserRole = (user) => user === null || user === void 0 ? void 0 : user.role;
export const getProperties = (user) => (user === null || user === void 0 ? void 0 : user.properties) || {};
export const screensOwnedCount = (user) => getProperties(user).screensOwnedCount;
export const teamsOwnedCount = (user) => getProperties(user).teamsOwnedCount;
export const prototypeProjectsOwnedCount = (user) => getProperties(user).prototypeProjectsOwnedCount;
export const userTestProjectsOwnedCount = (user) => getProperties(user).userTestProjectsOwnedCount;
export const hotspotsOwnedCount = (user) => getProperties(user).hotspotsOwnedCount;
export const customerId = (user) => getBilling(user).customerId;
const getPlanLabel = (user) => getBilling(user).plan.label;
export const trialDaysRemaining = (user) => getBilling(user).trialDaysRemaining;
export const hasRemainingTrialDays = (user) => trialDaysRemaining(user) > 0;
export const isFree = (user) => getPlanLabel(user) === 'FREE';
export const isPro = (user) => getPlanLabel(user) === 'PRO';
export const isTeam = (user) => getPlanLabel(user) === 'TEAM';
export const isTeamPlus = (user) => getPlanLabel(user) === 'COMPANY';
export const isEnterprise = (user) => getPlanLabel(user) === 'ENTERPRISE';
export const isEnterpriseAndTrialExpired = (user) => getBilling(user).subscriptionState === billing.SubscriptionStatus.IsTrial &&
    getCompany(user).accountLockOverride;
export const isLockedFreePlan = (user) => isFree(user) && !hasRemainingTrialDays(user);
export const isOnFreeTrial = (user) => isFree(user) && hasRemainingTrialDays(user);
export const hasFreeTrialEnabled = (user) => { var _a; return (_a = getCompany(user).flags) === null || _a === void 0 ? void 0 : _a.unrestrictedProjectTest; };
export const hasHitPrototypeLimit = (user) => {
    const quantityPurchased = projectQuantityPurchased(user);
    return (!hasFreeTrialEnabled(user) &&
        isFree(user) &&
        prototypeProjectCount(user) >= quantityPurchased);
};
export const isAccountLocked = (user) => isLockedFreePlan(user) ||
    (isEnterprise(user) && getCompany(user).accountLockOverride);
export const hasHitBallparkProjectLimit = (user) => projectQuantityPurchased(user) > 0 &&
    userTestProjectCount(user) >= projectQuantityPurchased(user);
export const hasHitUserTestLimit = (user) => !hasFreeTrialEnabled(user) && hasUserTestProjects(user) && isFree(user);
export const hasHitWhiteboardLimit = (user) => {
    return (!hasFreeTrialEnabled(user) &&
        whiteboardProjectCount(user) > 0 &&
        isFree(user));
};
export const hasHitAnyProjectsLimit = (user) => hasHitPrototypeLimit(user) ||
    hasHitUserTestLimit(user) ||
    hasHitWhiteboardLimit(user);
export const getCapitalisedPlanLabel = (user) => {
    return {
        FREE: 'Free',
        PRO: 'Pro',
        TEAM: 'Team',
        COMPANY: 'Team Plus',
        ENTERPRISE: 'Enterprise',
    }[getPlanLabel(user)];
};
export function canUnarchive(user, selectedPrototypeCount, selectedUserTestCount) {
    if (!isFree(user) || hasFreeTrialEnabled(user)) {
        // Only Free plan has limited project allowance
        return true;
    }
    const userTestCount = userTestProjectCount(user);
    if (selectedUserTestCount > 0 && userTestCount > 0) {
        // Free can only have 1 user test so you can't unarchive 1 if you have 1
        // already
        return false;
    }
    const prototypeAllowance = projectQuantityPurchased(user);
    const prototypeCount = prototypeProjectCount(user);
    if (prototypeCount + selectedPrototypeCount > prototypeAllowance) {
        return false;
    }
    return true;
}
