import { __rest } from "tslib";
import React from 'react';
import { tv } from 'tailwind-variants';
import { CheckIcon, EnterIcon, LeaveIcon } from '@marvelapp/ballpark-icons';
import { BtwHeading } from '../BtwHeading';
import { Stack } from '../Stack';
const box = tv({
    base: [
        'pl-10',
        'pr-16',
        'py-10',
        'ring-1',
        'bg-white',
        'rounded-full',
        'shadow-sm-longer',
        'ring-gray-600/20',
    ],
    variants: {
        variant: {
            startScreen: ['text-gray-900'],
            droppedOff: ['text-red-800'],
            droppedOffFreeRoam: ['text-gray-900'],
            goalReached: ['text-green-800'],
        },
    },
    defaultVariants: {
        variant: 'startScreen',
    },
});
const iconWrapper = tv({
    base: [
        'flex',
        'h-20',
        'w-20',
        'items-center',
        'justify-center',
        'rounded-full',
        'flex-shrink-0',
    ],
    variants: {
        variant: {
            startScreen: ['bg-gray-200/75'],
            droppedOff: ['bg-red-200'],
            droppedOffFreeRoam: ['bg-gray-200/75'],
            goalReached: ['bg-green-200'],
        },
    },
    defaultVariants: {
        variant: 'startScreen',
    },
});
function StartEndBox(props) {
    const { variant = 'startScreen' } = props;
    return (React.createElement(Stack, { "data-testid": variant },
        React.createElement(Stack, { justify: "center", align: "center", direction: "row", asChild: true, gap: "6" },
            React.createElement("div", { className: box({ variant }) },
                React.createElement("div", { className: iconWrapper({ variant }) },
                    React.createElement(Icon, { variant: variant, className: "h-10 w-10 [&>path]:stroke-[4px]" })),
                React.createElement(BtwHeading, { truncate: true, variant: "current", size: "3xl" }, getText({ variant }))))));
}
function Icon(props) {
    const { variant } = props, rest = __rest(props, ["variant"]);
    switch (variant) {
        case 'startScreen':
            return React.createElement(EnterIcon, Object.assign({}, rest));
        case 'droppedOff':
        case 'droppedOffFreeRoam':
            return React.createElement(LeaveIcon, Object.assign({}, rest));
        case 'goalReached':
            return React.createElement(CheckIcon, Object.assign({}, rest));
        default:
            return null;
    }
}
const getText = (args) => {
    switch (args.variant) {
        case 'startScreen':
            return 'Test started';
        case 'droppedOff':
        case 'droppedOffFreeRoam':
            return 'Dropped off';
        case 'goalReached':
            return 'Goal reached';
        default:
            return '';
    }
};
export { StartEndBox };
