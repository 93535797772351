import { differenceInMonths } from 'date-fns/esm';
import { userSelectors } from '@marvelapp/ballpark-application';
import { UserRole, getDaysToDate } from '@marvelapp/core';
import { didDowngradeInPastHour, getAssists, seenPlansInPastHour, } from './storage-assist';
const EXPIRY_WINDOW_DAYS = 14;
const SOURCE_STRIPE = 2;
export function getUserInfo(user, subscription) {
    const { pk, createdAt, role, company, testGroup } = user;
    const isOwner = role === UserRole.Owner;
    const ownerUsername = company.owner.username;
    const ownerEmail = company.owner.email;
    const paysWithStripe = subscription.source === SOURCE_STRIPE;
    const monthsSinceSignup = differenceInMonths(new Date(), new Date(createdAt));
    const planIsYearly = company.billing.billingCycle === 'yearly';
    const { nextPaymentDate } = company.billing;
    const daysToNextPayment = getDaysToDate(new Date(nextPaymentDate));
    const nextPaymentWithinExpiryWindow = daysToNextPayment < EXPIRY_WINDOW_DAYS;
    const assists = getAssists();
    const hasSeenPlansInPastHour = seenPlansInPastHour(assists);
    const hasDowngradedInPastHour = didDowngradeInPastHour(assists);
    const canUpgradePlan = [
        UserRole.Owner,
        UserRole.Admin,
        UserRole.Editor,
    ].includes(role);
    return {
        canUpgradePlan,
        daysToNextPayment,
        hasDowngradedInPastHour,
        hasHitUserTestLimit: userSelectors.hasHitUserTestLimit(user),
        hasSeenPlansInPastHour,
        hasUserTestProjects: userSelectors.hasUserTestProjects(user),
        hitAnyProjectsLimit: userSelectors.hasHitAnyProjectsLimit(user),
        isAccountLocked: userSelectors.isAccountLocked(user),
        isEnterprise: userSelectors.isEnterprise(user),
        isEnterpriseAndTrialExpired: userSelectors.isEnterpriseAndTrialExpired(user),
        isFree: userSelectors.isFree(user),
        isLockedFreePlan: userSelectors.isLockedFreePlan(user),
        isOwner,
        isPro: userSelectors.isPro(user),
        isTeam: userSelectors.isTeam(user),
        monthsSinceSignup,
        nextPaymentWithinExpiryWindow,
        ownerEmail,
        ownerUsername,
        paysWithStripe,
        pk,
        planIsYearly,
        role,
        testGroup,
        userTestProjectsOwnedCount: userSelectors.userTestProjectsOwnedCount(user),
    };
}
