import React from 'react';
import { BtwButton, FullScreenPage } from '@marvelapp/ballpark-components';
import { Page } from '@marvelapp/ui-internal';
function ProjectUnavailable({ status }) {
    const isEmbedded = window.top !== window.self;
    const title = `This project is ${status}`;
    return (
    // Important to set this because embeds are transparent, this avoids the project looking odd when embedded on a red page.
    React.createElement("div", { className: isEmbedded ? 'bg-gray-50' : 'bg-white' },
        React.createElement(Page, { title: title },
            React.createElement(FullScreenPage.Container, { noFooter: true },
                React.createElement(FullScreenPage.Message, { heading: title, description: "Ask the owner to verify the share link and/or update permissions to play this project." },
                    React.createElement(BtwButton, { asChild: true, variant: "legacyBlue" },
                        React.createElement("a", { "data-testid": "archived-page-cta", href: "https://ballparkhq.com" }, "Visit Ballpark")))))));
}
export function ProjectLocked() {
    return React.createElement(ProjectUnavailable, { status: "locked" });
}
export function ProjectArchived() {
    return React.createElement(ProjectUnavailable, { status: "archived" });
}
