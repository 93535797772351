import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import React from 'react';
import { tv } from 'tailwind-variants';
import { CheckIcon, MinusIcon } from '@marvelapp/ballpark-icons';
const root = tv({
    base: [
        'flex',
        'items-center',
        'justify-center',
        'cursor-pointer',
        'ring-1',
        // Transition
        'duration-300',
        'ease-smooth',
        'transition-combined',
        'will-change-transform',
        // Repeated disabled styles
        'data-[disabled]:bg-gray-200',
        'data-[disabled]:text-gray-500',
        'data-[disabled]:ring-transparent',
        'data-[disabled]:shadow-none',
        'data-[disabled]:cursor-not-allowed',
        'data-[disabled]:hover:translate-y-0',
        'data-[disabled]:active:translate-y-0',
        // Repeated active styles
        'active:translate-y-px',
        'active:outline-transparent',
        // Default state
        'bg-white',
        'text-gray-900',
        'ring-gray-600/20',
        'shadow-sm',
        // Hover state
        'hover:bg-gray-50',
        'hover:shadow-md',
        'hover:ring-gray-600/40',
        // Focus state
        'focus-visible:ring-gray-600',
        'focus-visible:outline-none',
        'focus-visible:outline-gray-300',
        // Active state
        'active:bg-gray-100',
        'active:ring-gray-600/25',
        'active:shadow-sm',
    ],
    variants: {
        variant: {
            primary: [
                // Checked state
                'data-[state=checked]:bg-gray-900',
                'data-[state=checked]:text-gray-50',
                'data-[state=checked]:ring-gray-900',
            ],
            blue: [
                // Checked state
                'data-[state=checked]:bg-blue-600',
                'data-[state=checked]:text-blue-50',
                'data-[state=checked]:ring-blue-600',
            ],
        },
        size: {
            sm: ['w-4', 'h-4', 'rounded', '[&>span>svg]:w-4', '[&>span>svg]:h-4'],
            base: ['w-5', 'h-5', 'rounded', '[&>span>svg]:w-4', '[&>span>svg]:h-4'],
        },
    },
    defaultVariants: {
        size: 'base',
        variant: 'primary',
    },
});
function BtwCheckbox(props) {
    const { checked, size, variant } = props;
    return (React.createElement(CheckboxPrimitive.Root, Object.assign({ className: root({ size, variant }) }, props),
        React.createElement(CheckboxPrimitive.Indicator, null,
            checked === 'indeterminate' && React.createElement(MinusIcon, null),
            checked === true && React.createElement(CheckIcon, null))));
}
export { BtwCheckbox };
