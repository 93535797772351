import { HBLockedAccount } from '../components/HBLockedAccount';
import { WebinarUpsell } from '../components/WebinarUpsell';
import { Identifier, Position } from '../types';
export default function upsells(userInfo) {
    const { isFree, isAccountLocked } = userInfo;
    return {
        [Position.DashboardSideBar]: [
            {
                identifier: Identifier.Webinar,
                component: WebinarUpsell,
                isClosable: true,
                condition: isFree,
                daysUntilReshownAfterClose: 365,
            },
        ],
        [Position.DashboardHintBar]: [
            {
                identifier: Identifier.LockedAccount,
                component: HBLockedAccount,
                isClosable: false,
                condition: isAccountLocked,
            },
        ],
    };
}
