import { amber, amberDark, blue, blueA, blueDark, crimson, cyan, grass, grassDark, green, indigo, lime, mint, olive, orange, pink, plum, purple, red, sky, slate, slateA, slateDark, teal, tomato, tomatoDark, violet, yellow, } from '@radix-ui/colors';
// Replacing blackA and whiteA from Radix because their versions suck
export const blackA = {
    blackA1: 'hsl(0 0% 0% / 0.08)',
    blackA2: 'hsl(0 0% 0% / 0.17)',
    blackA3: 'hsl(0 0% 0% / 0.25)',
    blackA4: 'hsl(0 0% 0% / 0.33)',
    blackA5: 'hsl(0 0% 0% / 0.42)',
    blackA6: 'hsl(0 0% 0% / 0.50)',
    blackA7: 'hsl(0 0% 0% / 0.58)',
    blackA8: 'hsl(0 0% 0% / 0.67)',
    blackA9: 'hsl(0 0% 0% / 0.75)',
    blackA10: 'hsl(0 0% 0% / 0.83)',
    blackA11: 'hsl(0 0% 0% / 0.92)',
    blackA12: 'hsl(0 0% 0% / 1)',
};
export const whiteA = {
    whiteA1: 'hsl(0 0% 100% / 0.08)',
    whiteA2: 'hsl(0 0% 100% / 0.17)',
    whiteA3: 'hsl(0 0% 100% / 0.25)',
    whiteA4: 'hsl(0 0% 100% / 0.33)',
    whiteA5: 'hsl(0 0% 100% / 0.42)',
    whiteA6: 'hsl(0 0% 100% / 0.50)',
    whiteA7: 'hsl(0 0% 100% / 0.58)',
    whiteA8: 'hsl(0 0% 100% / 0.67)',
    whiteA9: 'hsl(0 0% 100% / 0.75)',
    whiteA10: 'hsl(0 0% 100% / 0.83)',
    whiteA11: 'hsl(0 0% 100% / 0.92)',
    whiteA12: 'hsl(0 0% 100% / 1)',
};
export const legacyMarvelColors = {
    marvelDark: '#009EEB',
    orange: '#FD6F3C',
    orangeLight: '#FF977A',
    orangeExtraLight: '#FFB6A1',
    orangeExtraExtraLight: '#FFCEBD',
    yellowDark: '#A36300',
    yellow: '#FFAE30',
    yellowLight: '#FFC772',
    yellowExtraLight: '#FFD79B',
    yellowExtraExtraLight: '#FFE3B7',
    purple: '#AD6EDD',
    purpleLight: '#C896EA',
    purpleExtraLight: '#D6AFF1',
    purpleExtraExtraLight: '#DFC7F0',
    teal: '#60D2C9',
    tealLight: '#8FDFDA',
    tealExtraLight: '#BBEDED',
    tealExtraExtraLight: '#DDF6F6',
    red: '#F95849',
    redDark: '#E65143',
    twitter: '#55acee',
    facebook: '#3b5999',
    dribbble: '#ea4c89',
    dropbox: '#007ee5',
    google: '#dc4e41',
    instagram: '#3f729b',
    linkedin: '#0077b5',
    pocket: '#ef4056',
    github: '#333',
    youtube: '#FF0000',
};
export const aliases = {
    white: whiteA.whiteA12,
    marvel: blue.blue11,
    marvelA: blueA.blueA11,
    marvelLight: blue.blue10,
    marvelLighA: blueA.blueA10,
    marvelExtraLight: blue.blue9,
    greenDark: blueDark.blue8,
    green: blueDark.blue7,
    greenLight: blueDark.blue8,
    greenExtraLight: blueDark.blue9,
    green005: blue.blue1,
    green008: blue.blue2,
    snow: slate.slate2,
    snowA: slateA.slateA2,
    snowDark: slate.slate3,
    snowExtraDark: slate.slate4,
    smoke: slate.slate6,
    smokeDark: slate.slate7,
    smokeExtraDark: slate.slate8,
    silver: slate.slate10,
    slate: slate.slate11,
    steel: slate.slate11,
    black: slate.slate11,
    licorice: slate.slate12,
    background: whiteA.whiteA12,
    heading: slate.slate12,
    primary: blueDark.blue7,
    primaryHighlight: blueDark.blue8,
    text: slate.slate11,
    // Make slate11 slightly darker (just in light mode)
    slate11: 'hsl(206 6.0% 32%)',
};
export const dark = Object.assign(Object.assign(Object.assign(Object.assign({ background: 'hsl(206 24.0% 6.0%)' }, amberDark), tomatoDark), grassDark), slateDark);
export const colors = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, legacyMarvelColors), aliases), amber), blackA), blue), crimson), cyan), grass), green), indigo), lime), mint), orange), olive), pink), plum), purple), red), sky), slate), teal), tomato), violet), whiteA), yellow), { dark });
