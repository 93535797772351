import React from 'react';
import { EyeIcon } from '@marvelapp/ballpark-icons';
import { pluralize } from '@marvelapp/core';
import { AspectImage } from '../AspectImage';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { FullscreenModal } from '../FullscreenModal';
import { Progress } from '../Progress';
import { Stack } from '../Stack';
import { Strong } from '../Strong';
function Root(props) {
    return (React.createElement(Stack, Object.assign({ align: "center", className: "w-full", direction: "row", gap: "5" }, props)));
}
function Body(props) {
    return React.createElement(Stack, Object.assign({ className: "flex-1", gap: "3" }, props));
}
function Meta(props) {
    const { infoText, participants, percent, title } = props;
    return (React.createElement(Stack, { align: "baseline", direction: "row", gap: "4", justify: "between", width: "full" },
        React.createElement(BtwHeading, { truncate: true, size: "sm" }, title),
        React.createElement(BtwText, { className: "whitespace-nowrap", size: "13" },
            infoText && (React.createElement(React.Fragment, null,
                React.createElement("span", null, infoText),
                "\u00A0\u00A0\u00B7\u00A0\u00A0")),
            React.createElement("span", null,
                participants,
                " ",
                pluralize(participants, 'participant')),
            "\u00A0\u00A0\u00B7\u00A0\u00A0",
            React.createElement(Strong, null,
                percent,
                "%"))));
}
function ChoiceProgress(props) {
    const { percent, variant } = props;
    return (React.createElement(Progress.AnimatedInView, { rounded: "md", size: "lg", value: percent, variant: getProgressVariant(variant) }));
}
function SummaryChoiseBarBase(props) {
    const { infoText, participants, percent, title, variant = 'default' } = props;
    return (React.createElement(Root, null,
        React.createElement(Body, null,
            React.createElement(Meta, { infoText: infoText, participants: participants, percent: percent, title: title }),
            React.createElement(ChoiceProgress, { percent: percent, variant: variant }))));
}
function WithImage(props) {
    const { alt, image, infoText, participants, percent, title, variant = 'default', } = props;
    return (React.createElement(Root, null,
        image && (React.createElement("div", { className: "w-16" },
            React.createElement(FullscreenModal, { trigger: React.createElement(AspectImage, { src: image, srcSet: `${image} 2x`, alt: alt }) },
                React.createElement(FullscreenModal.Image, { alt: alt, height: "contain", src: image })))),
        React.createElement(Body, null,
            React.createElement(Meta, { infoText: infoText, participants: participants, percent: percent, title: title }),
            React.createElement(ChoiceProgress, { percent: percent, variant: variant }))));
}
function WithTagline(props) {
    const { infoText, participants, percent, tagline, title, variant = 'default', } = props;
    return (React.createElement(Root, null,
        tagline && (React.createElement("div", { className: "w-16" },
            React.createElement(FullscreenModal, { trigger: React.createElement(AspectImage.Container, null,
                    React.createElement(BtwButton, { size: "sm", standaloneIcon: React.createElement(EyeIcon, null) })) },
                React.createElement(Stack, { gap: "1", className: "shadow-sm-longer w-full max-w-md rounded-lg bg-white p-8 ring-1 ring-gray-600/20" },
                    React.createElement(BtwHeading, { className: "px-1", size: "lg" }, tagline.heading),
                    tagline.paragraph)))),
        React.createElement(Body, null,
            React.createElement(Meta, { infoText: infoText, participants: participants, percent: percent, title: title }),
            React.createElement(ChoiceProgress, { percent: percent, variant: variant }))));
}
export const SummaryChoiceBar = Object.assign(SummaryChoiseBarBase, {
    WithImage,
    WithTagline,
});
const getProgressVariant = (variant) => {
    switch (variant) {
        case 'winning':
            return 'green';
        case 'losing':
            return 'red';
        default:
            return 'gray';
    }
};
