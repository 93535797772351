import theme from '@marvelapp/ballpark-theme';
import { hexToRGB } from '@marvelapp/core';
export const underlineColor = {
    slate: theme.colors.snowA,
    marvel: theme.colors.marvelA11,
    red: hexToRGB(theme.colors.red, 0.5),
    white: theme.colors.whiteA6,
    green: hexToRGB(theme.colors.green, 0.5),
    purple: hexToRGB(theme.colors.purple, 0.5),
    yellowDark: hexToRGB(theme.colors.yellowDark, 0.5),
    marvelDark: hexToRGB(theme.colors.marvelDark, 0.5),
};
