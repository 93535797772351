import * as ProgressPrimitive from '@radix-ui/react-progress';
import React from 'react';
import { tv } from 'tailwind-variants';
const root = tv({
    base: ['bg-gray-200', 'rounded-full', 'w-full'],
    variants: {
        size: {
            sm: ['h-1.5'],
        },
        color: {
            gray: '[&>div]:bg-gray-900',
            amber: '[&>div]:bg-amber-400',
            red: '[&>div]:bg-red-500',
            green: '[&>div]:bg-green-500',
        },
    },
    defaultVariants: {
        size: 'sm',
        color: 'gray',
    },
});
const indicator = tv({
    base: ['rounded-l-full', 'h-full'],
});
function ProgressBar(props) {
    const { value, max, className, size, color } = props;
    let percentage;
    if (value && max) {
        percentage = (value / max) * 100;
    }
    return (React.createElement(ProgressPrimitive.Root, { value: value, max: max, className: root({ className, size, color }) },
        React.createElement(ProgressPrimitive.Indicator, { className: indicator(), style: { width: `${percentage}%` } })));
}
export { ProgressBar };
