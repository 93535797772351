import React from 'react';
import { Stack } from '../Stack';
import { cn } from '../utils';
export function ContainerWithSidebar({ children, sidebar, fullWidth = false, }) {
    return (React.createElement(Stack, { direction: "row", width: "full" },
        sidebar,
        React.createElement(Stack, { align: "center", width: "full", 
            // 192px is the Tailwind 48 unit (48*4), which is the width of the sidebar.
            className: "md:width-[calc(100%-192px)] md:ml-48" },
            React.createElement(Stack, { width: "full", className: cn('min-h-screen', 'py-9', 'text-left', 'md:w-[calc(100vw_-_192px)]', fullWidth ? 'items-center' : 'max-w-[2000px] px-12'), align: "stretch", gap: "10" }, children))));
}
