import { useConfig } from '../react';
const PRODUCTION_DOMAIN = 'ballparkhq.com';
export function useIsUnsafeProductionBackend() {
    const config = useConfig();
    return getIsUnsafeProductionBackend(config);
}
export function getIsUnsafeProductionBackend(config) {
    const isProductionBackend = config.pie.host.includes(PRODUCTION_DOMAIN) ||
        config.usertest.realtime.url.includes(PRODUCTION_DOMAIN);
    // TODO: this will need updating if we use SSR we'd need to check the host differently as window
    // won't be available
    return (isProductionBackend && !window.location.hostname.includes(PRODUCTION_DOMAIN));
}
