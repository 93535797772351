import { __rest } from "tslib";
import React from 'react';
import Position from '../Position';
import { InputElement, LabelElement } from './styles';
/** @param {any} */
function Toggle(_a) {
    var { activeColor = 'marvel', disabled = false, display, id, inactiveColor = 'smokeDark', isChecked, size = 60, 'data-testid': testId } = _a, inputProps = __rest(_a, ["activeColor", "disabled", "display", "id", "inactiveColor", "isChecked", "size", 'data-testid']);
    return (React.createElement(Position, { "data-testid": testId, display: display, position: "relative" },
        React.createElement(InputElement, Object.assign({ bg: activeColor, checked: isChecked, disabled: disabled, id: id, type: "checkbox" }, inputProps, { "data-testid": `${testId}-input` })),
        React.createElement(LabelElement, { bg: inactiveColor, "data-testid": `${testId}-label`, disabled: disabled, htmlFor: id, size: size })));
}
export default Toggle;
