import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
const pulse = tv({
    base: [
        'relative',
        'text-gray-400',
        'before:block',
        'before:absolute',
        'before:top-0',
        'before:left-0',
        'before:w-full',
        'before:h-full',
    ],
    variants: {
        size: {
            sm: 'before:animate-attentionSmall',
            base: 'before:animate-attention',
        },
        rounded: {
            md: ['before:rounded-md'],
            full: ['before:rounded-full'],
        },
    },
    defaultVariants: {
        rounded: 'md',
        size: 'base',
    },
});
const BtwPulse = forwardRef(function BtwPulse(props, ref) {
    const { children, className, shouldPulse, size, rounded } = props, rest = __rest(props, ["children", "className", "shouldPulse", "size", "rounded"]);
    if (shouldPulse) {
        return (React.createElement("div", Object.assign({ className: pulse({ className, rounded, size }), ref: ref }, rest), children));
    }
    return React.createElement(React.Fragment, null, children);
});
export { BtwPulse };
