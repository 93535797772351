import React, { useState } from 'react';
import { DashboardTinyUpseller } from '@marvelapp/askhub-upsells';
import { useConfig, useCurrentUser, useLogout, } from '@marvelapp/ballpark-application';
import { Avatar, Dropdown, Heading, Icon, SquareButton, } from '@marvelapp/ballpark-components';
import { DOTS_ICON } from '@marvelapp/ballpark-theme';
import { getRoleDisplayLabel } from '@marvelapp/core';
import styled from '@marvelapp/styled';
import { Box, Flex, Text } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
import { getUserBlockLinks } from './links';
export default function UserBlock() {
    const user = useCurrentUser();
    const { image, username, company, role } = user;
    const [isPopoverActive, setIsPopoverActive] = useState(false);
    return (React.createElement(Container, null,
        React.createElement(Upsell, null,
            React.createElement(DashboardTinyUpseller, null)),
        React.createElement(Footer, null,
            React.createElement(Avatar, { size: "xs", src: image, username: username }),
            React.createElement(Heading, { as: "p", flex: 1, pl: "xs", size: "s", truncated: true }, username),
            React.createElement(Dropdown.Root, { onOpenChange: () => setIsPopoverActive(!isPopoverActive) },
                React.createElement(SquareButton, { as: Dropdown.Trigger, isActive: isPopoverActive, translateOnHover: false, "data-testid": "my-settings" },
                    React.createElement(Icon, { paths: DOTS_ICON })),
                React.createElement(Dropdown.Content, { position: "top" },
                    React.createElement(DropdownContent, { company: company, role: role }))))));
}
function DropdownContent({ role, company }) {
    const logout = useLogout();
    const config = useConfig();
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const links = getUserBlockLinks();
    return (React.createElement(Box, { width: 210 },
        company && (React.createElement(Flex, { alignItems: "center", borderBottom: "1px solid", borderColor: "smoke", padding: 3 },
            React.createElement(Box, null,
                React.createElement(Heading, { as: "h3", fontSize: 14, fontWeight: 500, paddingTop: "1px" }, company.name),
                React.createElement(Text, { fontSize: 2, marginTop: "2px" }, getRoleDisplayLabel(role))))),
        React.createElement(Box, { py: 15 }, links.map((link) => (React.createElement(Dropdown.Item, { "data-testid": link.name.replace(/[\s]+/g, '-').toLowerCase(), as: Link, key: link.name, target: link.target, to: link.to }, link.name)))),
        React.createElement(Box, { borderTop: "1px solid", borderColor: "smoke", py: 10 },
            React.createElement(Dropdown.Item, { forceExternal: true, 
                // we need to prevent accidental double taps, to avoid a backend error
                isDisabled: isLoggingOut, onClick: () => {
                    setIsLoggingOut(true);
                    logout().finally(() => {
                        window.location.href = `${config.ballpark.marketingSite}`;
                    });
                }, target: "_self" }, "Sign out"))));
}
const Container = styled(Box) `
  bottom: 0;
  width: 100%;
`;
const Upsell = styled(Box).attrs({
    paddingBottom: 2,
    px: 3,
}) `
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;
const Footer = styled(Box).attrs({
    py: 's',
    pl: 's',
    pr: 'xs',
}) `
  width: 100%;
  display: flex;
  align-items: center;
`;
