import { __rest } from "tslib";
import React from 'react';
import { kinds } from './kinds';
import { MessageElement } from './styles';
/** @param {any} */
function Message(_a) {
    var { children, kind, borderWidth } = _a, props = __rest(_a, ["children", "kind", "borderWidth"]);
    return (React.createElement(MessageElement, Object.assign({ borderWidth: borderWidth, pl: borderWidth + 15, pt: "2px" }, kinds[kind], props), children));
}
Message.defaultProps = {
    borderWidth: 6,
    kind: 'information',
};
export default Message;
