// TODO: move to @marvelapp/ballpark-application and convert to a hook
import config from '../config';
const KEY = 'accessToken';
const { scopes: requiredScopes } = config.pie;
export function getAuth() {
    const auth = window.localStorage.getItem(KEY);
    if (!auth)
        return false;
    try {
        const { accessToken, expiry, scopes } = JSON.parse(auth);
        // Check it has not expired
        if (Date.now() > expiry) {
            clearAuth();
            return false;
        }
        // Check the token is authenticated for all the scopes we need
        const authenticatedScopes = new Set(scopes);
        const hasAllScopes = requiredScopes.every((scope) => authenticatedScopes.has(scope));
        if (!hasAllScopes) {
            clearAuth();
            return false;
        }
        return accessToken;
    }
    catch (e) {
        // This means it's corrupted and we don't know how to read it,
        // so delete the entry and start over
        clearAuth();
        return false;
    }
}
export function setAuth(accessToken, expiry, scopes) {
    window.localStorage.setItem(KEY, JSON.stringify({
        accessToken,
        expiry,
        scopes,
    }));
}
export function clearAuth() {
    window.localStorage.removeItem(KEY);
}
