import React from 'react';
import { Button } from '@marvelapp/ballpark-components';
import { Image, Link, TinyUpseller } from '@marvelapp/ui';
import upseller from '../../static/images/weekly-webinar-upseller.png';
import upseller2x from '../../static/images/weekly-webinar-upseller@2x.png';
export function WebinarUpsell({ onClickClose }) {
    const WEBINAR_URL = 'https://us02web.zoom.us/webinar/register/WN_avA60CFCSliGrJsYx3br9Q';
    return (React.createElement(TinyUpseller, { mt: "m", title: "Join our weekly webinar to see Ballpark in action", image: React.createElement(Image, { src: upseller, src2x: upseller2x, alt: "Upsell icon", display: "block" }), callToAction: React.createElement(Link, { href: WEBINAR_URL, target: "_blank" },
            React.createElement(Button, { kind: "ghost" }, "Register")), onClickClose: onClickClose }));
}
