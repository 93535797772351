import { withApollo } from '@apollo/client/react/hoc';
import React, { useEffect, useMemo, useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { LastLocationProvider, useLastLocation, } from 'react-router-last-location';
import compose from 'recompose/compose';
import { EnsureBrowserSupported } from '@marvelapp/askhub-ui';
import { FloatingUpsell } from '@marvelapp/askhub-upsells';
import { FeatureFlagsContext, Marvel3Provider, PublicRoute, } from '@marvelapp/ballpark-application';
import { NotFound, UnhandledError } from '@marvelapp/ballpark-components';
import { ModalPortalDestination } from '@marvelapp/ui';
import { BackLinkProvider, CenteredLoader, ModalSwitch, ToastContainer, } from '@marvelapp/ui-internal';
import { routingMap } from '../../routingMap';
import { dangerouslySetupGoogleTranslateObserver } from '../../utils/googleTranslateObserver';
import logout from '../../utils/logout';
import { ProjectArchived, ProjectLocked } from '../PrototypeUnavailable';
import { Sidebar } from '../Sidebar';
dangerouslySetupGoogleTranslateObserver();
// Record page provides is its own localized browser check
const browserCheckIgnorePaths = ['/record'];
function App({ client: apolloClient, accessToken, user, config, pusher, flags, subscription, setSubscription, transactionalKey, }) {
    const lastLocation = useLastLocation();
    const sidebar = useMemo(() => React.createElement(Sidebar, { user: user }), [user]);
    const modalContainerRef = useRef();
    return (React.createElement(EnsureBrowserSupported, { ignoredPaths: browserCheckIgnorePaths },
        React.createElement(React.Suspense, { fallback: React.createElement(CenteredLoader, null) },
            React.createElement(FeatureFlagsContext.Provider, { value: flags },
                React.createElement(Marvel3Provider, { accessToken: accessToken, apolloClient: apolloClient, config: config, featureFlags: flags, lastLocation: lastLocation, logout: logout, pusher: pusher, setSubscription: setSubscription, sidebar: sidebar, subscription: subscription, transactionalKey: transactionalKey, user: user },
                    React.createElement(LastLocationProvider, null,
                        React.createElement(PublicRoute, null, (routeProps) => (React.createElement(BackLinkProvider, null,
                            React.createElement(ModalSwitch, Object.assign({}, routeProps, { containerRef: modalContainerRef }),
                                React.createElement(Redirect, { exact: true, from: "/", to: "/projects" }),
                                React.createElement(PublicRoute, { component: UnhandledError, exact: true, path: "/error" }),
                                React.createElement(PublicRoute, { component: ProjectLocked, exact: true, path: "/prototype-locked" }),
                                React.createElement(PublicRoute, { component: ProjectArchived, exact: true, path: "/prototype-archived" }),
                                routingMap.map((route) => {
                                    const { path, Application, flag } = route;
                                    if (flag && !flags[flag])
                                        return null;
                                    return (React.createElement(PublicRoute, { key: path, path: path, render: ({ history }) => {
                                            return (React.createElement(Application
                                            // TODO refactor all the apps to get these values from
                                            // the useXXX hooks
                                            , { 
                                                // TODO refactor all the apps to get these values from
                                                // the useXXX hooks
                                                accessToken: accessToken, apolloClient: apolloClient, config: config, featureFlags: flags, history: history, lastLocation: lastLocation, pusher: pusher, setSubscription: setSubscription, sidebar: sidebar, subscription: subscription, transactionalKey: transactionalKey, user: user }));
                                        } }));
                                }),
                                React.createElement(PublicRoute, { component: NotFound })))))),
                    React.createElement("div", { ref: modalContainerRef }),
                    React.createElement(ToastContainer, { icon: () => null }),
                    React.createElement(ModalPortalDestination, null),
                    user && subscription && React.createElement(FloatingUpsell, null),
                    React.createElement(IntercomVisibility, null))))));
}
function IntercomVisibility() {
    const { pathname } = useLocation();
    useEffect(() => {
        if (window.Intercom) {
            if (!['/projects', '/archive', '/company', '/templates'].some((path) => pathname.startsWith(path))) {
                window.Intercom('hide');
            }
        }
    }, [pathname]);
    return null;
}
export default compose(withApollo)(App);
