import querystring from 'query-string';
const FASTLY = 'freetls.fastly.net';
export function getFormattedImageUrl(options, fastlyUrl = FASTLY) {
    const { url, dpr = window.devicePixelRatio, width = 300, height, aspectRatio = '1:1', cropParams = ',smart', canvas, bgColor, crop = true, } = options;
    // If not from fastly we can't crop it, so just fallback
    if (!url || url.indexOf(fastlyUrl) < 0)
        return url;
    // Crop and resize using Fastly:
    // Some API images like avatars might come back with already params on the images
    // therefore we don't wanna remove those and just replace some of them.
    const parsed = querystring.parseUrl(url);
    parsed.query.dpr = `${dpr}` || parsed.query.dpr || undefined;
    if (width) {
        parsed.query.width = width;
    }
    if (height) {
        parsed.query.height = height;
    }
    if (crop) {
        parsed.query.crop =
            aspectRatio + cropParams || parsed.query.crop || undefined;
    }
    parsed.query.canvas = canvas;
    parsed.query['bg-color'] = bgColor;
    if (parsed.query.crop && parsed.query.width) {
        // When using crop you can't have a height and width
        parsed.query.height = undefined;
    }
    if (!parsed.query.width || !parsed.query.height) {
        // when using fit you need both height and width
        parsed.query.fit = undefined;
    }
    const queryStringified = querystring.stringify(parsed.query, {
        encode: false,
    });
    return `${parsed.url}?${queryStringified}`;
}
