import { StepClass } from '../common';
export const STEP_PREFIXES = {
    [StepClass.Instruction]: 'step_instruction_',
    [StepClass.BannerAdTest]: 'step_bannerAd_',
    [StepClass.FiveSecondTest]: 'step_fiveSecond_',
    [StepClass.MultipleChoice]: 'step_multipleChoice_',
    [StepClass.PreferenceTest]: 'step_preference_',
    [StepClass.PrototypeTask]: 'step_prototypeTask_',
    [StepClass.Question]: 'step_question_',
    [StepClass.RatingScale]: 'step_rating_',
    [StepClass.TaglineCopyTest]: 'step_tagline_',
    [StepClass.WebsiteTask]: 'step_websiteTask_',
    [StepClass.YesOrNo]: 'step_yesNo_',
    [StepClass.MediaSettings]: 'step_mediaSettings_',
    [StepClass.CardSortingClosed]: 'step_cardSortingClosed_',
    [StepClass.CardSortingHybrid]: 'step_cardSortingHybrid_',
    [StepClass.CardSortingOpen]: 'step_cardSortingOpen_',
};
