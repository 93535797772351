import React, { forwardRef } from 'react';
import { CheckIcon, SettingsIcon } from '@marvelapp/ballpark-icons';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { BtwText } from '../BtwText';
import { Stack } from '../Stack';
import { cn } from '../utils';
function Root(props) {
    return (React.createElement(Stack, { gap: "4", className: "max-w-sm", width: "full" }, props.children));
}
function Box(props) {
    const { access, description, disabled, media, onEnableAccess, settingsPopover, soundTest, title, } = props;
    return (React.createElement(Stack, { gap: "3", width: "full", className: cn('rounded-lg', 'p-4', {
            'bg-gray-200': disabled,
            // Access enabled styles
            'ring-1': !disabled,
            'bg-white': !disabled,
            'ring-gray-600/20': !disabled,
            'shadow-sm-longer': !disabled,
        }) },
        React.createElement(Stack, { width: "full", direction: "row", align: "center", gap: "1" },
            React.createElement(Stack, { gap: "1", width: "full" },
                React.createElement(BtwHeading, { className: "flex items-center gap-1", variant: disabled ? 'secondary' : 'primary', size: "sm" },
                    title,
                    " ",
                    access === 'enabled' && React.createElement(CheckIcon, { className: "h-4 w-4" })),
                React.createElement(BtwText, { size: "xs" }, description)),
            access === 'enabled' && (React.createElement("div", { className: "relative" }, settingsPopover))),
        access === 'enabled' ? (React.createElement(React.Fragment, null, (media || soundTest) && (React.createElement(Stack, { gap: "4", width: "full" },
            media,
            soundTest)))) : (React.createElement(BtwButton, { size: "intermediate", variant: "primary", width: "full", disabled: disabled, onClick: onEnableAccess, className: cn({ '!bg-gray-300': disabled }) }, "Enable access"))));
}
function Media(props) {
    return (React.createElement("div", { className: cn('aspect-video', 'overflow-hidden', 'rounded-md', 
        // Image
        '[&>img]:block', '[&>img]:h-full', '[&>img]:w-full', '[&>img]:object-cover', 
        // Video
        '[&>video]:block', '[&>video]:h-full', '[&>video]:w-full', '[&>video]:object-cover') }, props.children));
}
const SettingsButton = forwardRef(function SettingsButton(props, ref) {
    return (React.createElement(BtwButton, Object.assign({ size: "sm", standaloneIcon: React.createElement(SettingsIcon, null), ref: ref }, props)));
});
export const PermissionsWidgets = {
    Box,
    Media,
    Root,
    SettingsButton,
};
