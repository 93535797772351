export const zIndices = {
    negative: -1,
    base: 0,
    belowSidebar: 90,
    sectionHeader: 100,
    popover: 100,
    createProjectPopover: 110,
    sidebar: 120,
    helpBubble: 200,
    navigationBar: 300,
    actionBar: 300,
    windows: 400,
    modal: 900,
    tooltip: 900,
    devToolBar: 999,
    projectViewModal: 1001,
    record: {
        background: 0,
        content: 1,
        controls: 2,
        modal: 3,
    },
};
