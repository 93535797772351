import { RecordingStatus, StepClass } from '../common';
import { getMediaStatus } from '../media';
import { FlatStepClass } from './flatSteps';
import { isFirstClick } from './prototypeTaskStep';
import { FixedStep, PrototypeStepType, QuestionStepType, YesOrNoStepType, } from './types';
export function setDescription(mutable, value) {
    mutable.description = value;
}
export function setTitle(mutable, value) {
    mutable.title = value;
}
export function setImage(mutable, value) {
    const decorativeImage = { url: value };
    mutable.decorativeImage = decorativeImage;
}
export function removeImage(mutable) {
    delete mutable.decorativeImage;
}
export function removeInstruction(mutable) {
    delete mutable.instruction;
}
export function getInstruction(step) {
    var _a;
    return (_a = step.instruction) !== null && _a !== void 0 ? _a : null;
}
export function getInstructionStatus(step) {
    return getMediaStatus({ mutable: step });
}
export function getReadyInstruction(step) {
    var _a;
    if (((_a = step === null || step === void 0 ? void 0 : step.instruction) === null || _a === void 0 ? void 0 : _a.status) === RecordingStatus.Ready) {
        return {
            url: step.instruction.url,
            mimeType: step.instruction.mimeType,
        };
    }
}
export function isOfType(item, type) {
    return item.className === StepClass[type];
}
export function isOneOfType(item, types) {
    return types.some((type) => isOfType(item, type));
}
export function isRequirable(step) {
    return !isOfType(step, StepClass.Instruction) && !isLegalStep(step);
}
export function isStepWithSubType(step) {
    return 'type' in step;
}
export function isChoiceStep(step) {
    return isOneOfType(step, [
        StepClass.MultipleChoice,
        StepClass.PreferenceTest,
        StepClass.TaglineCopyTest,
    ]);
}
export function isYesNoStep(step) {
    return (isOfType(step, StepClass.YesOrNo) &&
        isOfYesNoSubType(step, YesOrNoStepType.YesNo));
}
export function isLegalStep(step) {
    return (isOfType(step, StepClass.YesOrNo) &&
        isOfYesNoSubType(step, YesOrNoStepType.Legal));
}
export function isGoalBasedPrototype(step) {
    return (isOfType(step, StepClass.PrototypeTask) &&
        isOfPrototypeSubType(step, PrototypeStepType.Prototype));
}
export function isOfPrototypeSubType(step, type) {
    if ('type' in step) {
        return step.type === type;
    }
    // this is a legacy project
    return type === PrototypeStepType.Prototype;
}
export function isOfYesNoSubType(step, type) {
    if ('type' in step) {
        return step.type === type;
    }
    // this is a legacy project
    return type === YesOrNoStepType.YesNo;
}
export function isOfQuestionSubType(step, type) {
    if ('type' in step) {
        return step.type === type;
    }
    // this is a legacy project
    return type === QuestionStepType.Text;
}
export function isNumericalStep(step) {
    return (isOfType(step, StepClass.Question) &&
        isOfQuestionSubType(step, QuestionStepType.Numerical));
}
export function isEmailStep(step) {
    return (isOfType(step, StepClass.Question) &&
        isOfQuestionSubType(step, QuestionStepType.Email));
}
export function isTextStep(step) {
    return (isOfType(step, StepClass.Question) &&
        isOfQuestionSubType(step, QuestionStepType.Text));
}
export function isDateStep(step) {
    return (isOfType(step, StepClass.Question) &&
        isOfQuestionSubType(step, QuestionStepType.QuestionDate));
}
export function getRecordingPermissions(step) {
    var _a;
    return ((_a = step.recordingPermissions) !== null && _a !== void 0 ? _a : {
        microphone: false,
        webcam: false,
        screen: false,
    });
}
export function setMicrophoneRecordingPermission(mutable, microphone) {
    mutable.recordingPermissions = Object.assign(Object.assign({}, getRecordingPermissions(mutable)), { microphone });
}
export function setWebcamRecordingPermission(mutable, webcam) {
    mutable.recordingPermissions = Object.assign(Object.assign({}, getRecordingPermissions(mutable)), { webcam });
}
export function setScreenRecordingPermission(mutable, screen) {
    mutable.recordingPermissions = Object.assign(Object.assign({}, getRecordingPermissions(mutable)), { screen });
}
export function getIsRequired(step) {
    return step.isRequired;
}
export function setIsRequired(mutable, isRequired) {
    mutable.isRequired = isRequired;
}
export function isCardSortingStep(step) {
    return (step.className === StepClass.CardSortingClosed ||
        step.className === StepClass.CardSortingOpen ||
        step.className === StepClass.CardSortingHybrid);
}
export function getFlatStepClass(step) {
    switch (step.className) {
        case StepClass.Instruction:
            return FlatStepClass.Instruction;
        case StepClass.FiveSecondTest:
            return FlatStepClass.FiveSecondTest;
        case StepClass.MultipleChoice:
            return FlatStepClass.MultipleChoice;
        case StepClass.PreferenceTest:
            return FlatStepClass.PreferenceTest;
        case StepClass.PrototypeTask:
            if (isFirstClick(step)) {
                return FlatStepClass.FirstClickStep;
            }
            if (isGoalBasedPrototype(step)) {
                return FlatStepClass.GoalBasedStep;
            }
            return FlatStepClass.FreeRoamStep;
        case StepClass.Question:
            if (isOfQuestionSubType(step, QuestionStepType.QuestionDate)) {
                return FlatStepClass.QuestionDate;
            }
            if (isOfQuestionSubType(step, QuestionStepType.Email)) {
                return FlatStepClass.QuestionEmail;
            }
            if (isOfQuestionSubType(step, QuestionStepType.Numerical)) {
                return FlatStepClass.QuestionNumerical;
            }
            return FlatStepClass.QuestionText;
        case StepClass.RatingScale:
            return FlatStepClass.RatingScale;
        case StepClass.TaglineCopyTest:
            return FlatStepClass.TaglineCopyTest;
        case StepClass.WebsiteTask:
            return FlatStepClass.WebsiteTask;
        case StepClass.YesOrNo:
            if (isLegalStep(step)) {
                return FlatStepClass.LegalStep;
            }
            return FlatStepClass.YesOrNo;
        case StepClass.MediaSettings:
            return FlatStepClass.MediaSettings;
        case StepClass.CardSortingClosed:
            return FlatStepClass.CardSortingClosed;
        case StepClass.CardSortingHybrid:
            return FlatStepClass.CardSortingHybrid;
        case StepClass.CardSortingOpen:
            return FlatStepClass.CardSortingOpen;
        default: {
            // Ensure the switch is exhaustive by marking the step as never
            throw new Error(`Unknown step class: ${step}`);
        }
    }
}
