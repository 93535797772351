import { __rest } from "tslib";
import * as SliderPrimitive from '@radix-ui/react-slider';
import React, { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { cn } from './utils';
const root = tv({
    base: [
        'flex',
        'items-center',
        'relative',
        'touch-none',
        'select-none',
        'h-5',
        'w-full',
        // Hover effects
        '[&>.btw-slider--track]:hover:bg-gray-600/20',
        '[&>span>.btw-slider--thumb]:hover:scale-110',
        // Focus effects
        'focus:[&>span>.btw-slider--thumb]:outline-4',
        'focus:[&>span>.btw-slider--thumb]:ring-gray-600',
        'focus:[&>span>.btw-slider--thumb]:outline-gray-600/10',
    ],
    variants: {},
    defaultVariants: {},
});
const Root = forwardRef(function Root(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(SliderPrimitive.Root, Object.assign({ className: root({ className }), ref: ref }, rest)));
});
/**
 * Slider track
 */
const Track = forwardRef(function Track(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(SliderPrimitive.Track, Object.assign({ className: cn('h-1.5', 'relative', 'flex-grow', 'rounded-full', 'bg-gray-600/10', 'duration-300', 'ease-smooth', 'transition-colors', 
        // Internal className
        'btw-slider--track', 
        // Additional classes
        className), ref: ref }, rest)));
});
/**
 * Slider range
 */
const Range = forwardRef(function Range(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(SliderPrimitive.Range, Object.assign({ className: cn('h-full', 'absolute', 'bg-gray-900', 'rounded-full', 
        // Internal className
        'btw-slider--range', 
        // Additional classes
        className), ref: ref }, rest)));
});
/**
 * Slider thumb
 */
const Thumb = forwardRef(function Thumb(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(SliderPrimitive.Thumb, Object.assign({ className: cn('w-5', 'h-5', 'block', 'ring-1', 'outline-none', 'outline-offset-0', 'bg-white', 'shadow-sm', 'rounded-full', 'ring-gray-600/10', 'ease-smooth', 'duration-300', 'transition-combined', 'will-change-transform', 
        // Internal className
        'btw-slider--thumb', 
        // Additional classes
        className), ref: ref }, rest)));
});
/**
 * Quick use component
 */
function SliderBase(props) {
    return (React.createElement(Root, Object.assign({}, props),
        React.createElement(Track, null,
            React.createElement(Range, null)),
        React.createElement(Thumb, null)));
}
const Slider = Object.assign(SliderBase, {
    Root,
    Track,
    Range,
    Thumb,
});
export { Slider };
