import { fetchApi } from '@marvelapp/core';
import CONFIG from '../config';
import { clearAuth, getAuth } from './auth';
const accessToken = getAuth();
export default function logout() {
    return fetchApi(`/api/logout/`, {
        method: 'GET',
        credentials: 'include',
        accessToken,
    }).finally(() => {
        clearAuth();
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    });
}
