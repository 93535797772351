import React from 'react';
import { CLOSE_ICON } from '@marvelapp/ballpark-theme';
import { Box } from '@marvelapp/ui';
import { Button, CornerButton } from './Buttons';
import { Heading } from './Heading';
import { Icon } from './Icon';
import { ModalPortal } from './ModalPortal';
import { Text } from './Text';
export const ConfirmDialog = function ConfirmDialog({ onConfirmAction, description, heading, onCloseDialog, }) {
    return (React.createElement(ModalPortal, null,
        React.createElement(Box, { position: "absolute", left: "0", top: "0", width: "100%", height: "100%" },
            React.createElement(Box, { "data-testid": "confirm-dialog", position: "absolute", top: "40%", left: "40%", bg: "background", borderRadius: "xl", boxShadow: "popover.default", padding: ['m', 'm', 'l', 'xl'], maxWidth: "420px", minWidth: "290px", "text-align": "center", zIndex: "modal" },
                React.createElement(CornerButton, { icon: React.createElement(Icon, { paths: CLOSE_ICON }), kind: "negative", testId: "close-button", onClick: onCloseDialog }),
                React.createElement(Heading, { "data-testid": "dialog-heading", fontWeight: "bold" }, heading),
                React.createElement(Text, { "data-testid": "dialog-description", size: "m", py: 3 }, description),
                React.createElement(Button, { "data-testid": "confirm-cta", kind: "negative", onClick: onConfirmAction }, "Confirm")))));
};
