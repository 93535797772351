import { __rest } from "tslib";
import React from 'react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import { Link as MarvelLink } from '@marvelapp/ui';
export default function Link(props) {
    const { to, activeClassName } = props, restProps = __rest(props, ["to", "activeClassName"]);
    const isExternal = /^https?:\/\//.test(to);
    const isEmailAddress = /^mailto:/.test(to);
    if (isExternal || !to) {
        restProps.tag = 'a';
        restProps.href = restProps.href || to;
        restProps.target = restProps.target || '_blank';
    }
    else if (isEmailAddress) {
        restProps.tag = 'a';
        restProps.href = to;
    }
    else if (activeClassName) {
        restProps.tag = RouterNavLink;
        restProps.to = to;
        restProps.activeClassName = activeClassName;
    }
    else {
        restProps.tag = RouterLink;
        restProps.to = to;
        // I don't quite understand why this is needed
        // It seems that RouterLink doesn't call history.pushState
        // when target is defined
        if (restProps.target === '_self') {
            delete restProps.target;
        }
    }
    return React.createElement(MarvelLink, Object.assign({}, restProps));
}
