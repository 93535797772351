import { __rest } from "tslib";
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import React from 'react';
import { tv } from 'tailwind-variants';
import { BtwTooltip } from './BtwTooltip';
import { cn } from './utils';
/**
 * Avatar root
 */
const root = tv({
    base: ['relative', 'flex', 'shrink-0', 'rounded-full', 'overflow-hidden'],
    variants: {
        size: {
            xs: ['h-4', 'w-4', '[&>span]:text-[7px]'],
            sm: ['h-5', 'w-5', '[&>span]:text-[9px]'],
            base: ['h-6', 'w-6', '[&>span]:text-xxs'],
            lg: ['h-7', 'w-7', '[&>span]:text-xs'],
            xl: ['h-10', 'w-10', '[&>span]:text-sm'],
            '2xl': ['h-12', 'w-12', '[&>span]:text-base'],
            '3xl': ['h-16', 'w-16', '[&>span]:text-xl'],
        },
    },
    defaultVariants: {
        size: 'base',
    },
});
const Root = React.forwardRef(function Root(props, ref) {
    const { className, size } = props, rest = __rest(props, ["className", "size"]);
    return (React.createElement(AvatarPrimitive.Root, Object.assign({ ref: ref, className: root({ size, className }) }, rest)));
});
Root.displayName = AvatarPrimitive.Root.displayName;
/**
 * Avatar image
 */
const Image = React.forwardRef(function Avatar(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(AvatarPrimitive.Image, Object.assign({ ref: ref, className: cn('aspect-square h-full w-full object-cover', className) }, rest)));
});
Image.displayName = AvatarPrimitive.Image.displayName;
/**
 * Avatar fallback
 */
const fallback = tv({
    base: [
        'flex',
        'h-full',
        'w-full',
        'items-center',
        'justify-center',
        'rounded-full',
        'font-semibold',
    ],
    variants: {
        variant: {
            gray: ['bg-gray-300/50', 'text-gray-500'],
            red: ['bg-red-300', 'text-red-900'],
            orange: ['bg-orange-300', 'text-orange-900'],
            yellow: ['bg-yellow-300', 'text-yellow-900'],
            green: ['bg-green-300', 'text-green-900'],
            cyan: ['bg-cyan-300', 'text-cyan-900'],
            blue: ['bg-blue-300', 'text-blue-900'],
            purple: ['bg-purple-300', 'text-purple-900'],
            pink: ['bg-pink-300', 'text-pink-900'],
        },
    },
    defaultVariants: {
        variant: 'gray',
    },
});
const Fallback = React.forwardRef(function Avatar(props, ref) {
    const { className, variant } = props, rest = __rest(props, ["className", "variant"]);
    return (React.createElement(AvatarPrimitive.Fallback, Object.assign({ ref: ref, className: fallback({ variant, className }) }, rest)));
});
Fallback.displayName = AvatarPrimitive.Fallback.displayName;
function BtwAvatarBase(props) {
    const { name, src, size } = props;
    return (React.createElement(BtwTooltip, { content: name },
        React.createElement(BtwAvatar.Root, { size: size },
            React.createElement(BtwAvatar.Image, { src: src }),
            React.createElement(BtwAvatar.Fallback, { delayMs: 600 }, name.charAt(0)))));
}
const BtwAvatar = Object.assign(BtwAvatarBase, {
    Root,
    Image,
    Fallback,
});
export { BtwAvatar };
