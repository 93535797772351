import { __rest } from "tslib";
import React from 'react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import { Link as MarvelLink } from '@marvelapp/ui';
export const kinds = {
    primary: {
        color: 'blue11',
        hoverColor: 'blue12',
        hoverTextDecorationColor: 'blue12',
        textDecorationColor: 'blue11',
    },
    white: {
        color: 'whiteA12',
        hoverColor: 'whiteA10',
        hoverTextDecorationColor: 'whiteA10',
        textDecorationColor: 'whiteA10',
    },
    licorice: {
        color: 'slate12',
        hoverColor: 'slate11',
    },
    popover: {
        color: 'slate12',
        display: 'block',
        fontSize: 's',
        hoverBg: 'slate2',
        hoverColor: 'slate12',
        px: 20,
        py: 1,
    },
    ballpark: {
        color: 'tomato12',
        hoverColor: 'tomato11',
    },
    sidebar: {
        color: 'slate',
        hoverColor: 'licorice',
    },
    destructive: {
        color: 'slate',
        hoverColor: 'red',
    },
};
/** @deprecated Use <BtwLink /> instead */
export function Link(_a) {
    var { children, kind = 'primary', underline, activeClassName } = _a, rest = __rest(_a, ["children", "kind", "underline", "activeClassName"]);
    const underlineProps = underline && {
        textDecorationLine: 'underline !important',
        textUnderlineOffset: '2px !important',
        textDecorationThickness: '1px !important',
    };
    return (React.createElement(BaseLink, Object.assign({ activeClassName: activeClassName }, rest, underlineProps, kinds[kind]), children));
}
function BaseLink(props) {
    const { to, activeClassName } = props, restProps = __rest(props, ["to", "activeClassName"]);
    const isExternal = typeof to === 'string' && /^https?:\/\//.test(to);
    const isEmailAddressOrBlob = typeof to === 'string' && /^(mailto:|blob:)/.test(to);
    if (isExternal || !to) {
        restProps.tag = 'a';
        restProps.href = restProps.href || to;
        restProps.target = restProps.target || '_blank';
    }
    else if (isEmailAddressOrBlob) {
        restProps.tag = 'a';
        restProps.href = to;
    }
    else if (activeClassName) {
        restProps.tag = RouterNavLink;
        restProps.to = to;
        restProps.activeClassName = activeClassName;
    }
    else {
        restProps.tag = RouterLink;
        restProps.to = to;
        // I don't quite understand why this is needed
        // It seems that RouterLink doesn't call history.pushState
        // when target is defined
        if (restProps.target === '_self') {
            delete restProps.target;
        }
    }
    return React.createElement(MarvelLink, Object.assign({}, restProps));
}
