import { __rest } from "tslib";
import { Command } from 'cmdk';
import React, { createContext, useContext, useState, } from 'react';
import { CheckIcon, ChevronBottomIcon, SearchIcon, } from '@marvelapp/ballpark-icons';
import { useLockBodyScroll } from '@marvelapp/hooks';
import { BtwButton } from './BtwButton';
import { BtwCheckbox } from './BtwCheckbox';
import { BtwInput } from './BtwInput';
import { BtwPopover } from './BtwPopover';
import { BtwSkeleton } from './BtwSkeleton';
import { BtwText } from './BtwText';
import { Stack } from './Stack';
import { cn } from './utils';
const ComboboxContext = createContext(undefined);
const useComboboxState = () => {
    const comboboxState = useContext(ComboboxContext);
    if (comboboxState === undefined) {
        throw new Error('useComboboxState used outside of Provider');
    }
    return comboboxState;
};
/**
 * Root
 */
function Root(props) {
    const { children, variant } = props, rest = __rest(props, ["children", "variant"]);
    const [open, onOpenChange] = useState(false);
    return (React.createElement(BtwPopover.Root, Object.assign({ open: open, onOpenChange: onOpenChange }, rest),
        React.createElement(ComboboxContext.Provider, { value: Object.assign({ open,
                onOpenChange }, (variant === 'multi-select' || variant === 'multi-select-with-cta'
                ? {
                    variant,
                    onClear: props.onClear,
                    selected: props.selected,
                    total: props.total,
                }
                : { variant })) }, children)));
}
function Trigger(props) {
    const { children, className, hasPlaceholder, truncate = true } = props, rest = __rest(props, ["children", "className", "hasPlaceholder", "truncate"]);
    return (React.createElement(BtwPopover.Trigger, { asChild: true },
        React.createElement(BtwButton, Object.assign({ className: cn('justify-between', '[&>:not(.inner-icon)]:w-full', '[&>:not(.inner-icon)]:text-left', {
                'font-normal text-gray-500': hasPlaceholder,
            }, className), trailingIcon: React.createElement(ChevronBottomIcon, null) }, rest),
            React.createElement("span", { className: cn({ 'min-w-0 truncate': truncate }) }, children))));
}
/**
 * Separator
 */
function Separator(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(Command.Separator, Object.assign({ className: cn('h-px', 'w-full', 'bg-gray-200', className) }, rest)));
}
function Item(props) {
    const { check = 'icon', children, onSelect, selected, disabled } = props, rest = __rest(props, ["check", "children", "onSelect", "selected", "disabled"]);
    const { onOpenChange, variant } = useComboboxState();
    return (React.createElement(BtwButton, { asChild: true, className: cn('font-13', 'h-auto', 'justify-start', 'py-2', 'pl-3', 'pr-3', 'text-left', 'font-medium', '[&>.inner-icon]:ml-auto', '[&>.inner-icon]:flex-none', {
            'cursor-not-allowed': disabled,
        }), variant: "ghost", width: "full", trailingIcon: selected && check === 'icon' ? React.createElement(CheckIcon, null) : undefined },
        React.createElement(Command.Item, Object.assign({ "data-disabled": disabled, onSelect: (value) => {
                // Handle disabling the onSelect event manually because cmdk does not trigger the mouse exit event
                // when hovering from an item to a disabled item, leaving the previous item appearing as hovered.
                if (disabled) {
                    return;
                }
                if (onSelect) {
                    onSelect(value);
                }
                if (variant === 'single-select') {
                    onOpenChange(false);
                }
            } }, rest),
            check === 'checkbox' && (React.createElement("div", { 
                // We should potentially handle this spacing alignment on <Button /> component
                // and make it per size, but it seems fine for now since we only have this button size
                // that uses a checkbox inside
                className: "-ml-0.5" },
                React.createElement(BtwCheckbox, { disabled: disabled, checked: selected, 
                    // The selection logic is handled by the parent <Command.Item /> component
                    size: "sm" }))),
            React.createElement("span", { className: cn('flex-1 truncate', {
                    'opacity-50': disabled,
                }) }, children))));
}
/**
 * Content
 */
const CONTENT_HEADER_HEIGHT = 60;
const CONTENT_FOOTER_HEIGHT = 48;
const CONTENT_CTA_FOOTER_HEIGHT = 56;
function Content(props) {
    const { children, className, filter, modal = false, placeholder = 'Search for options…', width = 'trigger', listRef, headerInput = (React.createElement(BtwInput.Field, { asChild: true, id: "search" },
        React.createElement(Command.Input, { placeholder: placeholder }))), onCloseAutoFocus } = props, rest = __rest(props, ["children", "className", "filter", "modal", "placeholder", "width", "listRef", "headerInput", "onCloseAutoFocus"]);
    const comboboxState = useComboboxState();
    return (React.createElement(BtwPopover.Content, { className: className, "data-testid": "combobox-list-content", width: width, onCloseAutoFocus: onCloseAutoFocus, asChild: true },
        React.createElement(Command, Object.assign({ filter: filter }, rest),
            modal && React.createElement(BodyLock, null),
            comboboxState.variant !== 'popover' && (React.createElement("header", { className: "shadow-sm-bottom relative z-10 px-2.5" },
                React.createElement(Stack, { justify: "center", style: { height: CONTENT_HEADER_HEIGHT } },
                    React.createElement(BtwInput.Root, { className: "w-full", leadingIcon: React.createElement(SearchIcon, null) }, headerInput)))),
            React.createElement(Command.List, { "data-testid": "list-dropdown", className: cn('p-1.5', 'overflow-auto', '[&>div]:flex', '[&>div]:w-full', '[&>div]:flex-col', '[&>div]:gap-0.5'), style: {
                    // We only show the footer for multi-select comboboxes, so we need to
                    // take this into account for the overall max height of the popover
                    maxHeight: getComboboxContentHeight(comboboxState.variant),
                }, ref: listRef }, children),
            (comboboxState.variant === 'multi-select' ||
                comboboxState.variant === 'multi-select-with-cta') && (React.createElement(Stack, { direction: "row", justify: "between", align: "center", asChild: true },
                React.createElement("div", { className: "shadow-sm-top pl-[18px] pr-2.5", style: { height: CONTENT_FOOTER_HEIGHT } },
                    React.createElement(BtwText, { className: "text-gray-500", "data-testid": "selected-options-count", size: "13", weight: "medium" },
                        comboboxState.selected,
                        " of ",
                        comboboxState.total,
                        " selected"),
                    React.createElement(BtwButton, { "data-testid": "clear-selection-combobox-content", disabled: comboboxState.selected === 0, onClick: comboboxState.onClear, size: "sm", variant: "ghost" }, "Clear selection")))),
            (comboboxState.variant === 'multi-select-with-cta' ||
                comboboxState.variant === 'popover') && (React.createElement("div", { className: "shadow-sm-top w-full bg-white p-3", style: { height: CONTENT_CTA_FOOTER_HEIGHT } }, props.cta)))));
}
function LoadingState(props) {
    const { itemCount = 3, hasCheckboxes = false } = props;
    const elements = Array(itemCount).fill(null);
    return (React.createElement(React.Fragment, null, elements.map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    React.createElement(Command.Loading, { key: index },
        React.createElement(Stack, { direction: "row", gap: "2", align: "center", className: cn('h-9', 'py-2', 'pl-2.5', 'pr-3', 'animate-pulse'), width: "full" },
            hasCheckboxes && React.createElement(BtwSkeleton, { className: "size-[18px] rounded" }),
            React.createElement(BtwSkeleton, { className: "h-3 rounded", style: {
                    // Making the width random between 200 and 80 px, which is visually a good range
                    width: Math.floor(Math.random() * (200 - 80)) + 80,
                } })))))));
}
function BodyLock() {
    useLockBodyScroll();
    return null;
}
const getComboboxContentHeight = (variant) => {
    switch (variant) {
        case 'single-select':
            return `calc(var(--radix-popover-content-available-height) - ${CONTENT_HEADER_HEIGHT}px)`;
        case 'multi-select':
            return `calc(var(--radix-popover-content-available-height) - ${CONTENT_HEADER_HEIGHT}px - ${CONTENT_FOOTER_HEIGHT}px)`;
        case 'multi-select-with-cta':
            return `calc(var(--radix-popover-content-available-height) - ${CONTENT_HEADER_HEIGHT}px - ${CONTENT_FOOTER_HEIGHT}px - ${CONTENT_CTA_FOOTER_HEIGHT}px)`;
        case 'popover':
        default:
            return 'var(--radix-popover-content-available-height)';
    }
};
/**
 * Empty state
 */
function Empty(props) {
    const { className, icon, children = React.createElement(React.Fragment, null, "No matches found\u2026") } = props, rest = __rest(props, ["className", "icon", "children"]);
    return (React.createElement(BtwText, { align: "center", asChild: true, className: cn('p-4', {
            'flex flex-col items-center justify-center gap-2': Boolean(icon),
        }, className), size: "sm" },
        React.createElement(Command.Empty, Object.assign({}, rest), children)));
}
/**
 * Empty state
 */
function Group(props) {
    return (React.createElement(Command.Group, Object.assign({ className: cn(
        // Groups
        'flex', '[&[hidden]]:hidden', 'gap-0.5', 'flex-col', 'font-body', 'pt-2', '[&:not(:first-child)]:pt-4', '[&:not(:last-child)]:pb-2.5', '[&:not(:last-child)]:border-b', '[&:not(:last-child)]:border-gray-600/10', 
        // Group items
        '[&>_[cmdk-group-items]]:flex', '[&>_[cmdk-group-items]]:gap-0.5', '[&>_[cmdk-group-items]]:flex-col', 
        // Group headings
        '[&>_[cmdk-group-heading]]:font-body', '[&>_[cmdk-group-heading]]:font-bold', '[&>_[cmdk-group-heading]]:uppercase', '[&>_[cmdk-group-heading]]:px-3', '[&>_[cmdk-group-heading]]:pb-1', '[&>_[cmdk-group-heading]]:text-gray-500', '[&>_[cmdk-group-heading]]:text-xxs') }, props)));
}
const Combobox = {
    Content,
    Empty,
    Group,
    Item,
    Root,
    Separator,
    Trigger,
    LoadingState,
};
export { Combobox };
