import { __rest } from "tslib";
import React from 'react';
import { CARET_ICON } from '@marvelapp/ballpark-theme';
import Icon from '../Icon';
import Loader from '../Loader';
import Position from '../Position';
import kinds from './kinds';
import { loaderSizes, selectSizes } from './sizes';
import { Arrow, SelectElement } from './styles';
/** @param {any} */
function Select(_a) {
    var { children, defaultValue, disabled, iconColor = 'silver', iconSize, isLoading, kind, placeholder, showPlaceholder, size } = _a, props = __rest(_a, ["children", "defaultValue", "disabled", "iconColor", "iconSize", "isLoading", "kind", "placeholder", "showPlaceholder", "size"]);
    return (React.createElement(Position, { position: "relative" },
        React.createElement(SelectElement, Object.assign({ as: "select", defaultValue: defaultValue, disabled: disabled, pointerEvents: disabled ? 'none' : 'all', showPlaceholder: showPlaceholder }, selectSizes[size], kinds[kind], props),
            defaultValue && (React.createElement("option", { "data-testid": "select-placeholder", disabled: true, selected: true, value: defaultValue }, defaultValue)),
            children),
        React.createElement(Arrow, { color: iconColor, height: iconSize || selectSizes[size].height, width: iconSize || selectSizes[size].height }, isLoading ? (React.createElement(Loader, Object.assign({}, loaderSizes[size]))) : (React.createElement(Icon, Object.assign({ paths: CARET_ICON }, loaderSizes[size]))))));
}
/** @type {any} */
Select.defaultProps = {
    bg: 'white',
    border: 'none',
    disabled: false,
    kind: 'ghost',
    size: 1,
    width: 1,
};
export default Select;
