import { __rest } from "tslib";
import React, { useState } from 'react';
import { DotsIcon, PlusIcon, RecordIcon } from '@marvelapp/ballpark-icons';
import { FlatStepClass } from '@marvelapp/user-test-creator';
import { BtwButton } from '../BtwButton';
import { BtwHeading } from '../BtwHeading';
import { Stack } from '../Stack';
import { StepCard } from '../StepCard';
import { FlatFixedStepClass } from '../stepUtils';
import { cn } from '../utils';
import { AddStepSidebar } from './AddStepSidebar';
import { BuilderScrollArea } from './BuilderScrollArea';
// TODO: The above is just an example for storybook
function BuilderSidebar() {
    const [isStepSidebarOpen, setIsStepSidebarOpen] = useState(false);
    return (React.createElement(Sidebar.Root, { preScrollAreaComponent: React.createElement(Sidebar.AbsoluteRight, null,
            React.createElement(AddStepSidebar, { isOpen: isStepSidebarOpen, setIsOpen: setIsStepSidebarOpen })) },
        React.createElement(Sidebar.Header, null,
            React.createElement(BtwButton, { leadingIcon: React.createElement(RecordIcon, null), size: "sm", variant: "secondary" }, "Record"),
            React.createElement(BtwButton, { className: "flex-1", leadingIcon: React.createElement(PlusIcon, null), size: "sm", variant: "primary", onClick: () => setIsStepSidebarOpen(!isStepSidebarOpen) }, "Add step")),
        React.createElement(Sidebar.Section, null,
            React.createElement(Sidebar.Title, { title: "Start" }),
            React.createElement(Sidebar.Cards, null,
                React.createElement(StepCard, { type: FlatFixedStepClass.Welcome, title: "Welcome! We're testing our new holiday booking app and would love your help" }))),
        React.createElement(Sidebar.Section, null,
            React.createElement(Sidebar.Title, { title: "Fixed" }),
            React.createElement(Sidebar.Cards, null,
                React.createElement(StepCard, { type: FlatStepClass.Instruction, title: "You're about to see an interactive example of our new app to test" }),
                React.createElement(StepCard, { type: FlatStepClass.Instruction, title: "You're about to see an interactive example of our new app to test" }),
                React.createElement(StepCard, { type: FlatStepClass.Instruction, title: "You're about to see an interactive example of our new app to test" }),
                React.createElement(StepCard, { type: FlatStepClass.Instruction, title: "You're about to see an interactive example of our new app to test" }))),
        React.createElement(Sidebar.Section, null,
            React.createElement(Sidebar.Title, { title: "Survey" }),
            React.createElement(Sidebar.Cards, null,
                React.createElement(StepCard, { type: FlatStepClass.MultipleChoice, title: "First up, how often do you book holidays online?" }),
                React.createElement(StepCard, { type: FlatStepClass.YesOrNo, title: "Have you ever booked a holiday on your phone?" }),
                React.createElement(StepCard, { type: FlatStepClass.YesOrNo, title: "Sort the cards" }))),
        React.createElement(Sidebar.Section, null,
            React.createElement(Sidebar.Title, { title: "Exit" }),
            React.createElement(Sidebar.Cards, null,
                React.createElement(StepCard, { type: FlatFixedStepClass.Exit, title: "Thank you for your feedback!" })))));
}
function Root(props) {
    const { className, children, preScrollAreaComponent } = props;
    return (React.createElement("aside", { className: cn('z-20', 'relative', 'w-screen', 'max-w-[230px]', className) },
        preScrollAreaComponent,
        React.createElement(BuilderScrollArea, { className: "shadow-sm-right z-20 bg-white" },
            React.createElement("div", { className: "p-2.5" }, children))));
}
function Header(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(Stack, Object.assign({ className: cn('z-10', 'sticky', 'top-2.5', 'py-2', 'px-2.5', 
        // Subtle gradient on scroll
        'before:absolute', 'before:-top-2.5', 'before:left-0', 'before:right-0', 'before:block', 'before:h-6', 'before:from-white', 'before:to-transparent', 'before:bg-gradient-to-b', className), gap: "2.5", direction: "row", width: "full" }, rest)));
}
function Section(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(Stack, Object.assign({ gap: "1.5", className: cn('group', 'px-2.5', 'pb-2.5', 'pt-1.5', 'rounded-lg', 'hover:bg-gray-100', 'duration-300', 'ease-smooth', 'transition-color', className) }, rest)));
}
function Title(props) {
    const { title } = props;
    return (React.createElement(Stack, { align: "center", direction: "row", gap: "2.5", justify: "between", width: "full" },
        React.createElement(BtwHeading, { size: "13", truncate: true }, title),
        React.createElement(BtwButton, { className: "opacity-0 group-hover:opacity-100", size: "sm", variant: "ghost", standaloneIcon: React.createElement(DotsIcon, null) })));
}
function Cards(props) {
    return React.createElement(Stack, Object.assign({ gap: "2.5", width: "full" }, props));
}
function AbsoluteRight(props) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement("div", Object.assign({ className: cn(className, 'absolute', 'bottom-0', 'bottom-0', 'left-[230px]', 'top-0', 'z-0') }, rest)));
}
const Sidebar = {
    AbsoluteRight,
    Cards,
    Header,
    Root,
    Section,
    Title,
};
export { BuilderSidebar };
