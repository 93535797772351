import React from 'react';
import { AsteriskIcon, BranchesIcon, MicrophoneDisabledIcon, MicrophoneIcon, ScreenDisabledIcon, ScreenIcon, VideoIcon, WebcamDisabledIcon, WebcamIcon, } from '@marvelapp/ballpark-icons';
import { MediaUtils } from '@marvelapp/user-test-creator';
import { BtwTooltip } from './BtwTooltip';
import { Stack } from './Stack';
import { cn } from './utils';
const ICON_CLASSNAME = 'h-3 w-3 [&>path]:stroke-1';
var StepSettingsIconStatus;
(function (StepSettingsIconStatus) {
    StepSettingsIconStatus["Disabled"] = "Disabled";
    StepSettingsIconStatus["Enabled"] = "Enabled";
    StepSettingsIconStatus["Failed"] = "Failed";
})(StepSettingsIconStatus || (StepSettingsIconStatus = {}));
// This component is used in a few places, some of which don't use
// context yet. Hence why we still need to use props if `step` is not available
function StepSettingsIcons({ videoGuideStatus, recordingSettings, isStepRequired, hasConditions, }) {
    const { conditionalLogic, screen, microphone, webcam, guides, required } = getSlideRowIcons({
        videoGuideStatus,
        recordingSettings,
        isStepRequired,
        hasConditions,
    });
    return (React.createElement(Stack, { direction: "row", gap: "1" },
        React.createElement(ConditionalLogic, { status: conditionalLogic }),
        React.createElement(ScreenRecording, { status: screen }),
        React.createElement(Microphone, { status: microphone }),
        React.createElement(Webcam, { status: webcam }),
        React.createElement(Guides, { status: guides }),
        React.createElement(Required, { status: required })));
}
function ConditionalLogic({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "This step has conditional logic rules defined" },
                React.createElement(BranchesIcon, { className: ICON_CLASSNAME })));
        // For the conditional logic icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
}
function ScreenRecording({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Screen recording enabled" },
                React.createElement(ScreenIcon, { "data-testid": "screen-icon", className: ICON_CLASSNAME })));
        case StepSettingsIconStatus.Disabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Screen recording disabled" },
                React.createElement(ScreenDisabledIcon, { className: ICON_CLASSNAME })));
        case StepSettingsIconStatus.Failed:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Screen recording upload failed" },
                React.createElement(ScreenDisabledIcon, { className: ICON_CLASSNAME })));
        default:
            return null;
    }
}
function Microphone({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Microphone enabled" },
                React.createElement(MicrophoneIcon, { "data-testid": "mic-icon", className: ICON_CLASSNAME })));
        case StepSettingsIconStatus.Disabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Microphone disabled" },
                React.createElement(MicrophoneDisabledIcon, { className: ICON_CLASSNAME })));
        case StepSettingsIconStatus.Failed:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Microphone recording upload failed" },
                React.createElement(MicrophoneDisabledIcon, { className: ICON_CLASSNAME })));
        default:
            return null;
    }
}
function Webcam({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Webcam enabled" },
                React.createElement(WebcamIcon, { "data-testid": "webcam-icon", className: ICON_CLASSNAME })));
        case StepSettingsIconStatus.Disabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Webcam disabled" },
                React.createElement(WebcamDisabledIcon, { "data-testid": "webcam-icon", className: ICON_CLASSNAME })));
        case StepSettingsIconStatus.Failed:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Webcam disabled" },
                React.createElement(WebcamDisabledIcon, { "data-testid": "webcam-icon", className: ICON_CLASSNAME })));
        default:
            return null;
    }
}
function Guides({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Video guide enabled" },
                React.createElement(VideoIcon, { className: ICON_CLASSNAME })));
        // For the video guides icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
}
function Required({ status }) {
    switch (status) {
        case StepSettingsIconStatus.Enabled:
            return (React.createElement(BtwTooltip, { disableHoverableContent: true, asChild: false, content: "Required step" },
                React.createElement(AsteriskIcon, { "data-testid": "required-icon", className: cn(ICON_CLASSNAME, 'text-red-700') })));
        // For the required icon we don't have use cases for these other states yet
        case StepSettingsIconStatus.Disabled:
        case StepSettingsIconStatus.Failed:
        default:
            return null;
    }
}
function getSlideRowIcons({ hasConditions, videoGuideStatus, recordingSettings = {
    microphone: false,
    screen: false,
    webcam: false,
}, isStepRequired, }) {
    const { microphone: isMicEnabled, screen: isScreenEnabled, webcam: isWebcamEnabled, } = recordingSettings;
    return {
        required: isStepRequired ? StepSettingsIconStatus.Enabled : undefined,
        guides: videoGuideStatus === MediaUtils.RecordingStatus.Ready
            ? StepSettingsIconStatus.Enabled
            : undefined,
        microphone: isMicEnabled ? StepSettingsIconStatus.Enabled : undefined,
        screen: isScreenEnabled ? StepSettingsIconStatus.Enabled : undefined,
        webcam: isWebcamEnabled ? StepSettingsIconStatus.Enabled : undefined,
        conditionalLogic: hasConditions
            ? StepSettingsIconStatus.Enabled
            : undefined,
    };
}
export { StepSettingsIcons };
