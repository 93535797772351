import { __rest } from "tslib";
import React from 'react';
import { CheckIcon, FaceHappyIcon, FaceNeutralIcon, FaceSadIcon, WarningIcon, } from '@marvelapp/ballpark-icons';
import { BtwHeading } from './BtwHeading';
import { BtwLink } from './BtwLink';
import { BtwMessage } from './BtwMessage';
import { BtwText } from './BtwText';
import { Logo } from './Logo';
import { Stack } from './Stack';
function Container(props) {
    const { children, noFooter, maxWidth = 384 } = props;
    return (React.createElement(Stack, { align: "center", className: "min-h-screen px-6 py-10 sm:py-12", gap: { initial: '12', sm: '16' }, justify: "between", width: "full" },
        React.createElement(BtwLink, { href: "/", className: "[&>svg]:h-7 [&>svg]:w-auto", underline: false, variant: "secondary" },
            React.createElement(Logo, null)),
        React.createElement(Stack, { align: "center", gap: { initial: '12', sm: '16' }, width: "full", style: { maxWidth } }, children),
        noFooter ? React.createElement("div", null) : React.createElement(Footer, null)));
}
function Header(props) {
    const { description, descriptionMaxWidth, heading, headingMaxWidth, headingTestId, } = props;
    return (React.createElement(Stack, { align: "center", gap: "1.5" },
        React.createElement(BtwHeading, { asChild: true, "data-testid": headingTestId, size: "xl", style: { maxWidth: headingMaxWidth } },
            React.createElement("h1", null, heading)),
        description && (React.createElement(BtwText, { size: "sm", leading: "relaxed", style: { maxWidth: descriptionMaxWidth } }, description))));
}
function Body(props) {
    return React.createElement("div", Object.assign({ className: "flex w-full max-w-sm flex-col gap-8" }, props));
}
function Form(props) {
    return React.createElement("form", Object.assign({ className: "flex w-full max-w-sm flex-col gap-6" }, props));
}
function Footer(props) {
    const { children } = props;
    return (React.createElement(BtwText, { align: "center", className: "w-full max-w-sm", leading: "relaxed", size: "sm" }, children || (React.createElement(React.Fragment, null,
        "By proceeding you acknowledge that you have read, understood and agree to our ",
        React.createElement("br", { className: "hidden max-sm:block" }),
        React.createElement(BtwLink, { href: "/terms", variant: "secondary", target: "_blank" }, "Terms of Service"),
        "."))));
}
function ErrorMessage(props) {
    const { children } = props;
    return (React.createElement(BtwMessage, { leadingIcon: React.createElement(WarningIcon, null), variant: "red" }, children));
}
function SuccessMessage(props) {
    const { children } = props;
    return (React.createElement(BtwMessage, { leadingIcon: React.createElement(CheckIcon, null), variant: "green" }, children));
}
function Message(props) {
    const { children, icon } = props, rest = __rest(props, ["children", "icon"]);
    return (React.createElement(Stack, { align: "center", width: "full", gap: "6" },
        React.createElement(Stack, { align: "center", width: "full", gap: "4" },
            icon && (React.createElement(Stack, { align: "center", className: "text-gray-900 [&>svg>path]:stroke-[3px] [&>svg]:h-12 [&>svg]:w-12", width: "full" }, icon)),
            React.createElement(FullScreenPage.Header, Object.assign({}, rest))),
        React.createElement(Stack, { align: "center", width: "full" }, children)));
}
function PasswordStrength(props) {
    const { password } = props;
    const strength = getPasswordStrength(props.password);
    if (!password) {
        return null;
    }
    return (React.createElement(BtwText, { className: "flex items-center gap-1 [&>svg]:h-3.5 [&>svg]:w-3.5", size: "xs", variant: strength === null || strength === void 0 ? void 0 : strength.variant, weight: "medium" }, strength === null || strength === void 0 ? void 0 :
        strength.icon,
        " ", strength === null || strength === void 0 ? void 0 :
        strength.text));
}
const getPasswordStrength = (password) => {
    if (!password) {
        return null;
    }
    let strength = 0;
    // Check password length
    if (password.length > 8) {
        strength += 1;
    }
    // Check for mixed case
    if (password.match('.*[a-z].*') && password.match('.*[A-Z].*')) {
        strength += 1;
    }
    // Check for numbers
    if (password.match('.*\\d.*')) {
        strength += 1;
    }
    // Check for special characters
    if (password.match('.*[^a-zA-Z\\d].*')) {
        strength += 1;
    }
    if (strength < 2) {
        return {
            icon: React.createElement(FaceSadIcon, null),
            variant: 'red',
            text: 'Password weak',
        };
    }
    if (strength === 2) {
        return {
            icon: React.createElement(FaceNeutralIcon, null),
            variant: 'yellow',
            text: 'Password average',
        };
    }
    if (strength === 3) {
        return {
            icon: React.createElement(FaceNeutralIcon, null),
            variant: 'green',
            text: 'Password strong',
        };
    }
    if (strength > 3) {
        return {
            icon: React.createElement(FaceHappyIcon, null),
            variant: 'green',
            text: 'Password excellent',
        };
    }
    return null;
};
const FullScreenPage = {
    Body,
    Container,
    ErrorMessage,
    Form,
    Header,
    Message,
    SuccessMessage,
    PasswordStrength,
};
export { FullScreenPage };
