import { __rest } from "tslib";
import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import React, { forwardRef } from 'react';
import { BtwButton } from './BtwButton';
import { BtwTooltip } from './BtwTooltip';
import { cn } from './utils';
const Root = forwardRef(function Root(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement(ToolbarPrimitive.Root, Object.assign({ className: cn('p-1', 'bg-white', 'rounded-lg', 'print:hidden', 'whitespace-nowrap', 'ring-1', 'shadow-lg', 'ring-gray-600/10', 'focus-visible:outline-none'), ref: ref }, rest)));
});
const Button = forwardRef(function Button(props, ref) {
    const { children, className, content } = props, rest = __rest(props, ["children", "className", "content"]);
    return (React.createElement(BtwTooltip, { content: content },
        React.createElement(ToolbarPrimitive.ToolbarButton, Object.assign({ asChild: true, ref: ref }, rest),
            React.createElement(BtwButton, null))));
});
export const Toolbar = { Root, Button };
