import themeGet from '@styled-system/theme-get';
import { border, color, flexbox, layout, shadow, space, typography, } from 'styled-system';
import styled, { css } from '@marvelapp/styled';
import { cursor, focusBg, focusBorderColor, focusBoxShadow, focusColor, hoverBg, hoverBorderColor, hoverBoxShadow, hoverColor, hoverTextDecorationColor, pointerEvents, resize, textDecorationColor, textDecorationLine, textDecorationThickness, textTransform, textUnderlineOffset, } from '../customProperties';
export const inputStyles = css `
  ${border};
  ${color};
  ${cursor};
  ${flexbox};
  ${layout};
  ${pointerEvents};
  ${resize};
  ${shadow};
  ${space};
  ${textDecorationColor};
  ${textDecorationLine};
  ${textDecorationThickness};
  ${textTransform};
  ${textUnderlineOffset};
  ${typography};

  appearance: none;
  box-sizing: border-box;

  outline-style: none;
  outline-color: transparent;
  outline-offset: 4px;
  outline-style: dashed;
  outline-width: 1px;

  transition-property: all;
  transition-duration: 150ms;
  transition: all 400ms ${themeGet('easings.smooth.string')};

  &:hover {
    ${hoverBg};
    ${hoverBorderColor};
    ${hoverBoxShadow};
    ${hoverColor};
    ${hoverTextDecorationColor};
  }

  &:focus {
    ${focusBg};
    ${focusBorderColor};
    ${focusBoxShadow};
    ${focusColor};
  }

  &::placeholder {
    color: ${(props) => themeGet(`colors.${props.placeholderColor}`)};
    opacity: ${(props) => props.placeholderOpacity};
    letter-spacing: -0.2px;
    line-height: inherit;
  }

  &[type='date']::-webkit-inner-spin-button,
  &[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;
export const InputElement = styled.input `
  ${inputStyles};
  ${(props) => props.truncated &&
    css `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
  ${(props) => !props.hasTransition &&
    css `
      transition: none;
    `};

  ${(props) => props.hideArrows &&
    css `
      &[type='number'] {
        -moz-appearance: textfield;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}
`;
export const LoaderWrapper = styled.div `
  ${layout};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;
