import { __rest } from "tslib";
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { BtwButton } from './BtwButton';
export const MotionButton = forwardRef(function MotionButton(props, ref) {
    const { children, disabled, variants } = props, rest = __rest(props, ["children", "disabled", "variants"]);
    // TODO: Export screens from @ballpark/tailwind
    const isDesktop = useMediaQuery('(min-width: 768px)');
    // To be able to properly animate our buttons with framer motion, we
    // have to convert them to motion components. That messes with the native
    // button transform transition, so we have to disable it and use framer
    // to animate the button up and down instead.
    return (React.createElement(BtwButton, Object.assign({ asChild: true, disabled: disabled, transform: false, ref: ref }, rest),
        React.createElement(motion.button, { layout: isDesktop, 
            // Make sure the framer motion transition doesn't trigger when the
            // button is disabled
            whileHover: disabled || !isDesktop ? {} : { y: -1 }, whileTap: disabled || !isDesktop ? {} : { y: 1 }, variants: variants }, children)));
});
