const pluralSuffixMap = {
    y: { matchSuffix: 'y', replaceWith: 'ies' },
    ay: { matchSuffix: 'ay', replaceWith: 'ays' },
    s: { matchSuffix: 's', replaceWith: 'ses' },
    ch: { matchSuffix: 'ch', replaceWith: 'ches' },
    sh: { matchSuffix: 'sh', replaceWith: 'shes' },
};
export const pluralize = (count, noun) => {
    if (count === 1) {
        return noun;
    }
    // We try and get the correct plural suffix by the last 2 chars first.
    const lastChar = noun.slice(-1);
    const last2Chars = noun.slice(-2);
    const replaceData = pluralSuffixMap[last2Chars] || pluralSuffixMap[lastChar];
    if (replaceData) {
        const regExp = new RegExp(`${replaceData.matchSuffix}$`);
        const pluralized = noun.replace(regExp, replaceData.replaceWith);
        return pluralized;
    }
    return `${noun}s`;
};
