/**
 * Create an emitter that can be used to highlight cells in a table.
 *
 * @returns An emitter object with a subscribe method and a highlight method.
 */
const createEmitter = () => {
    // Keep track of the currently highlighted row and column
    let currentRow = -1;
    let currentCol = -1;
    // 2d array indexed by row and column
    const subs = [];
    // Subscribe function that will be called once for each cell, in a useEffect
    const subscribe = (row, col, callback) => {
        if (row < 0 || col < 0) {
            return () => {
                // Do nothing
            };
        }
        // Initialize row array if needed
        // Note that this does not handle multiple subscriptions for the same cell
        if (!subs[row]) {
            subs[row] = [];
        }
        subs[row][col] = callback;
        // This will be invoked by useEffect's cleanup
        return () => {
            if (subs[row]) {
                delete subs[row][col];
            }
        };
    };
    // Called by each cell when it is hovered
    const highlight = (newRow, newCol) => {
        subs.forEach((rowSubscriptions, rowIndex) => {
            rowSubscriptions.forEach((callback, colIndex) => {
                const wasHighlighted = rowIndex === currentRow || colIndex === currentCol;
                const isHighlighted = rowIndex === newRow || colIndex === newCol;
                // Only notify if the highlighting for this row has changed.
                // We could optimize this loop to only run for the changed
                // rows, but you're unlikely to see noticable gains.
                if (wasHighlighted !== isHighlighted) {
                    callback(isHighlighted);
                }
            });
        });
        // Update the currently highlighted cell, otherwise you'll never
        // unhighlight the old ones.
        currentRow = newRow;
        currentCol = newCol;
    };
    return { subscribe, highlight };
};
export const tableCellEmitter = createEmitter();
