import { __makeTemplateObject } from "tslib";
import themeGet from '@styled-system/theme-get';
import { color, layout } from 'styled-system';
import styled from '@marvelapp/styled';
import Box from '../Box';
import { focusBg, focusBoxShadow, focusColor, hoverBg, hoverBoxShadow, hoverColor, } from '../customProperties';
export const SelectElement = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  outline-color: transparent;\n  outline-offset: 4px;\n  outline-style: dashed;\n  outline-width: 1px;\n  position: relative;\n  transition: all 400ms ", ";\n  color: ", ";\n\n  &:hover {\n    ", ";\n    ", ";\n    ", ";\n  }\n\n  &:focus {\n    ", ";\n    ", ";\n    ", void 0], ["\n  appearance: none;\n  outline-color: transparent;\n  outline-offset: 4px;\n  outline-style: dashed;\n  outline-width: 1px;\n  position: relative;\n  transition: all 400ms ", ";\n  color: ", ";\n\n  &:hover {\n    ", ";\n    ", ";\n    ", ";\n  }\n\n  &:focus {\n    ", ";\n    ", ";\n    ", ";\n  }\n\n  &::-ms-expand {\n    display: none; /* remove default arrow on ie10 and ie11 */\n  }\n\n  /* target Internet Explorer 9 to undo the custom arrow */\n  @media screen and (min-width: 0\\0) {\n    select {\n      background: none\\9;\n      padding: 5px\\9;\n    }\n  }\n"])), themeGet('easings.smooth.string'), (props) => props.showPlaceholder && themeGet('colors.silver'), hoverBg, hoverBoxShadow, hoverColor, focusColor, focusBoxShadow, focusBg);
export const Arrow = styled(Box) `
  ${color};
  ${layout};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
`;
var templateObject_1;
