import { __rest } from "tslib";
import React from 'react';
import { Box } from '@marvelapp/ui';
const styles = {
    warning: {
        bg: 'amber3',
        borderColor: 'amber6',
    },
    info: {
        bg: 'blue3',
        borderColor: 'blue6',
    },
};
export const HintMessage = (_a) => {
    var { kind } = _a, otherProps = __rest(_a, ["kind"]);
    return (React.createElement(Box, Object.assign({ border: "1px solid", borderRadius: "m", fontSize: "s", padding: "s" }, styles[kind], otherProps)));
};
