/* eslint-env browser */
export const key = 'upsells-assist';
function isWithinLastHour(timestamp) {
    const hour = 1000 * 60 * 60;
    const hourAgo = Date.now() - hour;
    return timestamp > hourAgo;
}
export function getAssists() {
    return JSON.parse(window.localStorage.getItem(key) || '{}');
}
export function setViewedPricingPage() {
    const data = {
        lastTimeViewedPricingPage: new Date().getTime(),
    };
    window.localStorage.setItem(key, JSON.stringify(data));
}
export function setDidDowngrade() {
    const newAssists = getAssists();
    newAssists.lastTimeDowngraded = new Date().getTime();
    window.localStorage.setItem(key, JSON.stringify(newAssists));
}
export function didDowngradeInPastHour(assists) {
    if (assists.lastTimeDowngraded === undefined) {
        return false;
    }
    return isWithinLastHour(assists.lastTimeDowngraded);
}
export function seenPlansInPastHour(assists) {
    if (assists.lastTimeViewedPricingPage === undefined) {
        return false;
    }
    return isWithinLastHour(assists.lastTimeViewedPricingPage);
}
