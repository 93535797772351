import { __awaiter } from "tslib";
import { useState } from 'react';
export function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(null);
    const copy = (text) => __awaiter(this, void 0, void 0, function* () {
        if (!(navigator === null || navigator === void 0 ? void 0 : navigator.clipboard)) {
            console.warn('Clipboard not supported');
            return false;
        }
        // Try to save to clipboard then save it in the state if worked
        try {
            yield navigator.clipboard.writeText(text);
            setCopiedText(text);
            setTimeout(() => setCopiedText(null), 1000);
            return true;
        }
        catch (error) {
            console.warn('Copy failed', error);
            setCopiedText(null);
            return false;
        }
    });
    return [copiedText, copy];
}
