const kinds = {
    ghost: {
        bg: 'transparent',
        color: 'slate',
        boxShadow: 'select.ghost',
        hoverBoxShadow: 'select.ghostHover',
        focusBoxShadow: 'select.ghostFocus',
    },
    disabled: {
        bg: 'snow',
        boxShadow: 'select.disabled',
        color: 'silver',
        pointerEvents: 'none',
    },
    error: {
        bg: 'transparent',
        color: 'red',
        boxShadow: 'select.error',
    },
    design: {
        bg: 'transparent',
        color: 'slate',
        boxShadow: 'none',
        hoverBoxShadow: 'input.default',
        focusBoxShadow: 'input.defaultFocus',
    },
};
export default kinds;
