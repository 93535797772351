import { ANONYMOUS, PlanLabel, UserRole } from '@marvelapp/core';
import * as userSelectors from './userSelectors';
export function createPermissions(user, subscription, flags) {
    var _a, _b;
    const isUserAuthenticated = user.role !== ANONYMOUS;
    const isRecruitmentBundlesFlagEnabled = !!(flags === null || flags === void 0 ? void 0 : flags['recruitment-bundles']);
    const planLabel = isUserAuthenticated
        ? user.company.billing.plan.label
        : null;
    const planSlug = isUserAuthenticated ? subscription === null || subscription === void 0 ? void 0 : subscription.plan.slug : null;
    // This is workaround for:
    // https://marvelapp.atlassian.net/browse/UT-2002
    //
    // The invoice section must be visible for customers that downgraded to free.
    // Unfortunately the invoice list is not exposed via GQL, so there is no way
    // to check if it's empty or not.
    // As alternative we check for `customerId` instead, which will have a value
    // in it if the company ever had a Stripe subscription
    const hasInvoicesToView = isUserAuthenticated &&
        !!user.company.billing.customerId &&
        // Free plan doesn't have access to feature flags
        (isRecruitmentBundlesFlagEnabled || planLabel === PlanLabel.Free);
    // We always try to declare by whitelisting roles rather than blacklisting
    // as it means you're more likely to stop something from doing something they
    // should be able to do than allow someone do something they're not supposed to
    const isOneOf = (...whitelist) => whitelist.some((w) => user.role === w);
    const canViewInvoices = hasInvoicesToView && isOneOf(UserRole.Owner);
    const editorsCanInvite = (_b = (_a = user.company) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.editorsCanInvite;
    const canArchiveProjects = (projects) => {
        const ownsAllProjects = projects.every((project) => project.owner.pk === user.pk);
        return (isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor) ||
            (isOneOf(UserRole.ExternalEditor) && ownsAllProjects));
    };
    const isLockedFreePlan = planLabel === PlanLabel.Free && !userSelectors.hasRemainingTrialDays(user);
    return {
        // General
        canViewAuthedRoutes: () => isUserAuthenticated,
        // Account settings
        canViewBilling: () => user.role === UserRole.Owner,
        canViewInvoices: () => canViewInvoices,
        canEditInvoiceDetails: () => canViewInvoices && planLabel !== PlanLabel.Free,
        // Subscription
        canEditSubscription: (isSubscribingToEntLite) => {
            if (planLabel === PlanLabel.Free) {
                return isOneOf(UserRole.Owner, UserRole.Editor, UserRole.Admin);
                // eslint-disable-next-line no-else-return
            }
            else if (isSubscribingToEntLite || (planSlug === null || planSlug === void 0 ? void 0 : planSlug.includes('ent-lite'))) {
                return isOneOf(UserRole.Owner);
            }
            const isActiveEnterpriseTrial = planLabel === PlanLabel.Enterprise &&
                !userSelectors.isEnterpriseAndTrialExpired(user);
            return isOneOf(UserRole.Owner) && !isActiveEnterpriseTrial;
        },
        // Folders
        canViewFolders: () => true,
        canEditFolders: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        // Projects
        canViewProjects: () => true,
        canCreateProjects: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        canEditProjects: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor) && !isLockedFreePlan,
        canArchiveProjects,
        canUnarchiveProjects: canArchiveProjects,
        canDownloadProjects: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        canBeTransferredProjects: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor),
        // Team & Groups
        canViewPeople: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.InternalReviewer),
        canEditTeamSettings: () => isOneOf(UserRole.Owner, UserRole.Admin),
        canInvitePeopleToCompany: () => isOneOf(UserRole.Owner, UserRole.Admin) ||
            (isOneOf(UserRole.Editor) && editorsCanInvite),
        canDownloadCSV: () => isOneOf(UserRole.Owner, UserRole.Admin),
        canCreateGroups: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor),
        canEditGroups: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor),
        // User Testing
        canCreateUserTest: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        canEditUserTestResults: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        // Sharing
        canSearchCollaboratorsList: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        canEditCollaborators: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        canEditSharePassword: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor),
        canEditVisibility: () => !isLockedFreePlan &&
            planLabel !== PlanLabel.Pro &&
            isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor),
        // Ballpark specific
        canRecruitParticipants: () => isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor),
        canDownloadResultsCSV: () => planLabel !== PlanLabel.Free && planLabel !== PlanLabel.Pro,
    };
}
