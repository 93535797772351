import React from 'react';
import styled from '@marvelapp/styled';
import { Box } from '@marvelapp/ui';
import { Icon as Svg } from '../../Icon';
export function StepTypeIcon({ bg, borderRadius = 'm', children, color, height = 42, width = 42, }) {
    return (React.createElement(Icon, { "data-testid": "step-icon", borderRadius: borderRadius, bg: bg, color: color, width: width, height: height },
        React.createElement(Svg, { viewBox: "0 0 42 42", width: width, height: height }, children)));
}
const Icon = styled(Box) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
