import { StepClass } from '../common';
import { generateUUID } from '../utils/generateUUID';
import { STEP_PREFIXES } from './constants';
import { PrototypeStepType } from './types';
export function create(partial = {}) {
    return Object.assign({ uuid: generateUUID(STEP_PREFIXES.PrototypeTask), className: StepClass.PrototypeTask, title: null, description: [], revision: 0, originalPrototypeUrl: '', isRequired: false, importedPrototypeUrl: '', startScreen: null, goalScreen: null, paths: [], conditions: {
            ALWAYS: null,
        } }, partial);
}
export function clearPrototype(mutable) {
    mutable.originalPrototypeUrl = '';
    mutable.importedPrototypeUrl = '';
    mutable.startScreen = null;
    mutable.goalScreen = null;
    mutable.paths = [];
}
export function setOriginalPrototypeUrl(mutable, url) {
    mutable.originalPrototypeUrl = url;
}
export function setImportedPrototypeUrl(mutable, url) {
    mutable.importedPrototypeUrl = url;
}
export function setStartScreen(mutable, pk) {
    mutable.startScreen = pk;
}
export function setStartScreenExternalId(mutable, externalId) {
    mutable.defaultStartScreenExternalId = externalId;
}
export function setEmbedUrl(mutable, key) {
    mutable.embedUrl = key;
}
export function setGoalScreen(mutable, pk) {
    mutable.goalScreen = pk;
}
export function hasGoalScreen(step) {
    return !!step.goalScreen;
}
export function isExternalPrototype(step) {
    if (!step.originalPrototypeUrl)
        return null;
    try {
        const parsedUrl = new URL(step.originalPrototypeUrl);
        return !parsedUrl.hostname.includes('marvelapp');
    }
    catch (ex) {
        console.log('This is not viewed as a url');
        return false;
    }
}
export function setImageUrl(mutable, url) {
    mutable.imageUrl = url;
}
export function getImageUrl(step) {
    return step.imageUrl;
}
export function getOriginalPrototypeUrl(step) {
    return step.importedPrototypeUrl;
}
export function removeImage(mutable) {
    delete mutable.imageUrl;
    mutable.importedPrototypeUrl = '';
}
export function isFirstClick(item) {
    if ('type' in item) {
        return item.type === PrototypeStepType.FirstClick;
    }
    return false;
}
