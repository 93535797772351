import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BtwButton, BtwLink, Logo, Stack, } from '@marvelapp/ballpark-components';
import { CloseIcon } from '@marvelapp/ballpark-icons';
import { NavLinks } from './NavLinks';
import UserBlock from './UserBlock';
export function Sidebar() {
    const [isSidebarExtendedOnMobile, setIsSidebarExtendedOnMobile] = useState(false);
    const toggleSidebarOnMobile = (isExtended) => () => setIsSidebarExtendedOnMobile(isExtended);
    const collapsedClasses = isSidebarExtendedOnMobile
        ? 'translate-x-0'
        : '-translate-x-48';
    return (React.createElement("div", { "data-testid": "sidebar" },
        React.createElement(BtwButton, { variant: "secondary", size: "sm", className: `fixed left-3 top-3 ${isSidebarExtendedOnMobile ? 'hidden' : 'block md:hidden'}`, onClick: toggleSidebarOnMobile(!isSidebarExtendedOnMobile) }, "Menu"),
        React.createElement(Stack, { direction: "col", align: "start", justify: "between", 
            // TODO: {tailwind} Update the z-index here to use some sort of better defined system
            className: `fixed z-[500] h-screen w-48 bg-gray-50 transition-all duration-300 ease-in-out md:translate-x-0 ${collapsedClasses}` },
            React.createElement(Stack, { className: "w-48 overflow-y-scroll px-4 pt-11", gap: "8" },
                React.createElement(BtwButton, { standaloneIcon: React.createElement(CloseIcon, null), variant: "ghost", size: "sm", onClick: toggleSidebarOnMobile(!isSidebarExtendedOnMobile), className: "fixed right-3 top-3 md:hidden" }),
                React.createElement(Stack, { direction: "row", className: "px-4 py-1", gap: "1.5" },
                    React.createElement(BtwLink, { asChild: true, variant: "secondary" },
                        React.createElement(RouterLink, { to: "/" },
                            React.createElement(Logo, { width: "72" })))),
                React.createElement(NavLinks, null)),
            React.createElement(UserBlock, null))));
}
